
import {
	ERC20ContractParamsType,
	WrappedTokenType,
	WrappedTokensStatType,
	ChainParamsType,
} from '../models/BlockchainAdapter';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

// ---------- NAVIGATION ----------
export const gotoMain = () => {
	return {
		type: 'GOTO_MAIN',
	}
}
export const gotoPreview = () => {
	return {
		type: 'GOTO_PREVIEW',
	}
}
export const gotoList = () => {
	return {
		type: 'GOTO_LIST',
	}
}
export const setLoading = (payload: { msg: string }) => {
	return {
		type: 'SET_LOADING',
		payload,
	}
}
export const unsetLoading = () => {
	return {
		type: 'UNSET_LOADING',
	}
}
export const setError = (payload: {
	text: string,
	buttons: undefined | Array<{
		text: string,
		clickFunc: Function,
	}>,
	links: undefined | Array<{
		text: string,
		url : string,
	}>
}) => {
	return {
		type: 'SET_ERROR',
		payload,
	}
}
export const clearError = () => {
	return {
		type: 'CLEAR_ERROR',
	}
}
export const setInfo = (payload: {
	text: string,
	buttons: undefined | Array<{
		text: string,
		clickFunc: Function,
	}>,
	links: undefined | Array<{
		text: string,
		url : string,
	}>
}) => {
	return {
		type: 'SET_INFO',
		payload,
	}
}
export const clearInfo = () => {
	return {
		type: 'CLEAR_INFO',
	}
}
export const setSuccess = (
	payload: {
		token          : WrappedTokenType | undefined,
		icon           : string,
		text           : string,
		transactionHash: string,
	}
) => {
	return {
		type: 'SET_SUCCESS',
		payload,
	}
}
export const clearSuccess = () => {
	return {
		type: 'CLEAR_SUCCESS',
	}
}
export const resetAppData = () => {
	return {
		type: 'RESET_APP_DATA',
	}
}
export const gotoListRequest = () => {
	return {
		type: 'GOTO_LIST_REQUEST',
	}
}
export const gotoListResolve = () => {
	return {
		type: 'GOTO_LIST_RESOLVE',
	}
}
// ---------- END NAVIGATION ----------

// ---------- CONNECTION ----------
export const metamaskConnectionSuccess = (payload: { address: string }) => {
	return {
		type: 'METAMASK_CONNECTION_SUCCESS',
		payload,
	}
}
export const metamaskConnectionNotInstalled = () => {
	return {
		type: 'METAMASK_CONNECTION_NOT_INSTALLED',
	}
}
export const metamaskConnectionRejected = () => {
	return {
		type: 'METAMASK_CONNECTION_REJECTED',
	}
}
export const metamaskSetChainParams = (
	payload: {
		chainId?                      : number | undefined,
		chainName?                    : string,
		chainRPCUrl?                  : string,
		networkTokenTicket?           : string,
		EIPPrefix?                    : string,
		networkTokenDecimals?         : number,
		networkTokenIcon?             : string,
		isTestNetwork?                : Boolean,
		explorerBaseUrl?              : string,
		marketplaceUrl?               : string,
		explorerName?                 : string,
		transferRoyaltyModel?         : string,
		minterContract?               : string,
		batchWrapSubscriptionContract?: string,
	}
) => {
	return {
		type: 'METAMASK_SET_CHAIN_PARAMS',
		payload,
	}
}
export const metamaskSetAvailableChains = ( payload: Array<ChainParamsType> ) => {
	return {
		type: 'METAMASK_SET_AVAILABLE_CHAINS',
		payload,
	}
}
export const setAuthMethod = ( payload: string ) => {
	return {
		type: 'SET_AUTH_METHOD',
		payload,
	}
}
export const unsetAuthMethod = () => {
	return {
		type: 'UNSET_AUTH_METHOD',
	}
}
export const requestChain = ( payload: number | undefined ) => {
	return {
		type: 'REQUEST_CHAIN',
		payload
	}
}
// ---------- END CONNECTION ----------

// ---------- NATIVE TOKEN ----------
export const updateNativeBalance = ( payload: { balance: BigNumber } ) => {
	return {
		type: 'UPDATE_NATIVE_BALANCE',
		payload,
	}
}
// ---------- END NATIVE TOKEN ----------

// ---------- WRAPPER CONTRACT ----------
export const updateTransferAllowance = ( payload: {
	wrapperAddress      : string,
	transferModelAddress: string,
	erc20TokenAddress   : string,
	allowance           : BigNumber,
} ) => {
	return {
		type: 'UPDATE_TRANSFER_ALLOWANCE',
		payload,
	}
}
export const erc20TechContractParamsUpdate = (payload: ERC20ContractParamsType) => {
	return {
		type: 'ERC20_TECH_CONTRACT_PARAMS_UPDATE',
		payload,
	}
}
export const erc20CollateralContractParamsUpdate = (payload: ERC20ContractParamsType) => {
	return {
		type: 'ERC20_COLATERAL_CONTRACT_PARAMS_UPDATE',
		payload,
	}
}
// ---------- END WRAPPER CONTRACT ----------

// ---------- ERC721 FETCH ----------
export const wrappedTokensAdd = (payload: WrappedTokenType) => {
	return {
		type: 'WRAPPED_TOKENS_ADD',
		payload,
	}
}
export const wrappedTokensRemove = (payload: WrappedTokenType) => {
	return {
		type: 'WRAPPED_TOKENS_REMOVE',
		payload,
	}
}
export const wrappedTokensClear = () => {
	return {
		type: 'WRAPPED_TOKENS_CLEAR',
	}
}
export const incompleteTokensAdd = (payload: WrappedTokenType) => {
	return {
		type: 'INCOMPLETE_TOKENS_ADD',
		payload,
	}
}
export const incompleteTokensRemove = (payload: WrappedTokenType) => {
	return {
		type: 'INCOMPLETE_TOKENS_REMOVE',
		payload,
	}
}
export const tokenUpdate = (payload: WrappedTokenType) => {
	return {
		type: 'TOKEN_UPDATE',
		payload,
	}
}
export const tokenPreviewClear = () => {
	return {
		type: 'TOKEN_PREVIEW_CLEAR',
	}
}
export const wrappedStatsUpdate = (payload: WrappedTokensStatType) => {
	return {
		type: 'WRAPPED_STATS_UPDATE',
		payload,
	}
}
export const discoveredTokensAdd = (payload: WrappedTokenType) => {
	return {
		type: 'DISCOVERED_TOKENS_ADD',
		payload,
	}
}
export const discoveredTokensRemove = (payload: WrappedTokenType) => {
	return {
		type: 'DISCOVERED_TOKENS_REMOVE',
		payload,
	}
}
export const discoveredTokensClear = () => {
	return {
		type: 'DISCOVERED_TOKENS_CLEAR',
	}
}
export const ignoredTokensAdd = (payload: { contractAddress: string, tokenId: string }) => {
	return {
		type: 'IGNORED_TOKENS_ADD',
		payload,
	}
}
export const setTokensLoading = () => {
	return {
		type: 'SET_TOKENS_LOADING',
	}
}
export const unsetTokensLoading = () => {
	return {
		type: 'UNSET_TOKENS_LOADING',
	}
}
// ---------- END ERC721 FETCH ----------

// ---------- WAITING TOKEN ----------
export const waitingTokensAdd = (payload: { token: WrappedTokenType, msg: string }) => {
	return {
		type: 'WAITING_TOKENS_ADD',
		payload,
	}
}
export const waitingTokensRemove = (payload: WrappedTokenType) => {
	return {
		type: 'WAITING_TOKENS_REMOVE',
		payload,
	}
}
// ---------- END WAITING TOKEN ----------

// ---------- BATCHWRAP SUBSCRIPTION ----------
export const batchWrapSubscriptionUpdateParams = (payload: {
	ticketParams?: {
		paymentToken     : string,
		paymentAmount    : BigNumber,
		timelockPeriod   : BigNumber,
		ticketValidPeriod: BigNumber,
	},
	endTime?        : BigNumber,
	contractAddress?: string,
}) => {
	return {
		type: 'BATCHWRAP_SUBSCRIPTION_UPDATE_PARAMS',
		payload,
	}
}
export const batchWrapSubscriptionUpdateEndTime = (payload: { endTime: BigNumber }) => {
	return {
		type: 'BATCHWRAP_SUBSCRIPTION_END_TIME',
		payload,
	}
}
// ---------- END BATCHWRAP SUBSCRIPTION ----------