
import { MetamaskAdapter }  from '.';
import erc721_abi           from '../../abis/_erc721.json';
import { WrappedTokenType } from './wrappercontract';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

export const saveERC721Token = (token: WrappedTokenType): void => {
	const saved = localStorage.getItem('tokensIncomplete');
	if ( saved ) {
		const savedArr: Array<WrappedTokenType> = JSON.parse(saved);
		const filtered = savedArr.filter((item: WrappedTokenType) => { return !( item.contractAddress.toLowerCase() === token.contractAddress.toLowerCase() && item.tokenId.toLowerCase() === token.tokenId.toLowerCase() ) });
		localStorage.setItem('tokensIncomplete', JSON.stringify([...filtered, { ...token }]));
	} else {
		localStorage.setItem('tokensIncomplete', JSON.stringify([token]));
	}
}
export const removeERC721Token = (token: WrappedTokenType, chainId: number): void => {
	const saved = localStorage.getItem('tokensIncomplete');
	if ( !saved ) { return; }

	const savedArr: Array<WrappedTokenType> = JSON.parse(saved);
	const clearedArr = savedArr
		.filter((item: WrappedTokenType) => { return item.chainId === chainId })
		.filter((item: WrappedTokenType) => {
			if ( !token.originalContractAddress || !token.originalTokenId ) { return false; }
			return item.contractAddress.toLowerCase() !== token.originalContractAddress.toLowerCase() || item.tokenId.toLowerCase() !== token.originalTokenId.toLowerCase()
		});
	localStorage.setItem('tokensIncomplete', JSON.stringify(clearedArr));
}
export const loadERC721Token = (metamaskAdapter: MetamaskAdapter, tokenId: string, chainId: number, userAddress: string): WrappedTokenType | null => {
	const saved = localStorage.getItem('tokensIncomplete');
	if ( saved ) {
		const savedArr: Array<WrappedTokenType> = JSON.parse(saved);
		const foundToken = savedArr
			.filter((item: WrappedTokenType) => { return item.owner   === userAddress })
			.filter((item: WrappedTokenType) => { return item.chainId === chainId })
			.filter((item: WrappedTokenType) => { return item.tokenId === tokenId })
		if ( foundToken.length ) { return foundToken[0] }
	}
	return null;
}
export const loadERC721TokenAll = (metamaskAdapter: MetamaskAdapter, chainId: number, userAddress: string): Array<WrappedTokenType> => {
	const saved = localStorage.getItem('tokensIncomplete');
	if ( saved ) {
		const found: Array<WrappedTokenType> = JSON.parse(saved);
		const foundTokens = found
			.filter((item: WrappedTokenType) => { return item.chainId === chainId })
			.filter((item: WrappedTokenType) => { return item.owner   === userAddress });
		return foundTokens;
	}
	return [];
}
export const getERC721Token = async (
	metamaskAdapter: MetamaskAdapter,
	contractAddress: string,
	tokenId: string,
	userAddress: string,
	t: any,
	sortParams: {
		blockNumber: BigNumber | undefined,
		logIndex: BigNumber | undefined
	}
): Promise<WrappedTokenType> => {

	if ( !contractAddress || !tokenId ) { throw new Error(t('Empty params')); }

	if ( !metamaskAdapter.web3.utils.isAddress(contractAddress) ) { throw new Error(t('Bad address')); }

	const chainId  = await metamaskAdapter.web3.eth.getChainId();

	if ( contractAddress.toLowerCase() === metamaskAdapter.wrapperContract.contractAddress.toLowerCase() ) {
		return metamaskAdapter.wrapperContract.getWrappedTokenById(
			contractAddress,
			metamaskAdapter.wrapperContract.contract,
			tokenId,
			chainId
		)
	}

	const foundPrevContract = metamaskAdapter.wrapperContract.previousContracts.filter((item) => { return item.address.toLowerCase() === contractAddress.toLowerCase() });
	if ( foundPrevContract.length ) {
		return metamaskAdapter.wrapperContract.getWrappedTokenById(
			contractAddress,
			foundPrevContract[0].contract,
			tokenId,
			chainId
		)
	}

	let tokenUrl;
	let token;
	let tokenParsed;
	let owner;
	let name;
	let description;
	let image;
	try {
		const contract = new metamaskAdapter.web3.eth.Contract(erc721_abi as any, contractAddress);

		owner = await contract?.methods.ownerOf(tokenId).call();
		// if ( owner.toLowerCase() !== userAddress.toLowerCase() ) { return null; }

		tokenUrl = await contract?.methods.tokenURI(tokenId).call();
	} catch(e: any) {
		console.log('Cannot get erc721 token: ', e)

		let errorMsg = '';
		try {
			const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
			errorMsg = errorParsed.originalError.message;
		} catch(ignored) {}

		if ( errorMsg === '' ) {
			throw new Error(t('Cannot connect to contract'))
		} else {
			throw new Error(errorMsg)
		}
	}

	try {
		token       = await fetch(tokenUrl);
		tokenParsed = await token.json();
		name        = tokenParsed.name        || '';
		description = tokenParsed.description || '';
		image       = tokenParsed.image       || '';
	} catch(e) {
		console.log('Cannot fetch token data', token);
		name        = '';
		description = '';
		image       = '';
	}

	return {
		chainId,
		contractAddress,
		tokenId,
		tokenUrl,
		owner,
		name,
		description,
		image,
		sortParams,
		originalContractAddress: undefined,
		originalTokenId        : undefined,
		backedTokens           : undefined,
		backedValue            : undefined,
		backedERC20Value       : [],
		royalty                : undefined,
		royaltyPercent         : undefined,
		royaltyRec             : '',
		transferFeeToken       : '',
		transferFee            : undefined,
		unwrapAfter            : undefined,
		unwraptFeeThreshold    : undefined,
	}
}
export const transferERC721Token = async (
	metamaskAdapter: MetamaskAdapter,
	contractAddress: string,
	tokenId: string,
	userAddressFrom: string,
	addressTo: string,
	t: any
) => {

	if ( !contractAddress || !tokenId ) { throw new Error(t('Empty params')); }
	if ( !metamaskAdapter.web3.utils.isAddress(contractAddress) ) { throw new Error(t('Bad contract address')); }
	if ( !metamaskAdapter.web3.utils.isAddress(addressTo) ) { throw new Error(t('Bad recipient address')); }

	const contract = new metamaskAdapter.web3.eth.Contract(erc721_abi as any, contractAddress);

	const tx = contract.methods.transferFrom(
		userAddressFrom,
		addressTo,
		tokenId
	);

	// pre-send transaction check
	try {
		await tx.estimateGas({ from: userAddressFrom })
	} catch(e: any) {
		let errorMsg = '';
		try {
				errorMsg = e.message;
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errorMsg = errorParsed.originalError.message;
				} catch(ignored) {}
		} catch(ignored) {
			errorMsg = e;
		}
		throw errorMsg;
	}

	return tx.send({ from: userAddressFrom })

}
export const setApprovalERC721Token = async (
	metamaskAdapter: MetamaskAdapter,
	contractAddress: string,
	userAddressFrom: string,
	addressTo: string,
	t: any
) => {

	if ( !contractAddress ) { throw new Error(t('Empty params')); }
	if ( !metamaskAdapter.web3.utils.isAddress(contractAddress) ) { throw new Error(t('Bad contract address')); }
	if ( !metamaskAdapter.web3.utils.isAddress(addressTo) ) { throw new Error(t('Bad aprroval address')); }

	const contract = new metamaskAdapter.web3.eth.Contract(erc721_abi as any, contractAddress);

	const tx = contract.methods.setApprovalForAll(
		addressTo,
		true
	);

	// pre-send transaction check
	try {
		await tx.estimateGas({ from: userAddressFrom })
	} catch(e: any) {
		let errorMsg = '';
		try {
				errorMsg = e.message;
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errorMsg = errorParsed.originalError.message;
				} catch(ignored) {}
		} catch(ignored) {
			errorMsg = e;
		}
		throw errorMsg;
	}

	return tx.send({ from: userAddressFrom })

}

export const mintToken = async ( metamaskAdapter: MetamaskAdapter, contractAddress: string, userAddress: string ) => {
	if ( !contractAddress ) { return; }

	let   minterAbi = require(`../../abis/minter.json`);
	try { minterAbi = require(`../../abis/${contractAddress}.json`) } catch (ignored) {}
	try { minterAbi = require(`../../abis/${contractAddress.toLowerCase()}.json`) } catch (ignored) {}

	const contract = new metamaskAdapter.web3.eth.Contract(minterAbi as any, contractAddress);

	const tx = contract.methods.mint( userAddress );

	// pre-send transaction check
	try {
		await tx.estimateGas({ from: userAddress })
	} catch(e: any) {
		let errorMsg = '';
		try {
			errorMsg = e.message;
			try {
				const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
				errorMsg = errorParsed.originalError.message;
			} catch(ignored) {}
		} catch(ignored) {
			errorMsg = e;
		}
		throw new Error(errorMsg);
	}

	return tx.send({ from: userAddress })

}