
import React                from 'react';
import Blockies             from 'react-blockies';
import Tippy                from '@tippyjs/react';
import { CopyToClipboard }  from 'react-copy-to-clipboard';
import { compactString, tokenToFloat }     from '../../models/_utils';
import {
	ChainParamsType,
	MetamaskAdapter,
} from '../../models/BlockchainAdapter';
import {
	clearError,
	clearSuccess,
	requestChain,
	unsetAuthMethod,
	unsetLoading,
} from '../../reducers';
import {
	Link,
	withRouter,
	match
} from 'react-router-dom';
import {
	History,
	Location
} from 'history';

import icon_logo            from '../../static/pics/logo.svg';
import icon_i_copy          from '../../static/pics/i-copy.svg';
import icon_i_attention_red from '../../static/pics/i-attention-red.svg';

import { withTranslation } from "react-i18next";

// @ts-ignore
import queryString from 'query-string';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type HeaderProps = {
	store                 : any,
	metamaskAdapter       : MetamaskAdapter,
	showAuthMethodSelector: Function,
	t                     : any,
	match                 : match;
	location              : Location,
	history               : History,
}
type HeaderState = {
	address            : string,
	metamaskLogged     : boolean,
	isTestNetwork      : boolean,
	currentChain       : number,
	chainName          : string,
	availableChains    : Array<ChainParamsType>,
	chainSelectorOpened: boolean,

	balanceNative : BigNumber,
	decimalsNative: number,
	symbolNative  : string,

	balanceERC20  : BigNumber,
	decimalsERC20 : number,
	allowanceERC20: BigNumber,
	symbolERC20   : string,

	copiedHint: boolean,
}

class Header extends React.Component<HeaderProps, HeaderState> {

	store                 : any;
	metamaskAdapter       : MetamaskAdapter;
	unsubscribe!          : Function;
	unlisten!             : Function;
	showAuthMethodSelector: Function;
	t                     : any;

	constructor(props: HeaderProps) {
		super(props);

		this.store                  = props.store;
		this.metamaskAdapter        = props.metamaskAdapter;
		this.showAuthMethodSelector = props.showAuthMethodSelector;
		this.t                      = props.t;

		const urlParams = queryString.parse(this.props.location.search);
		if ( urlParams.chain ) {
			this.store.dispatch(requestChain( parseInt(`${urlParams.chain}`) ));
		}

		this.state = {
			address            : '',
			metamaskLogged     : false,
			isTestNetwork      : false,
			currentChain       : this.store.getState().metamaskAdapter.chainId,
			chainName          : this.store.getState().metamaskAdapter.chainName,
			availableChains    : this.store.getState().metamaskAdapter.availableChains,
			chainSelectorOpened: false,

			balanceNative : this.store.getState().account.balanceNative,
			decimalsNative: this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative  : this.store.getState().metamaskAdapter.networkTokenTicket,

			balanceERC20  : new BigNumber(0),
			decimalsERC20 : 0,
			allowanceERC20: new BigNumber(0),
			symbolERC20   : '',

			copiedHint    : false,
		};
	}

	componentDidMount() {
		this.unsubscribe = this.store.subscribe(() => {
			this.setState({
				address        : this.store.getState().account.address,
				metamaskLogged : this.store.getState().metamaskAdapter.logged,
				isTestNetwork  : this.store.getState().metamaskAdapter.isTestNetwork,
				currentChain   : this.store.getState().metamaskAdapter.chainId,
				chainName      : this.store.getState().metamaskAdapter.chainName,
				availableChains: this.store.getState().metamaskAdapter.availableChains,

				balanceNative : this.store.getState().account.balanceNative,
				decimalsNative: this.store.getState().metamaskAdapter.networkTokenDecimals,
				symbolNative  : this.store.getState().metamaskAdapter.networkTokenTicket,

				balanceERC20  : this.store.getState().erc20TechTokenParams.balance,
				decimalsERC20 : this.store.getState().erc20TechTokenParams.decimals,
				allowanceERC20: this.store.getState().erc20TechTokenParams.allowance,
				symbolERC20   : this.store.getState().erc20TechTokenParams.symbol,
			});
		});
		this.unlisten = this.props.history.listen(() => {
			const urlParams = queryString.parse(this.props.location.search);
			if ( urlParams.chain ) {
				this.store.dispatch(requestChain( parseInt(`${urlParams.chain}`) ));
			}
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	getBalances() {
		const balances = [];
		// if ( !this.state.balanceERC20.eq(0) && this.state.decimalsERC20 ) {
		// 	balances.push(`${ tokenToFloat(new BigNumber(this.state.balanceERC20), this.state.decimalsERC20).toFixed(3, BigNumber.ROUND_DOWN)} ${ this.state.symbolERC20 }`)
		// }

		if ( this.state.decimalsNative ) {
			balances.push(`${ tokenToFloat(new BigNumber(this.state.balanceNative), this.state.decimalsNative).toFixed(3, BigNumber.ROUND_DOWN) } ${ this.state.symbolNative }`)
		}
		return (
			<div className="info">
				{ balances.join(', ') }
			</div>
		)
	}
	getConnectBtn() {
		return (
			<button
				className="btn btn-sm"
				onClick={(e) => {
					this.store.dispatch(unsetLoading());
					this.store.dispatch(clearSuccess());
					this.store.dispatch(clearError());
					this.store.dispatch(unsetAuthMethod());

					this.showAuthMethodSelector();
				}}
			>
				{ this.t('Connect wallet') }
			</button>
		)
	}
	getUserData() {
		return (
			<div className="s-user">
				<button
					className="btn btn-sm btn-outline"
					style={{ marginRight: '15px' }}
					onClick={(e) => {
						localStorage.removeItem('provider_type');
						window.location.reload();
					}}
				>
					{ this.t('disconnect') }
				</button>
				<Tippy
					content={ this.state.address ? this.state.address : '' }
					appendTo={ document.getElementsByClassName("wrapper")[0] }
					trigger='mouseenter'
					interactive={ false }
					arrow={ false }
					maxWidth={ 512 }
				>
					<div className="s-user__data">
						{ this.getBalances() }
						<CopyToClipboard
							text={ this.state.address }
							onCopy={() => {
								this.setState({ copiedHint: true });
								setTimeout(() => { this.setState({ copiedHint: false }); }, 5*1000);
							}}
						>
								<button className="btn-copy">

										<span>{ this.state.address ? compactString(this.state.address) : '' }</span>

									<img src={ icon_i_copy } alt="" />
									<span className="btn-action-info" style={{display: this.state.copiedHint ? 'block' : 'none' }}>{ this.t('Copied') }</span>
								</button>
						</CopyToClipboard>
					</div>
				</Tippy>
				<div className="s-user__avatar">
					<Link to="/list">
						<div className="img">
							<Blockies
								seed      = { this.state.address }
								size      = {5}
								scale     = {10}
								color     = "#141616"
								bgColor   = "#4afebf"
								spotColor = "#ffffff"
							/>
						</div>
					</Link>
				</div>
			</div>
		)
	}
	getBtnOrData() {
		if ( !this.state.metamaskLogged ) {
			return this.getConnectBtn()
		} else {
			return this.getUserData()
		}
	}
	getTestLabel() {
		if ( this.state.metamaskLogged ) {
			if ( this.state.isTestNetwork ) {
				return (
					<div className="s-header__network">
						<img src={ icon_i_attention_red } alt="" />
						<span>{ this.t('Works in') } { this.state.chainName } { this.t('test') } { this.t('network') }</span>
					</div>
				)
			} else {
				return (
					<div className="s-header__network">
						<span style={{ color: '#4AFEBF' }}>{ this.t('Works in') } { this.state.chainName } {this.t('network') }</span>
					</div>
				);
			}
		} else {
			return '';
		}
	}

	render() {

		return (
			<React.Fragment>
				<div
					style={{
						width: '100%',
						textAlign: 'center',
						backgroundColor: 'rgb(74, 254, 191)',
						lineHeight: '30px',
						color: '#000',
						zIndex: 9,
					}}
				>We have new version of app. <a target="_blank" rel="noopener noreferrer" href="https://appv1.envelop.is">Try it out.</a></div>
				<header className="s-header">
					<div className="container">
						<div>
							<Link
								className="s-header__logo"
								to="/"
							>
								<img src={ icon_logo } alt="ENVELOP" />
							</Link>
							{ this.getTestLabel() }
						</div>
						{ this.getBtnOrData() }
					</div>
				</header>
			</React.Fragment>
		)
	}
}

export default withTranslation("translations")(withRouter(Header));