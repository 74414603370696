
import Web3         from 'web3';
import { Contract } from "web3-eth-contract";
import erc20_abi    from '../../abis/_erc20.json';
import {
	erc20CollateralContractParamsUpdate,
	erc20TechContractParamsUpdate
} from '../../reducers';

import default_icon from '../../static/pics/coins/_default.png';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type ERC20ContractPropsType = {
	web3                : Web3,
	store               : any,
	contractAddress     : string,
	contractType        : string,
	userAddress         : string,
	wrapperAddress      : string,
}
export type ERC20ContractParamsType = {
	address          : string,
	name             : string,
	symbol           : string,
	decimals         : number | undefined,
	icon             : string,
	balance          : BigNumber,
	allowance        : BigNumber,
}

export default class ERC20Contract {

	web3                : Web3;
	store               : any;
	contractAddress     : string;
	contractType        : string;
	userAddress         : string;
	contract            : Contract;
	erc20Params!        : ERC20ContractParamsType;
	wrapperAddress      : string;

	constructor(props: ERC20ContractPropsType) {
		this.web3                 = props.web3;
		this.store                = props.store;
		this.contractAddress      = props.contractAddress;
		this.contractType         = props.contractType;
		this.userAddress          = props.userAddress;
		this.wrapperAddress       = props.wrapperAddress;

		this.contract = new this.web3.eth.Contract(erc20_abi as any, this.contractAddress);
		// console.log('erc20', this.contract);
		this.getParams();
		this.addCheckoutEventListener();
	}

	async getParams() {
		const name              = await this.contract.methods.name().call();
		const symbol            = await this.contract.methods.symbol().call();
		const decimals          = await this.contract.methods.decimals().call();
		const balance           = new BigNumber(await this.contract.methods.balanceOf(this.userAddress).call());
		const allowance         = new BigNumber(await this.contract.methods.allowance(this.userAddress, this.wrapperAddress).call());

		let icon = default_icon;
		try { icon = require(`../../static/pics/coins/${symbol.toLowerCase()}.jpeg`).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${symbol.toLowerCase()}.jpg` ).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${symbol.toLowerCase()}.png` ).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${symbol.toLowerCase()}.svg` ).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${this.contractAddress}.jpeg`).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${this.contractAddress}.jpg` ).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${this.contractAddress}.png` ).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${this.contractAddress}.svg` ).default } catch (ignored) {}

		this.erc20Params = {
			address: this.contractAddress,
			name,
			symbol,
			decimals,
			icon,
			balance,
			allowance,
		}
		if ( this.contractType === 'tech' ) {
			this.store.dispatch(erc20TechContractParamsUpdate(this.erc20Params));
		} else {
			this.store.dispatch(erc20CollateralContractParamsUpdate(this.erc20Params));
		}
	}
	async addCheckoutEventListener() {
		const chainId =Number(await this.web3.eth.getChainId());
		if ( chainId === 56 ) { return; }

		this.contract.events.Approval(
			{
				filter: { owner: this.userAddress }
			},
			(e: any, data: any) => {
				this.getBalance();
			}
		);
		this.contract.events.Transfer(
			{
				filter: { from: this.userAddress }
			},
			(e: any, data: any) => {
				this.getBalance();
			}
		);
		this.contract.events.Transfer(
			{
				filter: { to: this.userAddress }
			},
			(e: any, data: any) => {
				this.getBalance();
			}
		);
	}

	async getBalance() {

		if ( !this.erc20Params ) { return; }

		const balance   = new BigNumber(await this.contract.methods.balanceOf(this.userAddress).call());
		const allowance = new BigNumber(await this.contract.methods.allowance(this.userAddress, this.wrapperAddress).call());

		this.erc20Params = {
			...this.erc20Params,
			balance,
			allowance,
		}

		if ( this.contractType === 'tech' ) {
			this.store.dispatch(erc20TechContractParamsUpdate(this.erc20Params));
		} else {
			this.store.dispatch(erc20CollateralContractParamsUpdate(this.erc20Params));
		}

		return {
			balance,
			allowance
		}
	}

	async getAllowanceToAddress(address: string) {
		const allowance = new BigNumber(await this.contract.methods.allowance(this.userAddress, address).call());
		return allowance;
	}

	makeAllowance(amount: BigNumber) {
		const parsedAmount = new BigNumber(amount).toString() === '-1' ? new BigNumber(10**50).toString() : new BigNumber(amount).toString();
		return this.contract.methods.approve(this.wrapperAddress, parsedAmount).send({ from: this.userAddress });
	}
	makeAllowanceTransfer(amount: BigNumber, addressTo: string) {
		const parsedAmount = new BigNumber(amount).toString() === '-1' ? new BigNumber(10**50).toString() : new BigNumber(amount).toString();
		return this.contract.methods.approve(addressTo, parsedAmount).send({ from: this.userAddress });
	}

}