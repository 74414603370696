
import React from 'react';
import {
	MetamaskAdapter,
	WrappedTokenType
} from '../../models/BlockchainAdapter';
import {
	setError,
	unsetLoading,
	setLoading,
	waitingTokensAdd,
	waitingTokensRemove,
	wrappedTokensClear,
	clearInfo,
	setInfo,
	setSuccess,
} from '../../reducers';
import {
	History,
} from 'history';

import { withTranslation } from "react-i18next";
import { setApprovalERC721Token } from '../../models/BlockchainAdapter/erc721contract';

import icon_onb_5 from '../../static/pics/onb-5.png';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type ApprovePopupProps = {
	store          : any,
	metamaskAdapter: MetamaskAdapter,
	t              : any,
	token          : WrappedTokenType,
	closePopup     : Function,
	history        : History,
}
type ApprovePopupState = {
	approvalToken?  : WrappedTokenType,
	isWrapped       : boolean,
	approveToAddress: string,
}

class ApprovePopup extends React.Component<ApprovePopupProps, ApprovePopupState> {

	store          : any;
	unsubscribe!   : Function;
	metamaskAdapter: MetamaskAdapter;
	t              : any;
	closePopup     : Function;
	history        : History;

	constructor(props: ApprovePopupProps) {
		super(props);

		this.store           = props.store;
		this.metamaskAdapter = props.metamaskAdapter;
		this.t               = props.t;
		this.closePopup      = props.closePopup;
		this.history         = props.history;

		const isWrapped = !!props.token &&
			(props.token.contractAddress.toLowerCase() === this.metamaskAdapter.wrapperContract.contractAddress.toLowerCase() ||
			!!this.metamaskAdapter.wrapperContract.previousContracts.filter((item) => { return props.token && item.address.toLowerCase() === props.token.contractAddress.toLowerCase() }).length);

		this.state = {
			approvalToken   : props.token,
			isWrapped       : isWrapped,
			approveToAddress: '',
		}
	}

	// componentDidMount() {

	// 	this.unsubscribe = this.store.subscribe(() => {

	// 		this.setState({
	// 		});
	// 	});
 	// }
	// componentWillUnmount() { this.unsubscribe(); }

	async approveWrappedTokenSubmit() {
		if ( !this.state.approvalToken ) { return; }
		await this.metamaskAdapter.wrapperContract.setApprovalForAll(this.state.approvalToken, this.state.approveToAddress);
		this.closePopup();
	}
	approveNotWrappedTokenSubmit() {
		if ( !this.state.approvalToken ) { return; }
		const addressTo = this.state.approveToAddress;

		this.store.dispatch(waitingTokensAdd({ token: this.state.approvalToken, msg: this.t('Waiting for approve') }));
		this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));

		setApprovalERC721Token(
			this.metamaskAdapter,
			this.state.approvalToken.contractAddress,
			this.store.getState().account.address,
			addressTo,
			this.t
		)
			.then((data) => {
				if ( this.state.approvalToken ) { this.store.dispatch(waitingTokensRemove(this.state.approvalToken)); }
				this.store.dispatch(unsetLoading());

				this.store.dispatch(wrappedTokensClear());
				this.metamaskAdapter.wrapperContract.getNFTTokens();
				this.metamaskAdapter.wrapperContract.updateNativeBalance();
				this.metamaskAdapter.wrapperContract.erc20Contract.getBalance();

				this.history.push('/list');

				this.store.dispatch(setInfo({
					text: `${this.t('Our tokens has been approved')} (${addressTo})`,
					 buttons: [{
						text: 'Ok',
						clickFunc: () => { this.store.dispatch(clearInfo()) }
					 }],
					links: [{
						text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
						url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
					}]
				}));
				this.store.dispatch(setSuccess({
					text: this.t('Our tokens has been approved'),
					icon: icon_onb_5,
					token: undefined,
					transactionHash: data.transactionHash
				}));
				this.closePopup();
			})
			.catch((e) => {
				console.log(e);
				if ( this.state.approvalToken ) { this.store.dispatch(waitingTokensRemove(this.state.approvalToken)); }
				this.store.dispatch(unsetLoading());

				let errorMsg = '';
				try {
					if ( 'message' in e ) {
						errorMsg = e.message;
						try {
							const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
							errorMsg = errorParsed.originalError.message;
						} catch(ignored) {}
					} else {
						errorMsg = `${e}`;
					}
				} catch (ignored) {
					errorMsg = `${e}`;
				}

				let links = undefined;
				try {
					if ('transactionHash' in e) {
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
					} else {
						try {
							const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
							const txHash = errorParsed.transactionHash;
							if ( txHash ) {
								links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
							}
						} catch(ignored) {}
					}
				} catch (ignored) {}

				this.store.dispatch(setError({
					text: `${this.t('Cannot approve token')}: ${errorMsg}`,
					buttons: undefined,
					links: links,
				}));
			});
	}
	getApproveSubmitBtn() {

		if ( !this.state.approvalToken ) { return ''; }

		if ( this.state.approveToAddress === '' ) {
			return (
				<button
					className="btn"
					type="button"
					disabled={ true }
				>{ this.t('Accept') }</button>
			)
		}
		if ( !this.metamaskAdapter.web3.utils.isAddress(this.state.approveToAddress) ) {
			return (
				<button
					className="btn"
					type="button"
					disabled={ true }
				>{ this.t('Wrong address') }</button>
			)
		}

		return (
			<button
				className="btn"
				type="button"
				onClick={() => {
					if ( this.state.isWrapped ) {
						this.approveWrappedTokenSubmit();
					} else {
						this.approveNotWrappedTokenSubmit();
					}
				}}
			>{ this.t('Accept') }</button>
		)
	}

	render() {
		return (
			<div className="modal">
				<div
					className="modal__inner"
					onClick={(e) => {
						e.stopPropagation();
						if ((e.target as HTMLTextAreaElement).className === 'modal__inner') {
							this.closePopup();
						}
					}}
				>
					<div className="modal__bg"></div>
					<div className="container">
						<div className="modal__content">
							<div
								className="modal__close"
								onClick={() => { this.closePopup() }}
							>
								<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z" fill="white"></path>
									<path fillRule="evenodd" clipRule="evenodd" d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z" fill="white"></path>
								</svg>
							</div>
							<div className="c-add">
								<div className="c-add__text">
									<div className="h2">{ this.t('Set approval for all ERC721 tokens of contract') }</div>
								</div>
								<div className="c-add__coins">
									<div className="c-add__form">
										<form
											onSubmit={(e) => {
												e.preventDefault();
												if ( this.state.isWrapped ) {
													this.approveWrappedTokenSubmit();
												} else {
													this.approveNotWrappedTokenSubmit();
												}
											}}
										>
											<div className="form-row">
												<div className="col">
													<input
														className="input-control"
														type="text"
														placeholder={ this.t('Paste address') }
														value={ this.state.approveToAddress }
														onChange={(e) => { this.setState({ approveToAddress: e.target.value }) }}
													/>
												</div>
												<div className="col">
													{ this.getApproveSubmitBtn() }
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation("translations")(ApprovePopup);