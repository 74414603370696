
import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

export const tokenToFloat = (value: BigNumber, decimals: number): BigNumber => {
	return value.multipliedBy( 10**-decimals );
}

export const  tokenToInt = (value: BigNumber, decimals: number): BigNumber => {
	return value.multipliedBy( 10**decimals );
}

export const addThousandSeparator = ( numStr: string ): string => {
	const parts = numStr.split(".");
	return parts[0]
		.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
		+ (parts[1] ? "." + parts[1] : "")
		+ ( numStr.endsWith('.') ? '.' : '' );
}
export const removeThousandSeparator = ( numStr: string ): string => {
	return numStr.replaceAll(',', '.').replaceAll(' ', '');
}

export const compactString = (str: string) => {
	if ( !str ) { return str }
	str = `${str}`;
	if ( str.length < 8 ) { return str }

	return `${str.slice(0,3)}...${str.slice(-3)}`
}

export const monthesNames = [
	'jan',
	'feb',
	'mar',
	'apr',
	'may',
	'june',
	'july',
	'aug',
	'sept',
	'oct',
	'nov',
	'dec',
];

export const unixtimeToStr = (unixtime: BigNumber): string => {
	const date = new Date(unixtime.toNumber());
	return `${date.getDate()} ${monthesNames[date.getMonth()]} ${date.getFullYear()}`;
}