
import React from 'react';
import { createStore } from 'redux';
import {
	HashRouter as Router,
	Link,
	Route,
	Switch,
} from "react-router-dom";
import 'tippy.js/dist/tippy.css';

import Header     from '../Header';
import Footer     from '../Footer';
import MainPage   from '../MainPage';
import NFTPreview from '../NFTPreview';
import NFTList    from '../NFTList';
import {
	MetamaskAdapter,
	WrappedTokenType
} from '../../models/BlockchainAdapter';
import {
	clearError,
	clearInfo,
	reducer,
	setAuthMethod
} from '../../reducers';

import icon_envelop       from '../../static/pics/envelop.svg';
import icon_error         from '../../static/pics/i-error.svg';
import metamask_icon      from '../../static/pics/i-metamask.svg';
import walletconnect_icon from '../../static/pics/i-connect.svg';

import bg_lg_left_1 from '../../static/pics/bg/bg-lg-left-1.svg';
import bg_lg_right_4 from '../../static/pics/bg/bg-lg-right-4.svg';

import youtube_icon from '../../static/pics/socials/youtube.svg'
import inst_icon from '../../static/pics/socials/in.svg'
import github_icon from '../../static/pics/socials/github.svg'
import telegram_icon from '../../static/pics/socials/telegram.svg'
import cn_tele_icon from '../../static/pics/socials/cn-telegram.svg'
import twitter_icon from '../../static/pics/socials/twitter.svg'
import med_icon from '../../static/pics/socials/m.svg'

import { withTranslation } from "react-i18next";

import BigNumber from 'bignumber.js';
import BatchWrapPage from '../BatchWrapPage';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type AppParamsType = {
	i18n: any,
	t   : any,
}
type AppState = {
	currentPage: string,
	loading    : string,
	error      : undefined | {
		text: string,
		buttons: undefined | Array<{
			text: string,
			clickFunc: Function,
		}>,
		links: undefined | Array<{
			text: string,
			url : string,
		}>,
	},
	info       : undefined | {
		text: string,
		buttons: undefined | Array<{
			text: string,
			clickFunc: Function,
		}>,
		links: undefined | Array<{
			text: string,
			url : string,
		}>,
	},
	success?   : {
		token          : WrappedTokenType,
		icon           : string,
		text           : string,
		transactionHash: string,
	},
	authMethodSelector: boolean,
	canCloseAuthMethodSelector: boolean,
}

class App extends React.Component<AppParamsType> {
	store          : any;
	metamaskAdapter: MetamaskAdapter;
	unsubscribe!   : Function;
	state          : AppState;

	i18n: any;
	t   : any;

	constructor(props: AppParamsType) {
		super(props);

		this.i18n = this.props.i18n;
		this.t    = this.props.t;

		if ( process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT.toLowerCase() === 'production' ) {
			this.store = createStore(reducer);
		} else {
			this.store = createStore(reducer,(window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__());
		}
		this.metamaskAdapter = new MetamaskAdapter({ store: this.store, t: this.t })

		this.state = {
			currentPage: this.store.getState().currentPage,
			loading    : this.store.getState()._loading,
			error      : this.store.getState()._error,
			info       : this.store.getState()._info,
			success    : this.store.getState()._success,
			authMethodSelector: false,
			canCloseAuthMethodSelector: true,
		};
	}

	async componentDidMount() {
		this.unsubscribe = this.store.subscribe(() => {

			// if ( this.state.currentPage !== this.store.getState().currentPage ) {
			// 	if ( this.store.getState().currentPage === 'list' ) {
			// 		window.scrollTo( 0, 0 );
			// 	}
			// }

			this.setState({
				currentPage: this.store.getState().currentPage,
				loading    : this.store.getState()._loading,
				error      : this.store.getState()._error,
				info       : this.store.getState()._info,
				success    : this.store.getState()._success,
			});
		});

		const prevAuthMethod = localStorage.getItem('provider_type');
		if ( prevAuthMethod ) {
			this.store.dispatch(setAuthMethod( prevAuthMethod ));
			await this.metamaskAdapter.connect();
		}
 	}
 	componentWillUnmount() { this.unsubscribe(); }

	getOverlays() {
		const getErrorBtns = (msg: string) => {
			if ( msg === 'error' ) {
				if (this.state.error?.buttons) {
					return this.state.error.buttons.map((item, idx) => {
						let btnClass = '';
						if ( this.state.error && this.state.error.buttons && idx + 1 === this.state.error.buttons.length ) {
							btnClass = 'btn'
						} else {
							btnClass = 'btn btn-grad'
						}
						return (
							<div
								className="col-12 col-sm-auto mb-3 mb-md-0"
								key={ item.text }
							>
								<button
									className={ btnClass }
									onClick={() => {
										item.clickFunc();
									}}
								>{ item.text }</button>
							</div>
						)
					})
				} else {
					return (
						<div
							className="col-12 col-sm-auto mb-3 mb-md-0"
							key={ '_default' }
						>
							<button
								className="btn btn-grad"
								onClick={() => {
									this.store.dispatch(clearError());
								}}
							>{ this.t('ACCEPT THIS FACT') }</button>
						</div>
					)
				}
			}
			if ( msg === 'info' ) {
				if (this.state.info?.buttons) {
					return this.state.info.buttons.map((item, idx) => {
						let btnClass = '';
						if ( this.state.info && this.state.info.buttons && idx + 1 === this.state.info.buttons.length ) {
							btnClass = 'btn'
						} else {
							btnClass = 'btn btn-grad'
						}
						return (
							<div
								className="col-12 col-sm-auto mb-3 mb-md-0"
								key={ item.text }
							>
								<button
									className={ btnClass }
									onClick={() => {
										item.clickFunc();
									}}
								>{ item.text }</button>
							</div>
						)
					})
				} else {
					return (
						<div
							className="col-12 col-sm-auto mb-3 mb-md-0"
							key={ '_default' }
						>
							<button
								className="btn btn-grad"
								onClick={() => {
									this.store.dispatch(clearInfo());
								}}
							>{ this.t('ACCEPT THIS FACT') }</button>
						</div>
					)
				}
			}
		}
		const getLinks = (msg: string) => {
			if ( msg === 'error' ) {
				if (this.state.error?.links) {
					return this.state.error.links.map((item) => {
						return (
							<div
								className="col-12 col-sm-auto mb-3 mb-md-0"
								key={ item.url }
							>
								<a
									className="btn-link"
									href={ item.url }
									target="_blank" rel="noopener noreferrer"
								>{ item.text }</a>
							</div>
						)
					})
				}
			}
			if ( msg === 'info' ) {
				if (this.state.info?.links) {
					return this.state.info.links.map((item) => {
						return (
							<div
								className="col-12 col-sm-auto mb-3 mb-md-0"
								key={ item.url }
							>
								<a
									className="btn-link"
									href={ item.url }
									target="_blank" rel="noopener noreferrer"
								>{ item.text }</a>
							</div>
						)
					})
				}
			}
		}

		if ( this.state.error ) {
			return (
				<div className="modal">
				<div className="modal__inner">
				<div className="modal__bg"></div>
				<div className="container">
				<div className="modal__content">
					<div className="c-success">
						<img className="c-success__img" src={ icon_error } alt="" />
						<div className="h2">{ this.t('Error Screen') }</div>
						<p>{ this.state.error.text }</p>
						<div className="modal__btns">
							{ getErrorBtns('error') }
							{ getLinks('error') }
						</div>
					</div>
				</div>
				</div>
				</div>
				</div>
			)
		}

		if ( this.state.loading ) {
			return (
				<div className="modal">
				<div className="modal__inner">
				<div className="modal__bg"></div>
				<div className="container">
				<div className="modal__content">
					<div className="c-success">
						<img className="c-success__img" src={ icon_envelop } alt="" />
						<div className="h2">
							{ this.t('Loading') }
							<span className="loading-dots"><span>.</span><span>.</span><span>.</span></span>
						</div>
						<p>{ this.state.loading }</p>
					</div>
				</div>
				</div>
				</div>
				</div>
			)
		}
		if ( this.state.info ) {
			return (
				<div className="modal">
				<div className="modal__inner">
				<div className="modal__bg"></div>
				<div className="container">
				<div className="modal__content">
					<div className="c-success">
						<img className="c-success__img" src={ icon_envelop } alt="" />
						<div className="h2">{ this.t('') }</div>
						<p>{ this.state.info.text }</p>
						<div className="modal__btns">
							{ getErrorBtns('info') }
							{ getLinks('info') }
						</div>
					</div>
				</div>
				</div>
				</div>
				</div>
			)
		}
		// if ( this.state.success ) {
		// 	return (
		// 		<SuccessPage store = { this.store } />
		// 	)
		// }
	}
	getAuthMethodSelector() {
		if ( !this.state.authMethodSelector ) { return '' }

		return (
			<div className="modal">
			<div className="modal__inner" onClick={ (e) => {
				e.stopPropagation();
				if ((e.target as HTMLTextAreaElement).className === 'modal__inner') {
					if ( this.state.canCloseAuthMethodSelector ) {
						this.setState({ authMethodSelector: false });
					} else {
						this.setState({ authMethodSelector: false });
						window.location.href = "/";
					}
				}
			}}>
			<div className="modal__bg"></div>
			<div className="container">
			<div className="modal__content">

				<div
					className="modal__close"
					onClick={() => {
						if ( this.state.canCloseAuthMethodSelector ) {
							this.setState({ authMethodSelector: false });
						} else {
							this.setState({ authMethodSelector: false });
							window.location.href = "/";
						}
					}}
				>
					<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z" fill="white"></path>
						<path fillRule="evenodd" clipRule="evenodd" d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z" fill="white"></path>
					</svg>
				</div>
				<div className="modal__header">
					<div className="h2">Choose your wallet</div>
				</div>
				<div className="c-connect">
					<div className="modal__btns">
						<div className="col mb-4 mb-sm-0">
							<button
								className="btn-wallet"
								style={{ width: '100%' }}
								onClick={async () => {
									this.store.dispatch(setAuthMethod('METAMASK'));
									await this.metamaskAdapter.connect();
									this.setState({ authMethodSelector: false });
								}}
							>
								<span className="img">
									<img src={ metamask_icon } alt="" />
								</span>
								<span>Metamask</span>
							</button>
						</div>
						<div className="col">
						<button
									className="btn-wallet"
									style={{ width: '100%' }}
									onClick={async () => {
										this.store.dispatch(setAuthMethod('WALLET_CONNECT'));
										await this.metamaskAdapter.connect();
										this.setState({ authMethodSelector: false });
									}}
								>
								<span className="img">
									<img src={ walletconnect_icon } alt="" />
								</span>
								<span>Walletconnect</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			</div>
			</div>
			</div>
		)
	}

	render() {
		return (
			<Router>
				{/* <button onClick={() => { this.i18n.changeLanguage('en');     }}>EN</button>
				<button onClick={() => { this.i18n.changeLanguage('ru_lol'); }}>RU_LOL</button> */}
				<Header
					store           = { this.store }
					metamaskAdapter = { this.metamaskAdapter }
					showAuthMethodSelector = {() => { this.setState({ authMethodSelector: true }) }}
				/>

				<Switch>
					<Route path="/list">
						<NFTList
							store           = { this.store }
							metamaskAdapter = { this.metamaskAdapter }
							showAuthMethodSelector = {() => { this.setState({ authMethodSelector: true }) }}
						/>
					</Route>
					<Route path="/token">
						<NFTPreview
							store           = { this.store }
							metamaskAdapter = { this.metamaskAdapter }
							showAuthMethodSelector = {( canClose: boolean ) => { this.setState({ authMethodSelector: true, canCloseAuthMethodSelector: canClose }) }}
						/>
					</Route>


					<Route path="/" exact>
						<MainPage store = { this.store } />
					</Route>

					<Route path="/saft" exact>
						<BatchWrapPage
							store = { this.store }
							metamaskAdapter = { this.metamaskAdapter }
							showAuthMethodSelector = {( canClose: boolean ) => { this.setState({ authMethodSelector: true, canCloseAuthMethodSelector: canClose }) }}
							hideAuthMethodSelector = {() => { this.setState({ authMethodSelector: false }) }}
						/>
					</Route>

					<Route path="*">
						<main className="s-main">
						<div className="sec-error">
							<img className="sec-bg bg-left d-none d-lg-block" src={ bg_lg_left_1 } alt="" />
							<img className="sec-bg bg-right d-none d-lg-block" src={ bg_lg_right_4 } alt=""/ >
							<div className="sec-error__text">
								<h1>{ this.t('ERROR 404') }</h1>
								<div className="h1_sub text-grad">{ this.t('PAGE NOT FOUND') }</div>
								<p>{ this.t('We couldn\'t find the page you\'re looking for.') }</p>
								<p className="actions">
									<Link to="/">{ this.t('Return Home') }</Link>
									<span>|</span>
									<a href="https://docs.envelop.is/" target="_blank" rel="noopener noreferrer">{ this.t('Documentation') }</a>
								</p>
								<ul className="socials d-lg-none">
									<li><a href="https://www.youtube.com/c/ENVELOP" target="_blank" rel="noopener noreferrer"><img src={ youtube_icon } alt="ENVELOP. NFTs YouTube Channel" /></a></li>
									<li><a href="https://www.linkedin.com/company/niftsy" target="_blank" rel="noopener noreferrer"><img src={ inst_icon } alt="NIFTSY is token" /></a></li>
									<li><a href="https://github.com/niftsy/niftsysmarts" target="_blank" rel="noopener noreferrer"><img src={ github_icon } alt="Github of our NFT project" /></a></li>
									<li><a href="https://t.me/envelop_en" target="_blank" rel="noopener noreferrer"><img src={ telegram_icon }alt="ENVELOP telegram group" /></a></li>
									<li><a href="https://t.me/envelop_cn" target="_blank" rel="noopener noreferrer"><img src={ cn_tele_icon } alt="ENVELOP telegram group (China)" /></a></li>
									<li><a href="https://twitter.com/Envelop_project" target="_blank" rel="noopener noreferrer"><img src={ twitter_icon } alt="Our twitter" /></a></li>
									<li><a href="https://envelop.medium.com/" target="_blank" rel="noopener noreferrer"><img src={ med_icon } alt="Blog about Web 3.0" /></a></li>
								</ul>
							</div>
						</div>
						</main>
					</Route>
				</Switch>

				{ this.getAuthMethodSelector() }
				{ this.getOverlays() }
				<Footer />
			</Router>
		)
	}
}

export default withTranslation("translations")(App);