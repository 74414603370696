
import React               from 'react';
import Tippy               from '@tippyjs/react';
import {
	ERC20ContractParamsType,
	MetamaskAdapter,
	WrappedTokenType
} from '../../models/BlockchainAdapter';
import {
	setError,
	unsetLoading,
	setLoading,
	waitingTokensRemove,
	waitingTokensAdd,
} from '../../reducers';

import {
	addThousandSeparator,
	tokenToFloat,
	tokenToInt
} from '../../models/_utils';
import { withTranslation } from "react-i18next";

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type AddValuePopupProps = {
	store          : any,
	metamaskAdapter: MetamaskAdapter,
	t              : any,
	token          : WrappedTokenType,
	closePopup     : Function,
}
type AddValuePopupState = {

	addValueToken?    : WrappedTokenType,
	addValueValue     : string,
	addValueERC20Token: ERC20ContractParamsType | undefined,
	checkoutApproving : boolean,
	checkoutProcessing: boolean,

	balanceNative : BigNumber,
	decimalsNative: number,
	symbolNative  : string,
	iconNative    : string,

	explorerBaseUrl      : string,
	erc20CollateralTokens: Array<ERC20ContractParamsType>,
	tokenAllowance: BigNumber,

	niftsyTokenIcon: string,
}

class AddValuePopup extends React.Component<AddValuePopupProps, AddValuePopupState> {

	store          : any;
	unsubscribe!   : Function;
	metamaskAdapter: MetamaskAdapter;
	t              : any;
	closePopup     : Function;

	constructor(props: AddValuePopupProps) {
		super(props);

		this.store           = props.store;
		this.metamaskAdapter = props.metamaskAdapter;
		this.t               = props.t;
		this.closePopup      = props.closePopup;

		const erc20CollateralTokensFiltered = this.store.getState().erc20CollateralTokens.filter((item: ERC20ContractParamsType) => {
			if ( !item.address ) { return false; }
			if ( props.token.contractAddress.toLowerCase() === this.metamaskAdapter.wrapperContract.contractAddress.toLowerCase() ) {
				return this.metamaskAdapter.wrapperContract.wrapperAllowedERC20.includes( item.address );
			}
			const foundContract = this.metamaskAdapter.wrapperContract.previousContracts.filter((iitem) => {
				return props.token.contractAddress.toLowerCase() === iitem.address.toLowerCase()
			})
			if ( foundContract.length ) {
				return foundContract[0].allowedERC20
					.map((iitem) => { return iitem.toLowerCase() })
					.includes( item.address.toLowerCase() );
			}

			return false;
		});

		let tokenAllowance = new BigNumber(0);
		if ( erc20CollateralTokensFiltered.length ) {
			const foundERC20 = this.metamaskAdapter.getERC20Contract( erc20CollateralTokensFiltered[0].address );
			if ( foundERC20 ) {
				foundERC20.getAllowanceToAddress( props.token.contractAddress )
				.then((data) => { this.setState({ tokenAllowance: new BigNumber(data) }) })
			}
		}

		this.state = {
			addValueToken     : props.token,
			addValueValue     : '',
			addValueERC20Token: erc20CollateralTokensFiltered.length
				? erc20CollateralTokensFiltered[0]
				: undefined,
			checkoutApproving : false,
			checkoutProcessing: false,
			balanceNative     : this.store.getState().account.balanceNative,
			decimalsNative    : this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative      : this.store.getState().metamaskAdapter.networkTokenTicket,
			iconNative        : this.store.getState().metamaskAdapter.networkTokenIcon,
			explorerBaseUrl   : this.store.getState().metamaskAdapter.explorerBaseUrl,

			erc20CollateralTokens: erc20CollateralTokensFiltered,
			tokenAllowance: tokenAllowance,
			niftsyTokenIcon: 'https://envelop.is/assets/img/niftsy.svg',
		}
	}

	componentDidMount() {

		this.unsubscribe = this.store.subscribe(() => {

			const erc20CollateralTokensFiltered = this.store.getState().erc20CollateralTokens.filter((item: ERC20ContractParamsType) => {
				if ( !item.address ) { return false; }
				if ( this.state.addValueToken?.contractAddress.toLowerCase() === this.metamaskAdapter.wrapperContract.contractAddress.toLowerCase() ) {
					return this.metamaskAdapter.wrapperContract.wrapperAllowedERC20.includes( item.address );
				}
				const foundContract = this.metamaskAdapter.wrapperContract.previousContracts.filter((iitem) => {
					return this.state.addValueToken?.contractAddress.toLowerCase() === iitem.address.toLowerCase()
				})
				if ( foundContract.length ) {
					return foundContract[0].allowedERC20.map((iitem) => { return iitem.toLowerCase() }).includes( item.address.toLowerCase() );
				}

				return false;
			});

			let addValueERC20TokenUpdate = this.state.addValueERC20Token;

			if ( this.state.addValueERC20Token ) {
				const tokenFound = erc20CollateralTokensFiltered.filter((item: ERC20ContractParamsType) => {
					if ( !item.address || !this.state.addValueERC20Token ) { return false; }
					return item.address.toLowerCase() === this.state.addValueERC20Token.address.toLowerCase()
				});
				if ( tokenFound.length ) {
					addValueERC20TokenUpdate = tokenFound[0]

					if ( addValueERC20TokenUpdate ) {
						const foundERC20 = this.metamaskAdapter.getERC20Contract( addValueERC20TokenUpdate.address );
						if ( foundERC20 ) {
							if ( this.state.addValueToken ) {
								foundERC20.getAllowanceToAddress( this.state.addValueToken.contractAddress )
								.then((data) => { this.setState({ tokenAllowance: new BigNumber(data) }) })
							}
						}
					}

				}

			}

			this.setState({
				balanceNative        : this.store.getState().account.balanceNative,
				decimalsNative       : this.store.getState().metamaskAdapter.networkTokenDecimals,
				iconNative           : this.store.getState().metamaskAdapter.networkTokenIcon,
				symbolNative         : this.store.getState().metamaskAdapter.networkTokenTicket,
				explorerBaseUrl      : this.store.getState().metamaskAdapter.explorerBaseUrl,
				addValueERC20Token   : addValueERC20TokenUpdate,

				erc20CollateralTokens: erc20CollateralTokensFiltered,
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	addValueSubmit() {
		if ( !this.state.addValueToken ) { return; }
		this.setState({ checkoutProcessing: true });

		let decimals = this.state.decimalsNative;
		if ( this.state.addValueERC20Token ) { decimals = this.state.addValueERC20Token.decimals || 18; }
		const valueToAdd = tokenToInt(new BigNumber(this.state.addValueValue), decimals);
		this.metamaskAdapter.wrapperContract.addCollateral(this.state.addValueToken, valueToAdd, this.state.addValueERC20Token)
			.then((data) => {
				this.closePopup();
			});
	}
	getAddValueBalanceBlock() {
		if ( this.state.addValueERC20Token && this.metamaskAdapter.wrapperContract.canWrapper('erc20collateral', this.state.addValueToken?.contractAddress) ) {
			return (
				<div className="c-add__max">
					<div>
						<span>{ this.t('Max') }: </span>
						<button
							onClick={(e) => {
								e.preventDefault();
								if ( this.state.addValueERC20Token ) {
									if ( new BigNumber(this.state.addValueERC20Token.balance).eq(0) ) { return; }
									this.setState({
										addValueValue: tokenToFloat(new BigNumber(this.state.addValueERC20Token.balance), this.state.addValueERC20Token.decimals || 18).toString()
									});
								}
							}}
						>
							{ addThousandSeparator(tokenToFloat(new BigNumber(this.state.addValueERC20Token.balance), this.state.addValueERC20Token.decimals || 18).toString()) }
							{ ' ' }
							{ this.state.addValueERC20Token.symbol }
						</button>
					</div>
					<div>
						<span>{ this.t('Allowance') }: </span>
						<button
							onClick={(e) => {
								e.preventDefault();
								if ( this.state.addValueERC20Token ) {
									if ( new BigNumber(this.state.tokenAllowance).eq(0) ) { return; }
									this.setState({
										addValueValue: tokenToFloat(new BigNumber(this.state.tokenAllowance), this.state.addValueERC20Token.decimals || 18).toString()
									});
								}
							}}
						>
							{ addThousandSeparator(tokenToFloat(new BigNumber(this.state.tokenAllowance), this.state.addValueERC20Token.decimals || 18).toString()) }
							{ ' ' }
							{ this.state.addValueERC20Token.symbol }
						</button>
					</div>
				</div>
			)
		} else {
			return (
				<div className="c-add__max">
					<div>
						<span>{ this.t('Max') }: </span>
						<button
							onClick={(e) => {
								e.preventDefault();
								if ( new BigNumber(this.state.balanceNative).eq(0) ) { return; }
								this.setState({
									addValueValue: addThousandSeparator(tokenToFloat(this.state.balanceNative, this.state.decimalsNative).toString())
								});
							}}
						>
							{ tokenToFloat(this.state.balanceNative, this.state.decimalsNative).toString() }
							{ ' ' }
							{ this.state.symbolNative }
						</button>
					</div>
				</div>
			)
		}
	}
	getAddValueTokenSelector() {
		return (
			<div className="select-coin">
				<div className="select-coin__value">
					{
						this.state.addValueERC20Token && this.metamaskAdapter.wrapperContract.canWrapper('erc20collateral', this.state.addValueToken?.contractAddress) ?
						(
							<span className="field-unit">
								<span className="i-coin"><img src={ this.state.addValueERC20Token.icon } alt="" /></span>
								{ this.state.addValueERC20Token.symbol }
							</span>
						) :
						(
							<span className="field-unit">
								<span className="i-coin"><img src={ this.state.iconNative } alt="" /></span>
								{ this.state.symbolNative }
							</span>
						)
					}
					<svg className="arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 0.529297L5 4.76459L9 0.529297" stroke="white"></path>
					</svg>
				</div>
				<ul className="select-coin__list">
					<li
						key={ '' }
						onClick={() => { this.setState({ addValueERC20Token: undefined }) }}
					><span className="field-unit"><span className="i-coin"><img src={ this.state.iconNative } alt="" /></span>{ this.state.symbolNative }</span></li>
					{
						this.metamaskAdapter.wrapperContract.canWrapper('erc20collateral', this.state.addValueToken?.contractAddress) && this.state.erc20CollateralTokens.map((item) => {
							return (
								<li
									key={ item.address }
									onClick={() => {
										const foundERC20 = this.metamaskAdapter.getERC20Contract( item.address );
										if ( foundERC20 && this.state.addValueToken ) {
											foundERC20.getAllowanceToAddress( this.state.addValueToken.contractAddress )
											.then((data) => { this.setState({ tokenAllowance: new BigNumber(data) }) })
										}
										this.setState({ addValueERC20Token: item })
									}}
								><span className="field-unit"><span className="i-coin"><img src={ item.icon } alt="" /></span>{ item.symbol }</span></li>
							)
						})
					}
				</ul>
			</div>
		)
	}
	checkoutERC20Approve = () => {
		if ( !this.state.addValueERC20Token ) { return; }
		if ( !this.state.addValueToken ) { return; }
		this.setState({ checkoutApproving: true });
		this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));
		this.store.dispatch(waitingTokensAdd({ token: this.state.addValueToken, msg: this.t('Waiting for approve') }));

		const erc20Contract = this.metamaskAdapter.getERC20Contract(this.state.addValueERC20Token.address);
		erc20Contract?.makeAllowanceTransfer( tokenToInt(new BigNumber(this.state.addValueValue), this.state.addValueERC20Token.decimals || 18), this.state.addValueToken.contractAddress )
			.then(() => {
				erc20Contract.getBalance();
				this.setState({ checkoutApproving: false });
				if ( this.state.addValueToken ) { this.store.dispatch(waitingTokensRemove(this.state.addValueToken)) };
				this.store.dispatch(unsetLoading());
			})
			.catch((e: any) => {
				console.log(e);
				this.setState({ checkoutApproving: false });
				if ( this.state.addValueToken ) { this.store.dispatch(waitingTokensRemove(this.state.addValueToken)) };
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `Cannot make allowance: ${e.message.split('\n')[0]}`,
					buttons: undefined,
					links: undefined
				}));
		});
	}
	getAddValueBtn() {

		if ( this.state.addValueValue === '' || new BigNumber(this.state.addValueValue).eq('0') ) { return ( <button className="btn btn-grad" disabled={ true }>{ this.t('Checkout') }</button> ) }
		if ( this.state.checkoutApproving ) {
			return (
				<button className="btn" disabled={ true }>
					{ this.t('Waiting for approve') }
				</button>
			)
		}
		if ( this.state.checkoutProcessing ) {
			return (
				<button className="btn" disabled={ true }>
					{ this.t('Waiting for refill') }
				</button>
			)
		}
		if ( this.state.addValueERC20Token ) {
			// ERC20
			if ( tokenToInt(new BigNumber(this.state.addValueValue), this.state.addValueERC20Token.decimals || 18).gt(new BigNumber(this.state.addValueERC20Token.balance)) ){
				return ( <button className="btn" disabled={ true }>{ this.t('Not enough') }</button> )
			}
			if ( tokenToInt(new BigNumber(this.state.addValueValue), this.state.addValueERC20Token.decimals || 18).gt(new BigNumber(this.state.tokenAllowance)) ){
				return ( <button
					className="btn"
					onClick={() => { this.checkoutERC20Approve() }}
				>{ this.t('Approve') }</button> )
			}
			return (
				<button
					className="btn"
					onClick={() => { this.addValueSubmit() }}
				>{ this.t('Checkout') }</button>
			)
		} else {
			// NATIVE
			if ( tokenToInt(new BigNumber(this.state.addValueValue), this.state.decimalsNative).gt(new BigNumber(this.state.balanceNative)) ){
				return ( <button className="btn" disabled={ true }>{ this.t('Not enough') }</button> )
			}
			return (
				<button
					className="btn"
					onClick={() => { this.addValueSubmit() }}
				>{ this.t('Checkout') }</button>
			)
		}
	}

	render() {

		let tokenImage = '';
		if ( this.state.addValueERC20Token && this.state.addValueERC20Token.name.toLowerCase() === 'niftsy' ) {
			tokenImage = this.state.niftsyTokenIcon
		}

		return (
			<div className="modal">
				<div
					className="modal__inner"
					onClick={(e) => {
						e.stopPropagation();
						if ((e.target as HTMLTextAreaElement).className === 'modal__inner') {
							this.closePopup();
						}
					}}
				>
					<div className="modal__bg"></div>
					<div className="container">
						<div className="modal__content">
							<div
								className="modal__close"
								onClick={() => { this.closePopup() }}
							>
								<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z" fill="white"></path>
									<path fillRule="evenodd" clipRule="evenodd" d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z" fill="white"></path>
								</svg>
							</div>
							<div className="c-add">
								<div className="c-add__text">
									<div className="h2">{ this.t('Add Collateral') }</div>
									<p>{ this.t('You can add assets to collateral of your wrapped NFT. Use list of approved tokens.') }</p>
									{
										this.state.addValueERC20Token &&
										tokenToInt(new BigNumber(this.state.addValueValue), this.state.addValueERC20Token.decimals || 18).gt(new BigNumber(this.state.tokenAllowance)) &&
										!tokenToInt(new BigNumber(this.state.addValueValue), this.state.addValueERC20Token.decimals || 18).gt(new BigNumber(this.state.addValueERC20Token.balance))
											? ( <p>{ this.t('Please, give permission smart contract to spend your tokens for wNFT purchase. Push approve button') }</p> ) : ''
									}
								</div>
								<div className="c-add__coins">
									<label className="input-label">
										{ this.t('Amount') }
											<Tippy
												content={ this.t('Maximum and allowanced amount of tokens which you can add to collateral of wrapped nft') }
												appendTo={ document.getElementsByClassName("wrapper")[0] }
												trigger='mouseenter'
												interactive={ false }
												arrow={ false }
												maxWidth={ 260 }
											>
												<span className="i-tip black"></span>
											</Tippy>
										</label>
										{ this.getAddValueBalanceBlock() }
									<div className="c-add__form">
										<form
											onSubmit={(e) => {
												e.preventDefault();
												// this.addValueSubmit();
											}}
										>
											<div className="form-row">
												<div className="col">
													<div className="input-group">
														<input
															className="input-control"
															type="text"
															placeholder="0.000"
															value={ addThousandSeparator(this.state.addValueValue) }
															onChange={(e) => {
																let value = e.target.value.replaceAll(',', '.').replaceAll(' ', '')//.replace(/(?<=\..*)\./g, '');
																if ( value.split('.')[1] && value.split('.')[1].length > this.state.decimalsNative ) { return; }
																if ( value.startsWith('00') ) { return; }
																if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
																	if ( new BigNumber(value).isNaN() ) { return; }
																	value = new BigNumber(value).toString();
																}
																this.setState({
																	addValueValue: value
																});
															}}
														/>
														{ this.getAddValueTokenSelector() }
													</div>
													{
														this.state.addValueERC20Token
															? (
																<div className="link-about">
																	<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${this.state.addValueERC20Token.address}` }>More about { this.state.addValueERC20Token.symbol }</a>
																	<br />
																	<br />
																	<a href="/"
																		onClick={(e) => {
																			e.preventDefault();
																			try {
																				if ( !(window as any).ethereum ) { return; }

																				(window as any).ethereum.request({
																					method: 'wallet_watchAsset',
																					params: {
																						type: 'ERC20', // Initially only supports ERC20, but eventually more!
																						options: {
																							address: this.state.addValueERC20Token?.address, // The address that the token is at.
																							symbol: this.state.addValueERC20Token?.symbol, // A ticker symbol or shorthand, up to 5 chars.
																							decimals: this.state.addValueERC20Token?.decimals, // The number of decimals in the token
																							image: tokenImage, // A string url of the token logo
																						},
																					},
																				});
																			} catch(e) {
																				console.log(e)
																			}
																		}}
																	>
																		Add { this.state.addValueERC20Token.symbol } to Metamask
																	</a>
																</div>
															)
															: ''
													}

												</div>
												<div className="col">
													{ this.getAddValueBtn() }
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation("translations")(AddValuePopup);