
import {
	ERC20ContractParamsType,
	WrappedTokenType,
	WrappedTokensStatType,
	ChainParamsType,
} from '../models/BlockchainAdapter';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type StateType = {
	currentPage      : string,
	gotoListRequested: boolean,
	_loading         : string,
	_error           : undefined | {
		text   : string,
		buttons: undefined | Array<{
			text     : string,
			clickFunc: Function,
		}>,
		links: undefined | Array<{
			text: string,
			url : string,
		}>,
	},
	_info    : undefined | {
		text   : string,
		buttons: undefined | Array<{
			text     : string,
			clickFunc: Function,
		}>,
		links: undefined | Array<{
			text: string,
			url : string,
		}>,
	},
	_success?: {
		token?         : WrappedTokenType,
		icon           : string,
		text           : string,
		transactionHash: string,
	}
	account: {
		address      : String,
		balanceNative: BigNumber,
	},
	metamaskAdapter: {
		logged              : Boolean,
		metamaskNotInstalled: Boolean,
		permissionRejected  : Boolean,
		chainId             : Number,
		requestChainId      : number | undefined,
		availableChains     : Array<ChainParamsType>,
		authMethod          : string,
	},
	transferModelAllowances : Array<{
		wrapperAddress      : string,
		transferModelAddress: string,
		erc20TokenAddress   : string,
		allowance: BigNumber,
	}>,
	erc20TechTokenParams  : ERC20ContractParamsType,
	erc20CollateralTokens : Array<ERC20ContractParamsType>,
	wrappedTokens         : Array<WrappedTokenType>,
	discoveredTokens      : Array<WrappedTokenType>,
	ignoredTokens         : Array<{ contractAddress: string, tokenId: string }>,
	wrappedTokensStat     : WrappedTokensStatType,
	incompleteTokens      : Array<WrappedTokenType>,
	waitingTokens         : Array<{ token: WrappedTokenType, msg: string }>,
	tokenPreview          : WrappedTokenType | undefined,
	tokenLoadingInProgress: boolean,
	batchWrapSubscription : {
		ticketParams: {
			paymentToken     : string,
			paymentAmount    : BigNumber,
			timelockPeriod   : BigNumber,
			ticketValidPeriod: BigNumber,
		},
		endTime        : BigNumber,
		contractAddress: string,
	} | undefined,
};

export const initialState: StateType = {
	currentPage      : '',
	gotoListRequested: false,
	_loading         : '',
	_error           : undefined,
	_info            : undefined,
	_success         : undefined,
	account          : {
		address      : '',
		balanceNative: new BigNumber(0),
	},
	metamaskAdapter: {
		logged              : false,
		metamaskNotInstalled: false,
		permissionRejected  : false,
		chainId             : 0,
		requestChainId      : undefined,
		availableChains     : [],
		authMethod          : '',
	},
	transferModelAllowances: [],
	erc20CollateralTokens: [],
	erc20TechTokenParams: {
		address          : '',
		name             : '',
		symbol           : '',
		decimals         : undefined,
		icon             : '',
		balance          : new BigNumber(0),
		allowance        : new BigNumber(0),
	},
	incompleteTokens: [],
	wrappedTokens   : [],
	discoveredTokens: [],
	ignoredTokens   : [],
	waitingTokens   : [],
	wrappedTokensStat: {
		count          : 0,
		collateral     : new BigNumber(0),
		erc20Collateral: [],
		fee            : [],
		royalties      : [],
	},
	tokenPreview: undefined,
	tokenLoadingInProgress: false,
	batchWrapSubscription: undefined,
}

export const reducer = (state = initialState, action: any): StateType => {

	switch ( action.type ) {

		// ---------- NAVIGATION ----------
		case 'GOTO_MAIN': {
			return {
				...state,
				currentPage: '',
			}
		}
		case 'GOTO_PREVIEW': {
			return {
				...state,
				currentPage: 'preview',
			}
		}
		case 'GOTO_LIST': {
			return {
				...state,
				currentPage: 'list',
			}
		}
		case 'SET_LOADING': {
			return {
				...state,
				_loading: action.payload.msg,
			}
		}
		case 'UNSET_LOADING': {
			return {
				...state,
				_loading: '',
			}
		}
		case 'SET_ERROR': {
			return {
				...state,
				_error: action.payload,
			}
		}
		case 'CLEAR_ERROR': {
			return {
				...state,
				_error: undefined,
			}
		}
		case 'SET_INFO': {
			return {
				...state,
				_info: action.payload,
			}
		}
		case 'CLEAR_INFO': {
			return {
				...state,
				_info: undefined,
			}
		}
		case 'SET_SUCCESS': {
			return {
				...state,
				_success: action.payload,
			}
		}
		case 'CLEAR_SUCCESS': {
			return {
				...state,
				_success: undefined,
			}
		}
		case 'RESET_APP_DATA': {
			return {
				...initialState,
				currentPage: state.currentPage === 'list' ? state.currentPage : initialState.currentPage,
				metamaskAdapter: {
					...initialState.metamaskAdapter,
					availableChains: state.metamaskAdapter.availableChains,
				}
			};
		}
		case 'GOTO_LIST_REQUEST': {
			return {
				...state,
				gotoListRequested: true,
			}
		}
		case 'GOTO_LIST_RESOLVE': {
			return {
				...state,
				gotoListRequested: false,
				currentPage: state.currentPage === '' ? 'list' : state.currentPage,
			}
		}
		// ---------- END NAVIGATION ----------
		// ---------- CONNECTION ----------
		case 'METAMASK_CONNECTION_SUCCESS': {
			return {
				...state,
				metamaskAdapter: {
					...state.metamaskAdapter,
					metamaskNotInstalled: false,
					permissionRejected  : false,
					logged              : true,
				},
				account: {
					...state.account,
					address: action.payload.address,
				}
			}
		}
		case 'METAMASK_CONNECTION_NOT_INSTALLED': {
			return {
				...state,
				metamaskAdapter: {
					...initialState.metamaskAdapter,
					metamaskNotInstalled: true,
				}
			}
		}
		case 'METAMASK_CONNECTION_REJECTED': {
			return {
				...state,
				metamaskAdapter: {
					...initialState.metamaskAdapter,
					permissionRejected: true,
					authMethod: state.metamaskAdapter.authMethod,
				}
			}
		}
		case 'METAMASK_SET_CHAIN_PARAMS': {
			return {
				...state,
				metamaskAdapter: {
					...state.metamaskAdapter,
					...action.payload,
				}
			}
		}
		case 'METAMASK_SET_AVAILABLE_CHAINS': {
			return {
				...state,
				metamaskAdapter: {
					...state.metamaskAdapter,
					availableChains: action.payload,
				}
			}
		}
		case 'SET_AUTH_METHOD': {
			return {
				...state,
				metamaskAdapter: {
					...state.metamaskAdapter,
					authMethod: action.payload,
				}
			}
		}
		case 'UNSET_AUTH_METHOD': {
			return {
				...state,
				metamaskAdapter: {
					...state.metamaskAdapter,
					authMethod: '',
				}
			}
		}
		case 'REQUEST_CHAIN': {
			return {
				...state,
				metamaskAdapter: {
					...state.metamaskAdapter,
					requestChainId: action.payload,
				}
			}
		}
		// ---------- END CONNECTION ----------

		// ---------- NATIVE TOKEN ----------
		case 'UPDATE_NATIVE_BALANCE': {
			return {
				...state,
				account: {
					...state.account,
					balanceNative: action.payload.balance,
				}
			}
		}
		// ---------- END NATIVE TOKEN ----------

		// ---------- WRAPPER CONTRACT ----------
		case 'UPDATE_TRANSFER_ALLOWANCE': {
			return {
				...state,
				transferModelAllowances: [
					...state.transferModelAllowances.filter((item) => {
						return item.wrapperAddress.toLowerCase() !== action.payload.wrapperAddress.toLowerCase() ||
						item.erc20TokenAddress.toLowerCase() !== action.payload.erc20TokenAddress.toLowerCase() ||
						item.transferModelAddress.toLowerCase() !== action.payload.transferModelAddress.toLowerCase()
					}),
					action.payload
				]
			}
		}
		// ---------- END WRAPPER CONTRACT ----------
		// ---------- ERC20 CONTRACT ----------
		case 'ERC20_TECH_CONTRACT_PARAMS_UPDATE': {
			return {
				...state,
				erc20TechTokenParams: {
					...state.erc20TechTokenParams,
					...action.payload,
				}
			}
		}
		case 'ERC20_COLATERAL_CONTRACT_PARAMS_UPDATE': {
			return {
				...state,
				erc20CollateralTokens: [
					...state.erc20CollateralTokens.filter((item) => {
						if ( !item.address || !action.payload.address ) { return false; }
						return item.address.toLowerCase() !== action.payload.address.toLowerCase() }),
					action.payload,
				]
			}
		}
		// ---------- END ERC20 CONTRACT ----------

		// ---------- ERC721 FETCH ----------
		case 'INCOMPLETE_TOKENS_ADD': {
			const foundToken = state.wrappedTokens.filter((item) => { return item.contractAddress.toLowerCase() === action.payload.contractAddress.toLowerCase() && item.tokenId === action.payload.tokenId });
			if ( foundToken.length ) { return state }
			return {
				...state,
				incompleteTokens: [
					...state.incompleteTokens.filter((item) => {
						return (item.contractAddress.toLowerCase() !== action.payload.contractAddress.toLowerCase() || item.tokenId !== action.payload.tokenId ) &&
						(item.contractAddress.toLowerCase() !== action.payload.originalContractAddress.toLowerCase() || item.tokenId !== action.payload.originalTokenId )
					}),
					action.payload,
				]
			}
		}
		case 'INCOMPLETE_TOKENS_REMOVE': {
			return {
				...state,
				incompleteTokens: [
					...state.incompleteTokens.filter((item) => {
						return (item.contractAddress.toLowerCase() !== action.payload.contractAddress.toLowerCase() || item.tokenId !== action.payload.tokenId ) &&
						(item.contractAddress.toLowerCase() !== action.payload.originalContractAddress.toLowerCase() || item.tokenId !== action.payload.originalTokenId )
					}),
				]
			}
		}
		case 'WRAPPED_TOKENS_ADD': {
			return {
				...state,
				wrappedTokens: [
					...state.wrappedTokens.filter((item) => { return item.contractAddress.toLowerCase() !== action.payload.contractAddress.toLowerCase() || item.tokenId !== action.payload.tokenId }),
					action.payload,
				]
			}
		}
		case 'WRAPPED_TOKENS_REMOVE': {
			return {
				...state,
				wrappedTokens: [
					...state.wrappedTokens.filter((item) => { return item.contractAddress.toLowerCase() !== action.payload.contractAddress.toLowerCase() || item.tokenId !== action.payload.tokenId }),
				]
			}
		}
		case 'WRAPPED_TOKENS_CLEAR': {
			return {
				...state,
				wrappedTokens: initialState.wrappedTokens,
				wrappedTokensStat: initialState.wrappedTokensStat,
			}
		}
		case 'TOKEN_UPDATE': {
			return {
				...state,
				_loading: '',
				tokenPreview: {
					...state.tokenPreview,
					...action.payload,
				}
			}
		}
		case 'TOKEN_PREVIEW_CLEAR': {
			return {
				...state,
				tokenPreview: undefined
			}
		}
		case 'WRAPPED_STATS_UPDATE': {
			return {
				...state,
				wrappedTokensStat: {
					...state.wrappedTokensStat,
					...action.payload,
				}
			}
		}
		case 'DISCOVERED_TOKENS_ADD': {
			return {
				...state,
				discoveredTokens: [
					...state.discoveredTokens.filter((item) => { return item.contractAddress.toLowerCase() !== action.payload.contractAddress.toLowerCase() || `${item.tokenId}` !== `${action.payload.tokenId}` }),
					action.payload,
				]
			}
		}
		case 'DISCOVERED_TOKENS_REMOVE': {
			return {
				...state,
				discoveredTokens: [
					...state.discoveredTokens.filter((item) => { return item.contractAddress.toLowerCase() !== action.payload.contractAddress.toLowerCase() || `${item.tokenId}` !== `${action.payload.tokenId}` }),
				]
			}
		}
		case 'DISCOVERED_TOKENS_CLEAR': {
			return {
				...state,
				discoveredTokens: initialState.discoveredTokens,
			}
		}
		case 'IGNORED_TOKENS_ADD': {
			return {
				...state,
				ignoredTokens: [
					...state.ignoredTokens.filter((item) => { return item.contractAddress.toLowerCase() !== action.payload.contractAddress.toLowerCase() || `${item.tokenId}` !== `${action.payload.tokenId}` }),
					action.payload,
				]
			}
		}
		case 'SET_TOKENS_LOADING': {
			return {
				...state,
				tokenLoadingInProgress: true
			}
		}
		case 'UNSET_TOKENS_LOADING': {
			return {
				...state,
				tokenLoadingInProgress: false
			}
		}
		// ---------- END ERC721 FETCH ----------

		// ---------- WAITING TOKEN ----------
		case 'WAITING_TOKENS_ADD': {
			return {
				...state,
				waitingTokens: [
					...state.waitingTokens.filter((item) => { return item.token.tokenUrl !== action.payload.token.tokenUrl }),
					action.payload,
				]
			}
		}
		case 'WAITING_TOKENS_REMOVE': {
			return {
				...state,
				waitingTokens: [
					...state.waitingTokens.filter((item) => { return item.token.tokenUrl !== action.payload.tokenUrl }),
				]
			}
		}
		// ---------- END WAITING TOKEN ----------

		// ---------- BATCHWRAP SUBSCRIPTION ----------
		case 'BATCHWRAP_SUBSCRIPTION_UPDATE_PARAMS': {
			return {
				...state,
				batchWrapSubscription: {
					...state.batchWrapSubscription,
					...action.payload
				}
			}
		}
		case 'BATCHWRAP_SUBSCRIPTION_END_TIME': {
			if ( !state.batchWrapSubscription ) {
				return {
					...state,
					batchWrapSubscription: {
						ticketParams: {
							paymentToken     : '',
							paymentAmount    : new BigNumber(0),
							timelockPeriod   : new BigNumber(0),
							ticketValidPeriod: new BigNumber(0),
						},
						contractAddress: '',
						endTime: action.payload.endTime,
					}
				}
			}
			return {
				...state,
				batchWrapSubscription: {
					...state.batchWrapSubscription,
					endTime: action.payload.endTime
				}
			}
		}
		// ---------- END BATCHWRAP SUBSCRIPTION ----------


		default: { return state }

	}
}