
import React from 'react';
import {
	ERC20ContractParamsType,
	MetamaskAdapter,
	transferERC721Token,
	WrappedTokenType
} from '../../models/BlockchainAdapter';
import {
	setError,
	unsetLoading,
	setLoading,
	waitingTokensAdd,
	waitingTokensRemove,
	wrappedTokensClear,
	clearInfo,
	setInfo,
	setSuccess,
	ignoredTokensAdd,
} from '../../reducers';
import {
	History,
} from 'history';

import { withTranslation } from "react-i18next";

import icon_onb_5 from '../../static/pics/onb-5.png';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type TransferPopupProps = {
	store          : any,
	metamaskAdapter: MetamaskAdapter,
	t              : any,
	token          : WrappedTokenType,
	closePopup     : Function,
	history        : History,
}
type TransferPopupState = {
	transferToken?       : WrappedTokenType,
	isWrapped            : boolean,
	transferAddress      : string,
	erc20CollateralTokens: Array<ERC20ContractParamsType>,
	transferAllowances   : Array<{ wrapperAddress: string, transferModelAddress: string, erc20TokenAddress: string, allowance: BigNumber }>,
}

class TransferPopup extends React.Component<TransferPopupProps, TransferPopupState> {

	store          : any;
	unsubscribe!   : Function;
	metamaskAdapter: MetamaskAdapter;
	t              : any;
	closePopup     : Function;
	history        : History;

	constructor(props: TransferPopupProps) {
		super(props);

		this.store           = props.store;
		this.metamaskAdapter = props.metamaskAdapter;
		this.t               = props.t;
		this.closePopup      = props.closePopup;
		this.history         = props.history;

		const isWrapped = !!props.token &&
			(props.token.contractAddress.toLowerCase() === this.metamaskAdapter.wrapperContract.contractAddress.toLowerCase() ||
			!!this.metamaskAdapter.wrapperContract.previousContracts.filter((item) => { return props.token && item.address.toLowerCase() === props.token.contractAddress.toLowerCase() }).length);

		this.state = {
			transferToken        : props.token,
			isWrapped            : isWrapped,
			transferAddress      : '',
			erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
			transferAllowances   : this.store.getState().transferModelAllowances,
		}
	}

	componentDidMount() {

		this.unsubscribe = this.store.subscribe(() => {

			this.setState({
				erc20CollateralTokens: this.store.getState().erc20CollateralTokens,
				transferAllowances   : this.store.getState().transferModelAllowances,
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	async transferWrappedTokenSubmit() {
		if ( !this.state.transferToken ) { return; }
		const addressTo = this.state.transferAddress;

		if (
			this.state.transferToken.transferFee &&
			this.state.transferToken.transferFeeToken && this.state.transferToken.transferFeeToken !== '0x0000000000000000000000000000000000000000'
		) {
			const foundToken = this.state.erc20CollateralTokens.filter((item) => {
				if ( !item.address ) { return false; }
				return item.address.toLowerCase() === this.state.transferToken?.transferFeeToken.toLowerCase()
			});
			if ( !foundToken ) { return; }
			if ( foundToken[0].balance.lt(new BigNumber(this.state.transferToken.transferFee)) ) {
				this.store.dispatch(setError({ text: `Not enough ${foundToken[0].symbol}`, buttons: undefined, links: undefined }));
				return;
			}
		}
		await this.metamaskAdapter.wrapperContract.transferToken(this.state.transferToken, addressTo, this.history);
		this.closePopup();
	}
	async approveERC20ForTransferWrapped() {
		if ( !this.state.transferToken ) { return; }
		if ( !this.state.transferToken.transferFee ) { return; }

		const erc20Contract = this.metamaskAdapter.getERC20Contract(this.state.transferToken.transferFeeToken);
		if ( !erc20Contract ) { return; }

		this.store.dispatch(setLoading({ msg: 'Waiting for approve' }));
		try {
			let addressTo = this.state.transferToken.contractAddress;
			const foundAllowance = this.state.transferAllowances
				.filter((item) => { return item.wrapperAddress.toLowerCase() === this.state.transferToken?.contractAddress.toLowerCase() })
				.filter((item) => { return item.erc20TokenAddress.toLowerCase() === this.state.transferToken?.transferFeeToken.toLowerCase() })
			if ( foundAllowance.length ) { addressTo = foundAllowance[0].transferModelAddress }
			await erc20Contract.makeAllowanceTransfer(this.state.transferToken.transferFee, addressTo);
		} catch(e: any) {
			console.log(e);
			this.store.dispatch(setError({ text: e.message, buttons: undefined, links: undefined }))
			this.store.dispatch(unsetLoading());
		}
		this.metamaskAdapter.wrapperContract.updateTransferAllowance();
		this.store.dispatch(unsetLoading());
	}
	getWrappedTransferSubmitBtn() {
		if ( !this.state.transferToken ) { return ''; }
		if ( !this.state.transferToken.transferFee ) { return ''; }

		if ( !this.state.transferToken?.transferFeeToken || this.state.transferToken?.transferFeeToken === '0x0000000000000000000000000000000000000000' ) {
			return (
				<button
					className="btn"
					type="button"
					onClick={(e) => {
						this.transferWrappedTokenSubmit()
					}}
				>{ this.t('Accept') }</button>
			)
		}

		const foundToken = this.state.erc20CollateralTokens.filter((item) => {
			if ( !item.address ) { return false; }
			return item.address.toLowerCase() === this.state.transferToken?.transferFeeToken.toLowerCase()
		});
		if ( !foundToken ) {
			return (
				<button
					className="btn"
					type="button"
					onClick={(e) => {
						this.transferWrappedTokenSubmit()
					}}
				>{ this.t('Accept') }</button>
			)
		}

		if ( foundToken[0].balance.lt(new BigNumber(this.state.transferToken.transferFee)) ) {
			return (
				<button
					className="btn"
					type="button"
					disabled={ true }
				>{ this.t('Not enough') }</button>
			)
		}

		const foundAllowance = this.state.transferAllowances
			.filter((item) => { return item.wrapperAddress.toLowerCase() === this.state.transferToken?.contractAddress.toLowerCase() })
			.filter((item) => { return item.erc20TokenAddress.toLowerCase() === this.state.transferToken?.transferFeeToken.toLowerCase() })
		if ( foundAllowance.length && foundAllowance[0].allowance.lt(new BigNumber(this.state.transferToken.transferFee)) ) {
			return (
				<button
					className="btn"
					type="button"
					onClick={(e) => {
						this.approveERC20ForTransferWrapped()
					}}
				>{ this.t('Approve') }</button>
			)
		}

		return (
			<button
				className="btn"
				type="button"
				onClick={(e) => {
					this.transferWrappedTokenSubmit()
				}}
			>{ this.t('Accept') }</button>
		)
	}
	async transferNotWrappedTokenSubmit() {
		if ( !this.state.transferToken ) { return; }
		const addressTo = this.state.transferAddress;

		this.store.dispatch(waitingTokensAdd({ token: this.state.transferToken, msg: this.t('Waiting for transfer') }));
		this.store.dispatch(setLoading({ msg: this.t('Waiting for transfer') }));

		transferERC721Token(
			this.metamaskAdapter,
			this.state.transferToken.contractAddress,
			this.state.transferToken.tokenId,
			this.store.getState().account.address,
			addressTo,
			this.t
		)
			.then((data) => {
				if ( this.state.transferToken ) {
					this.store.dispatch(ignoredTokensAdd({ contractAddress: this.state.transferToken.contractAddress, tokenId: this.state.transferToken.tokenId }));
					this.store.dispatch(waitingTokensRemove(this.state.transferToken));
				}
				this.store.dispatch(unsetLoading());

				this.store.dispatch(wrappedTokensClear());
				this.metamaskAdapter.wrapperContract.getNFTTokens();
				this.metamaskAdapter.wrapperContract.updateNativeBalance();
				this.metamaskAdapter.wrapperContract.erc20Contract.getBalance();

				this.history.push('/list');

				this.store.dispatch(setInfo({
					text: `${this.t('Our token has been transferred')} (${addressTo})`,
					 buttons: [{
						text: 'Ok',
						clickFunc: () => { this.store.dispatch(clearInfo()) }
					 }],
					links: [{
						text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
						url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
					}]
				}));
				this.store.dispatch(setSuccess({
					text: this.t('Our token has been transferred'),
					icon: icon_onb_5,
					token: undefined,
					transactionHash: data.transactionHash
				}));
				this.closePopup();
			})
			.catch((e) => {
				console.log(e);
				if ( this.state.transferToken ) { this.store.dispatch(waitingTokensRemove(this.state.transferToken)); }
				this.store.dispatch(unsetLoading());

				let errorMsg = '';
				try {
					if ( 'message' in e ) {
						errorMsg = e.message;
						try {
							const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
							errorMsg = errorParsed.originalError.message;
						} catch(ignored) {}
					} else {
						errorMsg = `${e}`;
					}
				} catch (ignored) {
					errorMsg = `${e}`;
				}

				let links = undefined;
				try {
					if ('transactionHash' in e) {
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
					} else {
						try {
							const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
							const txHash = errorParsed.transactionHash;
							if ( txHash ) {
								links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
							}
						} catch(ignored) {}
					}
				} catch (ignored) {}

				this.store.dispatch(setError({
					text: `${this.t('Cannot wrap token')}: ${errorMsg}`,
					buttons: undefined,
					links: links,
				}));
			});
	}
	getNotWrappedTransferSubmitBtn() {
		return (
			<button
				className="btn"
				type="button"
				onClick={(e) => {
					this.transferNotWrappedTokenSubmit()
				}}
			>{ this.t('Accept') }</button>
		)
	}
	getTransferSubmitBtn() {

		if ( !this.state.transferToken ) { return ''; }

		if ( this.state.transferAddress === '' ) {
			return (
				<button
					className="btn"
					type="button"
					disabled={ true }
				>{ this.t('Accept') }</button>
			)
		}
		if ( !this.metamaskAdapter.web3.utils.isAddress(this.state.transferAddress) ) {
			return (
				<button
					className="btn"
					type="button"
					disabled={ true }
				>{ this.t('Wrong address') }</button>
			)
		}

		if ( this.state.isWrapped ) {
			return this.getWrappedTransferSubmitBtn();
		} else {
			return this.getNotWrappedTransferSubmitBtn();
		}

	}

	getApproveLabel() {
		if ( !this.state.isWrapped ) { return ''; }
		if ( !this.state.transferToken ) { return ''; }
		if ( !this.state.transferToken.transferFee ) { return ''; }

		if ( !this.metamaskAdapter.web3.utils.isAddress(this.state.transferAddress) ) {
			return ''
		}

		if ( !this.state.transferToken?.transferFeeToken || this.state.transferToken?.transferFeeToken === '0x0000000000000000000000000000000000000000' ) {
			return ( <p>{ this.t('After this action you will not own this wrapped NFT') }</p> )
		}

		const foundToken = this.state.erc20CollateralTokens.filter((item) => {
			if ( !item.address ) { return false; }
			return item.address.toLowerCase() === this.state.transferToken?.transferFeeToken.toLowerCase()
		});
		if ( !foundToken ) {
			return ( <p>{ this.t('After this action you will not own this wrapped NFT') }</p> )
		}

		if ( foundToken[0].balance.lt(new BigNumber(this.state.transferToken.transferFee)) ) {
			return '';
		}

		const foundAllowance = this.state.transferAllowances
			.filter((item) => { return item.wrapperAddress.toLowerCase() === this.state.transferToken?.contractAddress.toLowerCase() })
			.filter((item) => { return item.erc20TokenAddress.toLowerCase() === this.state.transferToken?.transferFeeToken.toLowerCase() })
		if ( foundAllowance.length && foundAllowance[0].allowance.lt(new BigNumber(this.state.transferToken.transferFee)) ) {
			return ( <p>{ this.t('Please, give permission smart contract to spend your tokens for wNFT transfer. Push approve button') }</p> )
		}

		return ( <p>{ this.t('After this action you will not own this wrapped NFT') }</p> )
	}

	render() {
		return (
			<div className="modal">
				<div
					className="modal__inner"
					onClick={(e) => {
						e.stopPropagation();
						if ((e.target as HTMLTextAreaElement).className === 'modal__inner') {
							this.closePopup();
						}
					}}
				>
					<div className="modal__bg"></div>
					<div className="container">
						<div className="modal__content">
							<div
								className="modal__close"
								onClick={() => { this.closePopup() }}
							>
								<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z" fill="white"></path>
									<path fillRule="evenodd" clipRule="evenodd" d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z" fill="white"></path>
								</svg>
							</div>
							<div className="c-add">
								<div className="c-add__text">
									<div className="h2">{ this.t('Transfer token') }</div>
									{ this.getApproveLabel() }
								</div>
								<div className="c-add__coins">
									<div className="c-add__form">
										<form
											onSubmit={(e) => {
												e.preventDefault();
												if ( this.state.isWrapped ) {
													this.transferWrappedTokenSubmit()
												} else {
													this.transferNotWrappedTokenSubmit()
												}
											}}
										>
											<div className="form-row">
												<div className="col">
													<input
														className="input-control"
														type="text"
														placeholder={ this.t('Paste address') }
														value={ this.state.transferAddress }
														onChange={(e) => { this.setState({ transferAddress: e.target.value }) }}
													/>
												</div>
												<div className="col">
													{ this.getTransferSubmitBtn() }
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation("translations")(TransferPopup);