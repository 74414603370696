
import React    from 'react';
import Tippy    from '@tippyjs/react';
import Dropzone from 'react-dropzone';
import {
	withRouter,
	match,
} from 'react-router-dom';
import {
	BatchWrapCollateralItem,
	BatchWrapRecipientItem,
	ERC20ContractParamsType,
	MetamaskAdapter,
} from '../../models/BlockchainAdapter';

import {
	History,
	Location
} from 'history';
import { withTranslation, } from "react-i18next";

import { clearError, clearInfo, setError, setInfo, setLoading, unsetLoading } from '../../reducers';

import {
	addThousandSeparator,
	compactString,
	monthesNames,
	removeThousandSeparator,
	tokenToFloat,
	tokenToInt
} from '../../models/_utils';

import icon_i_del from '../../static/pics/i-del.svg';
import default_icon  from '../../static/pics/coins/_default.png';
import icon_i_attention from '../../static/pics/i-attention.svg';

import erc721_abi from '../../abis/_erc721.json';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type BatchWrapPageProps = {
	store                 : any,
	metamaskAdapter       : MetamaskAdapter,
	showAuthMethodSelector: Function,
	hideAuthMethodSelector: Function,
	t                     : any,
	match                 : match;
	location              : Location,
	history               : History,
}
type BatchWrapPageState = {
	decimalsNative   : number,
	symbolNative     : string,
	iconNative       : string,
	EIPPrefix        : string,

	userAddress      : string,

	techToken        : ERC20ContractParamsType,

	chainId          : number,
	metamaskLogged   : boolean,
	explorerBaseUrl  : string,

	erc20CollateralTokens: Array<ERC20ContractParamsType>,

	inProgressPopup: boolean,
	authMethodSelectorShown: boolean,

	emptyChecked: boolean,
	mintChecked: boolean,
	collectionName: string,
	badContract: boolean,
	nftAddressInput: string,
	unlockTimeInput: string,
	recipients: Array<BatchWrapRecipientItem>,
	recipientAddressInput: string,
	recipientTokenIdInput: string,

	collaterals: Array<BatchWrapCollateralItem>,
	collateralTokenAddressInput: string,
	collateralAmountInput: string,

	tokenSelectorWidth: number,

	subscription: {
		ticketParams: {
			paymentToken     : string,
			paymentAmount    : BigNumber,
			timelockPeriod   : BigNumber,
			ticketValidPeriod: BigNumber,
		},
		endTime        : BigNumber,
		contractAddress: string,
	} | undefined,
	subscriptionContractAllowance: BigNumber,
	subscriptionPopup            : boolean,
	checkoutApproving            : boolean,
	checkoutProcessing           : boolean,
}

class BatchWrapPage extends React.Component<BatchWrapPageProps, BatchWrapPageState> {

	store                 : any;
	unsubscribe!          : Function;
	metamaskAdapter       : MetamaskAdapter;
	showAuthMethodSelector: Function;
	hideAuthMethodSelector: Function;
	t                     : any;

	constructor(props: BatchWrapPageProps) {
		super(props);

		this.store                  = props.store;
		this.metamaskAdapter        = props.metamaskAdapter;
		this.showAuthMethodSelector = props.showAuthMethodSelector;
		this.hideAuthMethodSelector = props.hideAuthMethodSelector;
		this.t                      = props.t;

		let authMethodSelectorShownUpdated = false
		if ( !this.store.getState().metamaskAdapter.logged ) {
			this.showAuthMethodSelector();
			authMethodSelectorShownUpdated = true;
		}

		this.state = {
			decimalsNative   : this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative     : this.store.getState().metamaskAdapter.networkTokenTicket,
			iconNative       : this.store.getState().metamaskAdapter.networkTokenIcon,
			EIPPrefix        : this.store.getState().metamaskAdapter.EIPPrefix,

			userAddress      : this.store.getState().account.address,

			techToken        : this.store.getState().erc20TechTokenParams,

			chainId          : this.store.getState().metamaskAdapter.chainId,
			metamaskLogged   : this.store.getState().metamaskAdapter.logged,
			explorerBaseUrl  : this.store.getState().metamaskAdapter.explorerBaseUrl,

			erc20CollateralTokens: this.store.getState().erc20CollateralTokens,

			inProgressPopup: false,
			authMethodSelectorShown: authMethodSelectorShownUpdated,

			emptyChecked: false,
			mintChecked: false,
			collectionName: '',
			badContract: false,
			nftAddressInput: '',
			unlockTimeInput: '',
			recipients: [],
			recipientAddressInput: '',
			recipientTokenIdInput: '',
			collaterals: [],
			collateralTokenAddressInput: '',
			collateralAmountInput: '',
			tokenSelectorWidth: 75,

			subscription: this.store.getState().batchWrapSubscription,
			subscriptionPopup: false,
			checkoutApproving: false,
			checkoutProcessing: false,
			subscriptionContractAllowance: new BigNumber(0),
		}
	}

	componentDidMount() {

		if (
			this.metamaskAdapter.wrapperContract &&
			this.metamaskAdapter.wrapperContract.contractAddress &&
			!this.store.getState()._error
		) {
			if ( !this.metamaskAdapter.wrapperContract.canWrapper('batchWrap', this.metamaskAdapter.wrapperContract.contractAddress) ) {
				console.log(
					'Contract does not support this operation:',
					this.metamaskAdapter.wrapperContract.contractAddress,
				);
				this.store.dispatch(setError({
					text: `Contract does not support this operation`,
					buttons: [{
						text: this.t('Go to main page'),
						clickFunc: () => {
							this.props.history.push(`/list`);
							setTimeout(() => { this.store.dispatch(clearError()) }, 100);
						}
					}],
					links: undefined,
				}));
			}
		}

		this.unsubscribe = this.store.subscribe(() => {

			if (
				this.metamaskAdapter.wrapperContract &&
				this.metamaskAdapter.wrapperContract.contractAddress &&
				!this.store.getState()._error
			) {
				if ( !this.metamaskAdapter.wrapperContract.canWrapper('batchWrap', this.metamaskAdapter.wrapperContract.contractAddress) ) {
					console.log(
						'Contract does not support this operation:',
						this.metamaskAdapter.wrapperContract.contractAddress,
					);
					this.store.dispatch(setError({
						text: `Contract does not support this operation`,
						buttons: [{
							text: this.t('Go to main page'),
							clickFunc: () => {
								this.props.history.push(`/list`);
								setTimeout(() => { this.store.dispatch(clearError()) }, 100);
							}
						}],
						links: undefined,
					}));
				}
			}

			let authMethodSelectorShownUpdated = this.state.authMethodSelectorShown;
			if ( !this.store.getState().metamaskAdapter.logged && !this.state.authMethodSelectorShown ) {
				this.showAuthMethodSelector();
				authMethodSelectorShownUpdated = true;
			}
			if ( this.store.getState().metamaskAdapter.logged && this.state.authMethodSelectorShown ) {
				this.hideAuthMethodSelector();
				authMethodSelectorShownUpdated = false;
			}

			this.setState({
				decimalsNative       : this.store.getState().metamaskAdapter.networkTokenDecimals,
				iconNative           : this.store.getState().metamaskAdapter.networkTokenIcon,
				symbolNative         : this.store.getState().metamaskAdapter.networkTokenTicket,
				techToken            : this.store.getState().erc20TechTokenParams,
				EIPPrefix            : this.store.getState().metamaskAdapter.EIPPrefix,

				userAddress          : this.store.getState().account.address,

				chainId              : this.store.getState().metamaskAdapter.chainId,
				metamaskLogged       : this.store.getState().metamaskAdapter.logged,
				explorerBaseUrl      : this.store.getState().metamaskAdapter.explorerBaseUrl,
				erc20CollateralTokens: this.store.getState().erc20CollateralTokens,

				authMethodSelectorShown: authMethodSelectorShownUpdated,

				subscription: this.store.getState().batchWrapSubscription,
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	getDaysPretty() {
		const daysToAdd = parseInt(this.state.unlockTimeInput) || 0;
		if ( daysToAdd === 0 ) { return '' }
		if ( daysToAdd > 36500 ) { return this.t('100 years max') }

		const output = new Date();
		output.setDate(output.getDate() + daysToAdd);
		return `${output.getDate()} ${monthesNames[output.getMonth()]} ${output.getFullYear()}`;
	}
	addDays(num: number) {
		const output = new Date();
		output.setDate(output.getDate() + num);
		return output;
	}
	async fetchCollectionName(address: string) {
		if (
			!this.metamaskAdapter.web3 ||
			!address ||
			!this.metamaskAdapter.web3.utils.isAddress(address) ||
			this.state.collectionName
		) { return; }

		try {
			const contract = new this.metamaskAdapter.web3.eth.Contract(erc721_abi as any, address);
			const name = await contract.methods.name().call();
			this.setState({ collectionName: name })
		} catch (ignored) {
			this.setState({ badContract: true });
		}
	}
	getCollectionBlock() {
		if ( this.state.badContract ) {
			return (
				<div className="collection-name">
					<span>{ this.t('Cannot connect to contract') }</span>
				</div>
			)
		} else {
			if ( this.state.collectionName !== '' ) {
				return (
					<div className="collection-name">
						<span>{ this.t('Collection') }:&nbsp;</span>
						<b>{ this.state.collectionName }</b>
					</div>
				)
			}
		}

		return null;
	}
	getMintBlock() {
		return (
			<div className="c-wrap c-b-wrap">
				<div className="c-wrap__header justify-content-start">
					<div className="h3">{ this.t('SAFT') }</div>
					<label className="checkbox ml-5">
						<input
							type="checkbox"
							checked={ this.state.emptyChecked }
							disabled={ !this.isSubscriptionActive() }
							onChange={(e) => {
								this.setState({ emptyChecked: e.target.checked })
							}}
						/>
						<span className="check"></span>
						<span>{ this.t('Еmpty') }</span>
					</label>
				</div>
				<div className="c-wrap__form">
					<form>
						<div className="row">
							<div className="col col-12 col-md-5 col-lg-6 order-1 order-md-1">
								<label className="input-label">{ this.t('Original NFT Address') }</label>
								<input
									className="input-control"
									type="text"
									placeholder="Paste here"
									disabled={ this.state.emptyChecked || !this.isSubscriptionActive() }
									value={ this.state.nftAddressInput }
									onChange={(e) => {
										this.fetchCollectionName(e.target.value);
										this.setState({
											nftAddressInput: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, ""),
											collectionName: '',
											badContract: false,
										})
									}}
								/>
								{ this.getCollectionBlock() }
							</div>
							<div className="col col-12 col-md-4 col-lg-3 order-3 order-md-2">
								<label className="input-label">{ `${this.t('Time Unlock')} (${this.t('days')})` }</label>
								<input
									className="input-control"
									type="text"
									placeholder=""
									value={ this.state.unlockTimeInput }
									disabled={ !this.isSubscriptionActive() }
									onChange={(e) => {
										const value = e.target.value.replaceAll(' ', '');
										if (
											value === '' ||
											isNaN(parseInt(value))
										) {
											this.setState({ unlockTimeInput: '' })
											return;
										}

										this.setState({ unlockTimeInput: `${parseInt(value)}` })
									}}
								/>
								{
									this.state.unlockTimeInput !== '' ?
									( <div className="collection-name">
										<span>{ this.t('Date') }:&nbsp;</span>
										<b>{ this.getDaysPretty() }</b>
									</div> ) : null
								}
							</div>
							{/* <div className="col col-12 col-md-3 col-lg-3 order-4 order-md-3 mt-2 mt-md-0 pb-md-3 align-self-end">
								<label className="checkbox">
								<input
									type="checkbox"
									checked={ this.state.mintChecked }
									onChange={(e) => { this.setState({ mintChecked: e.target.checked }) }}
								/>
								<span className="check"></span>
								<span>Mint</span>
								</label>
							</div> */}
						</div>
					</form>
				</div>
			</div>
		)
	}
	getRecipientRow(recipient: BatchWrapRecipientItem, position: number) {
		return (
			<div className="item" key={ recipient.timeAdded }>
				<div className="row">
					<div className="col-md-1 mb-2">#{ position }</div>
					<div className="col-md-5 mb-2">
						<span className="text-break">{ recipient.userAddress }</span>
					</div>
					<div className="col-md-5 mb-2">{ !this.state.emptyChecked ? `ID ${ recipient.tokenId }` : null }</div>
					<button
						className="btn-del"
						onClick={(e) => {
							const filteredRecipients = this.state.recipients.filter((item) => { return item.timeAdded !== recipient.timeAdded });
							this.setState({ recipients: filteredRecipients })
						}}
					>
						<img src={ icon_i_del } alt="" />
					</button>
				</div>
			</div>
		)
	}
	addRecipientDisabled() {
		if ( !this.isSubscriptionActive() ) { return this.t('Add') }
		if (
			!this.metamaskAdapter.web3 || !this.metamaskAdapter.web3.utils.isAddress(this.state.recipientAddressInput)
		) {
			return this.t('Wrong address')
		}

		if ( !this.state.emptyChecked && !this.state.recipientTokenIdInput ) { return this.t('Empty token id') }

		return ''
	}
	recipientsOnDrop(files: Array<File>) {
		if ( !this.isSubscriptionActive() ) { return; }

		files.forEach((item) => {
			const fileReader = new FileReader();
			fileReader.addEventListener('load', (e) => {
				let text = e.target?.result;
				if ( typeof(text) !== 'string' ) {
					return;
				}
				if ( !text ) { return; }
				const rows = text.split(/\r?\n/);

				rows.forEach((iitem) => {
					if ( this.metamaskAdapter.web3 &&
						this.metamaskAdapter.web3.utils.isAddress(iitem)
					) {
						// whole string is address
						this.addRecipientRow(iitem, '');
					} else {
						// two comma separated values
						try {
							const address = iitem.split(';')[0];
							const tokenId = iitem.split(';')[1].replaceAll(',', '.');

							if (
								!this.metamaskAdapter.web3 ||
								!this.metamaskAdapter.web3.utils.isAddress(address)
							) { return null; }

							this.addRecipientRow(address, tokenId);
						} catch(e) { console.log('Cannot add row from file', iitem) }
					}
				});

			});
			fileReader.readAsText(item)
		})
	}
	addRecipientRow(address: string, tokenId: string) {
		const foundToken = this.state.recipients.filter((item) => {
			if ( !item.tokenId ) { return false; }
			return item.tokenId === tokenId;
		});
		if ( foundToken.length ) { return; }

		const recipientsUpdated: Array<BatchWrapRecipientItem> = [
			// ...this.state.recipients.filter((item) => { return item.tokenId !== tokenId }),
			...this.state.recipients,
			{
				timeAdded: new Date().getTime(),
				userAddress: address,
				tokenId: tokenId
			}
		]
		this.setState({
			recipients: recipientsUpdated,
			recipientAddressInput: '',
			recipientTokenIdInput: '',
		});
	}
	getRecipientsBlock() {
		return (
			<Dropzone
				onDrop={(files) => { this.recipientsOnDrop(files) }}
				accept={ '.txt, .csv' }
				noClick={ true }
				noKeyboard={ true }
			>
				{
					({
						getRootProps, getInputProps, isDragActive, open
					}) => (
						<div {...getRootProps({ className: `c-wrap c-b-wrap ${isDragActive ? 'file-dragged' : ''}` })}>

							<div className="upload-poopover">
								<div className="inner">
									<div className="h3">{ this.t('Drop your file here') }</div>
								</div>
							</div>

							<input {...getInputProps()} />
							<div className="c-wrap__header">
								<div className="h3">{ this.t('Recipients') }</div>
							</div>
							<div className="c-wrap__form">
								<div className="row">
									<div className="col col-12 col-md-5">
										<label className="input-label">{ this.t('Recipient Address') }</label>
										<input
											className="input-control"
											type="text"
											placeholder={ this.t('Paste here') }
											value={ this.state.recipientAddressInput }
											disabled={ !this.isSubscriptionActive() }
											onChange={(e) => { this.setState({ recipientAddressInput: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "") }) }}
											onKeyPress={(e) => {
												if ( e.defaultPrevented)             { return; }
												if ( !!this.addRecipientDisabled() ) { return; }
												if ( e.key !== 'Enter' )             { return; }

												this.addRecipientRow(this.state.recipientAddressInput, this.state.recipientTokenIdInput);
											}}
										/>
									</div>
									<div className="col col-12 col-md-4">
										<label className="input-label">{ this.t('Original NFT token ID') }</label>
										<input
											className="input-control"
											type="text"
											placeholder=""
											disabled={ this.state.emptyChecked || !this.isSubscriptionActive() }
											value={ this.state.recipientTokenIdInput }
											onChange={(e) => { this.setState({ recipientTokenIdInput: e.target.value.toLowerCase().replace(/[^0-9]/g, "") }) }}
											onKeyPress={(e) => {
												if ( e.defaultPrevented)             { return; }
												if ( !!this.addRecipientDisabled() ) { return; }
												if ( e.key !== 'Enter' )             { return; }

												this.addRecipientRow(this.state.recipientAddressInput, this.state.recipientTokenIdInput);
											}}
										/>
									</div>
									<div className="col col-12 col-md-3">
										<label className="input-label">&nbsp;</label>
										<button
											className="btn btn-grad"
											disabled={ !!this.addRecipientDisabled() }
											onClick={(e) => {
												this.addRecipientRow(this.state.recipientAddressInput, this.state.recipientTokenIdInput);
											}}
										>{ this.addRecipientDisabled() ? this.addRecipientDisabled() : 'Add' }</button>
									</div>
								</div>

								<div className="c-wrap__upload-row">
									<button
										className="btn-link btn-csv mr-md-3"
										onClick={ open }
									>{ this.t('Click to upload a CSV file') }</button>
									<span className="d-none d-md-inline">
										<span className="mr-3">{ this.t('or') } </span>
										{ this.t('drag and drop it here') }
									</span>
								</div>

							</div>
							<div className="c-wrap__table mt-3">
								{
									this.state.recipients.map((item, idx) => { return this.getRecipientRow(item, idx + 1) })
								}
							</div>
						</div>
					)
				}
			</Dropzone>
		)
	}
	getCollateralRow(collateral: BatchWrapCollateralItem) {

		// erc20 known token
		if ( collateral.token ) {
			return (
				<div className="item" key={ collateral.tokenAddress }>
					<div className="row">
						<div className="col-md-1 mb-2">
							<span className="i-coin">
								<img src={ collateral.token.icon } alt="" />
							</span>
						</div>
						<Tippy
							content={ collateral.tokenAddress }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 512 }
						>
							<div className="col-md-5 mb-2">
								<span className="text-break">{ collateral.token.symbol }</span>
							</div>
						</Tippy>
						<div className="col-md-5 mb-2">
							<span className="col-legend">{ this.t('Amount') }:</span>
							<span className="text-break">{ addThousandSeparator(tokenToFloat(collateral.amount, collateral.token.decimals || 18).toString()) }</span>
						</div>
						<button
							className="btn-del"
							onClick={(e) => {
								this.setState({ collaterals: this.state.collaterals.filter((item) => { return item.tokenAddress.toLowerCase() !== collateral.tokenAddress.toLowerCase() }) })
							}}
						>
							<img src={ icon_i_del } alt="" />
						</button>
					</div>
				</div>
			)
		}

		// native token
		if (
			collateral.tokenAddress === '0x0000000000000000000000000000000000000000' ||
			collateral.tokenAddress === '0' ||
			collateral.tokenAddress === ''
		) {
			return (
				<div className="item" key={ collateral.tokenAddress }>
					<div className="row">
						<div className="col-md-1 mb-2">
							<span className="i-coin">
								<img src={ this.state.iconNative } alt="" />
							</span>
						</div>
						<div className="col-md-5 mb-2">
							<span className="text-break">{ this.state.symbolNative }</span>
						</div>
						<div className="col-md-5 mb-2">
						<span className="col-legend">{ this.t('Amount') }:</span>
							<span className="text-break">{ addThousandSeparator(tokenToFloat(collateral.amount, this.state.decimalsNative).toString()) }</span>
						</div>
						<button
							className="btn-del"
							onClick={(e) => {
								this.setState({
									collaterals: this.state.collaterals.filter((item) => {
										return item.tokenAddress.toLowerCase() !== '' &&
										item.tokenAddress.toLowerCase() !== '0' &&
										item.tokenAddress.toLowerCase() !== '0x0000000000000000000000000000000000000000'
									})
								})
							}}
						>
							<img src={ icon_i_del } alt="" />
						</button>
					</div>
				</div>
			)
		}

		// erc20 not known token
		return (
			<div className="item" key={ collateral.tokenAddress }>
				<div className="row">
					<div className="col-md-1 mb-2">
						<span className="i-coin">
							<img src={ default_icon } alt="" />
						</span>
					</div>
					<Tippy
						content={ collateral.tokenAddress }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 512 }
					>
						<div className="col-md-5 mb-2">
							<span className="text-break">{ compactString(collateral.tokenAddress) }</span>
						</div>
					</Tippy>
					<div className="col-md-5 mb-2">
						<span className="col-legend">{ this.t('Amount') }:</span>
						<Tippy
							content={ this.t('Cannot get decimals from contract, amount shown in wei') }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 512 }
						>
							<span className="text-break text-orange">{ addThousandSeparator( collateral.amount.toString() ) }*</span>
						</Tippy>
					</div>
					<button
							className="btn-del"
							onClick={(e) => {
								this.setState({ collaterals: this.state.collaterals.filter((item) => { return item.tokenAddress.toLowerCase() !== collateral.tokenAddress.toLowerCase() }) })
							}}
						>
						<img src={ icon_i_del } alt="" />
					</button>
				</div>
			</div>
		)
	}
	addCollateralRow(addressInput: string, amountInput: string) {

		let amount = new BigNumber(amountInput);
		let address = addressInput;
		if ( address === '' || address === '0' || address === '0x0000000000000000000000000000000000000000' ) {
			address = '0x0000000000000000000000000000000000000000';
			amount = tokenToInt(amount, this.state.decimalsNative);
		}
		let tokenToAdd = undefined;
		if ( address.toLowerCase() === this.state.techToken.address.toLowerCase() ) {
			tokenToAdd = this.state.techToken;
			amount = tokenToInt(amount, this.state.techToken.decimals || this.state.decimalsNative);
		}
		const foundERC20 = this.state.erc20CollateralTokens.filter((item) => {
			if ( !item.address ) { return false; }
			return item.address.toLowerCase() === address.toLowerCase()
		});
		if ( amount.isNaN() ) { return; }
		if ( foundERC20.length ) {
			tokenToAdd = foundERC20[0];
			amount = tokenToInt(amount, tokenToAdd.decimals || this.state.decimalsNative);
		} else {
			this.metamaskAdapter.addERC20Contracts(address);
		}

		let collateralsUpdated = this.state.collaterals;
		let collateralToAdd: BatchWrapCollateralItem;

		const foundExisting = this.state.collaterals.filter((item) => { return item.tokenAddress.toLowerCase() === address.toLowerCase() });
		if ( foundExisting.length ) {
			collateralsUpdated = this.state.collaterals.filter((item) => { return item.tokenAddress.toLowerCase() !== address.toLowerCase() });
			collateralToAdd = {
				tokenAddress: foundExisting[0].tokenAddress,
				token: foundExisting[0].token,
				amount: foundExisting[0].amount.plus(amount),
			}
		} else {
			collateralToAdd = {
				tokenAddress: address,
				token: tokenToAdd,
				amount: amount,
			}
		}

		collateralsUpdated = [
			...collateralsUpdated,
			collateralToAdd
		]
		this.setState({
			collaterals: collateralsUpdated,
			collateralAmountInput: '',
			collateralTokenAddressInput: '',
		})
	}
	getSelectedToken = () => {
		if (
			this.state.collateralTokenAddressInput &&
			this.state.collateralTokenAddressInput !== '0' &&
			this.state.collateralTokenAddressInput !== '0x0000000000000000000000000000000000000000'
		) {

			let erc20ToRender = {
				icon: default_icon,
				symbol: '',
			};

			if ( this.state.collateralTokenAddressInput.toLowerCase() === this.state.techToken.address.toLowerCase() ) {
				erc20ToRender = {
					icon: this.state.techToken.icon,
					symbol: this.state.techToken.symbol,
				}
			}

			const foundERC20 = this.state.erc20CollateralTokens.filter((item) => {
				if ( !item.address ) { return false; }
				return item.address.toLowerCase() === this.state.collateralTokenAddressInput.toLowerCase()
			});
			if ( foundERC20.length ) {
				erc20ToRender = {
					icon: foundERC20[0].icon,
					symbol: foundERC20[0].symbol,
				}
			}

			return (
				<span className="field-unit">
					<span className="i-coin">
						<img src={ erc20ToRender.icon } alt="" />
					</span>
					{ erc20ToRender.symbol }
				</span>
			)
		} else {
			return (
				<span className="field-unit">
					<span className="i-coin">
						<img src={ this.state.iconNative } alt="" />
					</span>
					{ this.state.symbolNative }
				</span>
			)
		}
	}
	getTokenSelector() {
		return (
			<div
				className="select-coin"
				ref={ (node: HTMLInputElement | null) => {
					if ( !node ) { return; }
					if ( node.clientWidth === this.state.tokenSelectorWidth ) { return; }
					this.setState({ tokenSelectorWidth: node.clientWidth });
				}}
			>
				<div className="select-coin__value">
					{ this.getSelectedToken() }
					<svg className="arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 0.529297L5 4.76459L9 0.529297" stroke="white"></path>
					</svg>
				</div>
				<ul className="select-coin__list">
					<li
						onClick={() => { this.setState({ collateralTokenAddressInput: '' }); }}
					><span className="field-unit"><span className="i-coin"><img src={ this.state.iconNative || default_icon } alt="" /></span>{ this.state.symbolNative }</span></li>
					{
						this.state.techToken.address ?
						( <li
							onClick={() => { this.setState({ collateralTokenAddressInput: this.state.techToken.address }); }}
						><span className="field-unit"><span className="i-coin"><img src={ this.state.techToken.icon } alt="" /></span>{ this.state.techToken.symbol }</span></li> ) : null
					}
					{
						this.state.erc20CollateralTokens
						.filter((item) => { return !!item.address })
						.map((item) => { return (
							<li
								key={ item.address }
								onClick={() => { this.setState({ collateralTokenAddressInput: item.address }); }}
							><span className="field-unit"><span className="i-coin"><img src={ item.icon } alt="" /></span>{ item.symbol }</span></li>
						) })
					}
				</ul>
			</div>
		)
	}
	addCollateralDisabled() {
		if ( !this.isSubscriptionActive() ) { return this.t('Add') }
		if (
			!!this.state.collateralTokenAddressInput &&
			this.state.collateralTokenAddressInput !== '0' &&
			(
				!this.metamaskAdapter.web3 ||
				!this.metamaskAdapter.web3.utils.isAddress(this.state.collateralTokenAddressInput)
			)
		) { return this.t('Wrong address') }

		const amount = new BigNumber(this.state.collateralAmountInput);
		if (
			!this.state.collateralAmountInput ||
			amount.eq(0) ||
			amount.isNaN()
		) { return this.t('Empty amount') }

		return ''
	}
	getCollateralAmountTitle() {
		const foundERC20 = this.state.erc20CollateralTokens.filter((item) => {
			if ( !item.address ) { return false; }
			return item.address.toLowerCase() === this.state.collateralTokenAddressInput.toLowerCase()
		});
		if (
			this.state.collateralTokenAddressInput &&
			this.state.collateralTokenAddressInput !== '' &&
			this.state.collateralTokenAddressInput !== '0' &&
			this.state.collateralTokenAddressInput !== '0x0000000000000000000000000000000000000000' &&
			this.state.collateralTokenAddressInput.toLowerCase() !== this.state.techToken.address.toLowerCase() &&
			!foundERC20.length
		) {
			return (
				<Tippy
					content={ this.t('Cannot get decimals from contract, enter amount in wei') }
					appendTo={ document.getElementsByClassName("wrapper")[0] }
					trigger='mouseenter'
					interactive={ false }
					arrow={ false }
					maxWidth={ 512 }
				>
					<label className="input-label text-orange">{ this.t('Amount') }*</label>
				</Tippy>
			)
		} else {
			return ( <label className="input-label">{ this.t('Amount') }</label> )
		}
	}
	getCollateralFootnote() {
		const collateralFound = this.state.collaterals.filter((item) => {
			return !item.token &&
			(
				item.tokenAddress !== '0' &&
				item.tokenAddress !== '0x0000000000000000000000000000000000000000' &&
				item.tokenAddress !== ''
			)
		});

		const foundERC20 = this.state.erc20CollateralTokens.filter((item) => {
			if ( !item.address ) { return false; }
			return item.address.toLowerCase() === this.state.collateralTokenAddressInput.toLowerCase()
		});

		if (
			collateralFound.length ||
			(
				this.state.collateralTokenAddressInput &&
				this.state.collateralTokenAddressInput !== '' &&
				this.state.collateralTokenAddressInput !== '0' &&
				this.state.collateralTokenAddressInput !== '0x0000000000000000000000000000000000000000' &&
				this.state.collateralTokenAddressInput.toLowerCase() !== this.state.techToken.address.toLowerCase() &&
				!foundERC20.length
			)
		) {
			return (
				<div className="text-orange mt-4"><small>* { this.t('Cannot get decimals from contract, amount shown in wei') }</small></div>
			)
		} else {
			return null
		}
	}
	collateralOnDrop(files: Array<File>) {
		if ( !this.isSubscriptionActive() ) { return; }

		files.forEach((item) => {
			const fileReader = new FileReader();
			fileReader.addEventListener('load', (e) => {
				let text = e.target?.result;
				if ( typeof(text) !== 'string' ) {
					return;
				}
				if ( !text ) { return; }
				const rows = text.split(/\r?\n/);

				rows.forEach((iitem) => {
					try {
						const address = iitem.split(';')[0];
						const amount = iitem.split(';')[1].replaceAll(',', '.');

						this.addCollateralRow( address, amount )
					} catch(e) { console.log('Cannot add row from file', iitem) }
				});

			});
			fileReader.readAsText(item)
		})
	}
	getCollateralBlock() {
		return (
			<Dropzone
				onDrop={(files) => { this.collateralOnDrop(files) }}
				accept={ '.txt, .csv' }
				noClick={ true }
				noKeyboard={ true }
			>
				{
					({
						getRootProps, getInputProps, isDragActive, open
					}) => (
						<div {...getRootProps({ className: `c-wrap c-b-wrap ${isDragActive ? 'file-dragged' : ''}` })}>

							<div className="upload-poopover">
								<div className="inner">
									<div className="h3">{ this.t('Drop your file here') }</div>
								</div>
							</div>

							<input {...getInputProps()} />
							<div className="c-wrap__header">
								<div className="h3">{ this.t('Collateral for each NFT') }</div>
								<div className="c-wrap__info">
									<div>{ this.t('Enter') } <span className="text-grad">{ this.t('address = 0') }</span> { this.t('to add') } { this.state.symbolNative }</div>
									<img src={ icon_i_attention } alt="" />
								</div>
							</div>
							<div className="c-wrap__form">
								<div className="row">
									<div className="col col-12 col-md-5">
										<label className="input-label">{ this.state.EIPPrefix }-20 { this.t('Address') }</label>
										<div className="input-group">
											<input
												className="input-control"
												type="text"
												placeholder="0x000"
												value={ this.state.collateralTokenAddressInput }
												disabled={ !this.isSubscriptionActive() }
												style={{ paddingRight: this.state.tokenSelectorWidth + 15 }}
												onChange={(e) => {
													const value = e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, "");
													if (
														!this.state.erc20CollateralTokens.find((item) => { return item.address.toLowerCase() === value })
													) {
														this.metamaskAdapter.addERC20Contracts(value)
													}
													this.setState({ collateralTokenAddressInput: value });
												}}
												onKeyPress={(e) => {
													if ( e.defaultPrevented)              { return; }
													if ( !!this.addCollateralDisabled() ) { return; }
													if ( e.key !== 'Enter' )              { return; }

													this.addCollateralRow( this.state.collateralTokenAddressInput, this.state.collateralAmountInput )
												}}
											/>

											{ this.getTokenSelector() }

										</div>
									</div>
									<div className="col col-12 col-md-4">
										{ this.getCollateralAmountTitle() }
										{/* <label className="input-label">Amount</label> */}
										<input
											className="input-control"
											type="text"
											placeholder=""
											value={ addThousandSeparator(this.state.collateralAmountInput) }
											disabled={ !this.isSubscriptionActive() }
											onChange={(e) => {
												let value = removeThousandSeparator(e.target.value);
												if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
													if ( new BigNumber(value).isNaN() ) { return; }
													value = new BigNumber(value).toString();
												}
												this.setState({ collateralAmountInput: value, })
											}}
											onKeyPress={(e) => {
												if ( e.defaultPrevented)              { return; }
												if ( !!this.addCollateralDisabled() ) { return; }
												if ( e.key !== 'Enter' )              { return; }

												this.addCollateralRow( this.state.collateralTokenAddressInput, this.state.collateralAmountInput )
											}}
										/>
									</div>
									<div className="col col-12 col-md-3">
										<label className="input-label">&nbsp;</label>
										<button
											className="btn btn-grad"
											disabled={ !!this.addCollateralDisabled() }
											onClick={ (e) => {
												this.addCollateralRow( this.state.collateralTokenAddressInput, this.state.collateralAmountInput )
											} }
										>{ this.addCollateralDisabled() ? this.addCollateralDisabled() : 'Add' }</button>
									</div>
								</div>

								<div className="c-wrap__upload-row">
									<button
										className="btn-link btn-csv mr-md-3"
										onClick={ open }
									>{ this.t('Click to upload a CSV file') }</button>
									<span className="d-none d-md-inline">
										<span className="mr-3">{ this.t('or') } </span>
										{ this.t('drag and drop it here') }
									</span>
								</div>

							</div>
							<div className="c-wrap__table mt-3">
								{ this.state.collaterals.map((item) => { return this.getCollateralRow(item) }) }
							</div>
							{ this.getCollateralFootnote() }
						</div>
					)
				}
			</Dropzone>
		)
	}
	submitBtnDisabled() {
		if ( !this.isSubscriptionActive() ) { return this.t('Wrap') }

		if (
			!this.state.emptyChecked &&
			( !this.metamaskAdapter.web3 || !this.metamaskAdapter.web3.utils.isAddress(this.state.nftAddressInput) )
		) {
			return this.t('Wrong address')
		}
		if ( !this.state.recipients.length ) {
			return this.t('Empty recipients')
		}
		// if ( !this.state.collaterals.length ) {
		// 	return this.t('Empty collaterals')
		// }

		const foundEmptyRecTokenId = this.state.recipients.filter((item) => { return !item.tokenId });
		if ( !this.state.emptyChecked && foundEmptyRecTokenId.length ) {
			return this.t('Empty recipients\' token ids')
		}

		return ''
	}
	getClearBtn() {
		return (
			<div className="col-12 col-sm-3 mt-3 order-2 order-sm-1">
				<button
					className="btn btn-yellow w-100"
					disabled={ !this.isSubscriptionActive() }
					onClick={() => {
						this.setState({
							emptyChecked: false,
							mintChecked: false,
							collectionName: '',
							badContract: false,
							nftAddressInput: '',
							unlockTimeInput: '',
							recipients: [],
							recipientAddressInput: '',
							recipientTokenIdInput: '',
							collaterals: [],
							collateralTokenAddressInput: '',
							collateralAmountInput: '',
							tokenSelectorWidth: 75,
						});
					}}
				>{ this.t('Clear form') }</button>
			</div>
		)
	}
	getSubmitBtn() {
		if ( this.submitBtnDisabled() ) {
			return (
				<div className="row mb-5 mb-lg-7 justify-content-sm-between">
					{ this.getClearBtn() }
					<div className="col-12 col-sm-6 mt-3 order-1 order-sm-2">
						<button
							className="btn btn-grad w-100"
							disabled={ true }
						>{ this.submitBtnDisabled() }</button>
					</div>
				</div>
			)
		}

		if ( this.state.badContract ) {
			return (
				<div className="row mb-5 mb-lg-7 justify-content-sm-between">
					{ this.getClearBtn() }
					<div className="col-12 col-sm-6 mt-3 order-1 order-sm-2">
						<button
							className="btn btn-grad w-100 btn-yellow"
							disabled={ false }
							onClick={() => {

								let unwrapAfter = new BigNumber(0);
								if ( this.state.unlockTimeInput ) {
									const days = parseInt(this.state.unlockTimeInput);
									const newDate = this.addDays(days).getTime();
									unwrapAfter = new BigNumber( parseInt(`${newDate / 1000}`) );

									if ( unwrapAfter.isNaN() ) { unwrapAfter = new BigNumber(0); }
								}

								this.metamaskAdapter.wrapperContract.batchWrapToken({
									originalAddress: this.state.emptyChecked ? undefined : this.state.nftAddressInput,
									recipients: this.state.recipients,
									collaterals: this.state.collaterals,
									unwrapAfter,
								})
							}}
						>{ this.t('Transaction could be reverted') }</button>
					</div>
				</div>
			)
		}

		return (
			<div className="row mb-5 mb-lg-7 justify-content-sm-between">
				{ this.getClearBtn() }
				<div className="col-12 col-sm-6 mt-3 order-1 order-sm-2">
					<button
						className="btn btn-grad w-100"
						disabled={ false }
						onClick={() => {

							let unwrapAfter = new BigNumber(0);
							if ( this.state.unlockTimeInput ) {
								const days = parseInt(this.state.unlockTimeInput);
								const newDate = this.addDays(days).getTime();
								unwrapAfter = new BigNumber( parseInt(`${newDate / 1000}`) );

								if ( unwrapAfter.isNaN() ) { unwrapAfter = new BigNumber(0); }
							}

							this.metamaskAdapter.wrapperContract.batchWrapToken({
								originalAddress: this.state.emptyChecked ? undefined : this.state.nftAddressInput,
								recipients: this.state.recipients,
								collaterals: this.state.collaterals,
								unwrapAfter,
							})
						}}
					>{ this.t('Wrap') }</button>
				</div>
			</div>
		)
	}
	getHistoryBlock() {
		return (
			<div className="c-wrap c-b-wrap">
				<div className="h4 mb-4"> <b>{ this.t('Transactions History') }</b></div>
				<div className="c-wrap__table">
					<div className="item item-header">
						<div className="row">
							<div className="col-2 col-md-1 mb-2"> </div>
							<div className="col-10 col-md-3 mb-2">{ this.t('Date') }</div>
							<div className="col-md-4 col-lg-3 mb-2">{ this.t('Tx Hash') }</div>
							<div className="col-6 col-md-2 mb-2 text-right">{ this.t('Batch size') }</div>
							<div className="col-6 col-md-2 col-lg-3 mb-2 text-right">{ this.t('Status') }</div>
						</div>
					</div>
					<div className="item">
						<div className="row">
							<div className="col-2 col-md-1 mb-2">#1</div>
							<div className="col-10 col-md-3 mb-2">2022-01-15 20:10:03 UTC</div>
							<div className="col-md-4 col-lg-3 mb-2"><a href="https://etherscan.io/">0x8679a598.......213ac1185</a></div>
							<div className="col-8 col-md-2 mb-2">
								<div className="batch-size">
									<span className="col-legend">{ this.t('Batch size') }:</span>
									8000
								</div>
							</div>
							<div className="col-4 col-md-2 col-lg-3 mb-2 text-right">
								<b className="text-orange">{ this.t('Pending') }</b>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="row">
							<div className="col-2 col-md-1 mb-2">#2</div>
							<div className="col-10 col-md-3 mb-2">2022-01-15 20:10:03 UTC</div>
							<div className="col-md-4 col-lg-3 mb-2"><a href="https://etherscan.io/">0x8679a598.......213ac1185</a></div>
							<div className="col-8 col-md-2 mb-2">
								<div className="batch-size">
									<span className="col-legend">{ this.t('Batch size') }:</span>
									8970
								</div>
							</div>
							<div className="col-4 col-md-2 col-lg-3 mb-2 text-right">
								<b className="text-red">{ this.t('Revert') }</b>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="row">
							<div className="col-2 col-md-1 mb-2">#3</div>
							<div className="col-10 col-md-3 mb-2">2022-01-15 20:10:03 UTC</div>
							<div className="col-md-4 col-lg-3 mb-2"><a href="https://etherscan.io/">0x8679a598.......213ac1185</a></div>
							<div className="col-8 col-md-2 mb-2">
								<div className="batch-size">
									<span className="col-legend">{ this.t('Batch size') }:</span>
									1111
								</div>
							</div>
							<div className="col-4 col-md-2 col-lg-3 mb-2 text-right">
								<b className="text-green">{ this.t('OK') }</b>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
	getInProgressPopup() {
		return (
			<div className="modal">
			<div className="modal__inner">
				<div className="modal__bg"></div>
				<div className="container">
					<div className="modal__content">
						<div className="modal__close">
							<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z" fill="white"></path>
								<path fillRule="evenodd" clipRule="evenodd" d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z" fill="white"></path>
							</svg>
						</div>
						<div className="modal__header">
							<div className="h2">Wrapping in Progress</div>
						</div>
						<div className="c-approve">
							<div className="c-approve__step in-queue">
								<div className="row">
									<div className="col-12 col-sm-auto order-2 order-sm-1">
										<span className="current">0</span> / 10
										<span className="ml-2">
											Approving DAI
											<span className="dots">...</span>
										</span>
									</div>
									<div className="col-12 col-sm-auto order-1 order-sm-1"></div>
								</div>
							</div>
							<div className="c-approve__step active">
								<div className="row">
									<div className="col-12 col-sm-auto order-2 order-sm-1">
										<span className="current">2</span> / 10
										<span className="ml-2">
											Approving DAI
											<span className="dots">...</span>
										</span>
									</div>
									<div className="col-12 col-sm-auto order-1 order-sm-1">
										<div className="status">
											<img className="loader" src="../pics/loader-orange.svg" alt="" />
										</div>
									</div>
								</div>
							</div>
							<div className="c-approve__step completed">
								<div className="row">
									<div className="col-12 col-sm-auto order-2 order-sm-1">
										<span className="current">10</span> / 10 Approved
									</div>
									<div className="col-12 col-sm-auto order-1 order-sm-1">
										<div className="status">
											<b className="text-green">Completed</b>
										</div>
									</div>
								</div>
							</div>
							<div className="c-approve__step in-queue">
								<div className="row">
									<div className="col-12 col-sm-auto order-2 order-sm-1">
										Wrapping
										<span className="dots">...</span>
									</div>
									<div className="col-12 col-sm-auto order-1 order-sm-1"></div>
								</div>
							</div>
							<div className="c-approve__step active">
								<div className="row">
									<div className="col-12 col-sm-auto order-2 order-sm-1">
										Wrapping
										<span className="dots">...</span>
									</div>
									<div className="col-12 col-sm-auto order-1 order-sm-1">
										<div className="status">
											<img className="loader" src="../pics/loader-orange.svg" alt="" />
										</div>
									</div>
								</div>
							</div>
							<div className="c-approve__step completed">
								<div className="row">
									<div className="col-12 col-sm-auto order-2 order-sm-1">Wrapping</div>
									<div className="col-12 col-sm-auto order-1 order-sm-1">
										<div className="status">
											<b className="text-green">Completed</b>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</div>
		)
	}


	isSubscriptionActive() {
		if ( !this.state.subscription ) { return true; }

		if ( !this.state.subscription.endTime ) { return false; }

		const now = new BigNumber(new Date().getTime());
		if ( this.state.subscription.endTime.lt(now) ) { return false; }

		return true;
	}
	convertTimeToStr(diff: BigNumber) {

		const monthsRemaining = diff.dividedToIntegerBy(3600).dividedToIntegerBy(24).dividedToIntegerBy(30);
		if ( monthsRemaining.gt(0) ) {
			if ( monthsRemaining.eq( 1 ) ) { return `${monthsRemaining.toFixed(0)} ${this.t('month')}` }
			return `${monthsRemaining.toFixed(0)} ${this.t('months')}`
		}

		const daysRemaining = diff.dividedToIntegerBy(3600).dividedToIntegerBy(24);
		if ( daysRemaining.gt(0) ) {
			if ( daysRemaining.eq( 1 ) ) { return `${daysRemaining.toFixed(0)} ${this.t('day')}` }
			return `${daysRemaining.toFixed(0)} ${this.t('days')}`
		}

		const hoursRemaining = diff.dividedToIntegerBy(3600);
		if ( hoursRemaining.gt(0) ) {
			if ( hoursRemaining.eq( 1 ) ) { return `${hoursRemaining.toFixed(0)} ${this.t('hour')}` }
			return `${hoursRemaining.toFixed(0)} ${this.t('hours')}`
		}

		const minutesRemaining = diff.dividedToIntegerBy(60);
		if ( minutesRemaining.gt(0) ) {
			if ( minutesRemaining.eq( 1 ) ) { return `${minutesRemaining.toFixed(0)} ${this.t('minute')}` }
			return `${minutesRemaining.toFixed(0)} ${this.t('minutes')}`
		}

		return ''

	}
	getSubscriptionBlock() {
		if ( !this.state.subscription ) { return; }

		const now = new Date().getTime();
		const end = new Date(this.state.subscription.endTime.toNumber()).getTime();
		const diff = new BigNumber(end - now).dividedToIntegerBy(1000);
		const timeStr = this.convertTimeToStr(diff);
		const ticketValidPeriod = this.convertTimeToStr(this.state.subscription.ticketParams.ticketValidPeriod);

		if ( this.isSubscriptionActive() ) {
			return (
				<div
					className="bw-subscib"
					style={{ marginBottom: '30px' }}
				>{ this.t('Your subscription expires') }
					{ ' ' }
					<span
						className="days"
						style={{
							fontSize: '24px',
							lineHeight: '1.2em',
							margin: '0 0 1.2em',
							color: '#4afebf',
							fontWeight: 'bold',
						}}
					>
						{  timeStr === '' ? 'soon' : ` in ${timeStr}` }
					</span>
				</div>
			)
		}

		return (
			<div
				className="bw-subscib"
				style={{ marginBottom: '30px' }}
			>
				<div className="d-inline-block mr-2 my-3">{ this.t('To use the SAFT you need to') }</div>
				<button
					className="btn btn-outline"
					style={{
						display: 'inline-flex',
						background: 'transparent',
						border: '1px solid #4afebf',
						color: '#4afebf',
					}}
					onClick={() => {
						this.getSubscriptionContractAllowance();
						this.setState({ subscriptionPopup: true });
					}}
				>{ this.t('Subscribe for') } { ticketValidPeriod }</button>
			</div>
		)
	}
	getSubsciptionToken() {
		const foundToken = this.state.erc20CollateralTokens.filter((item) => {
			if ( !item.address ) { return false; }
			if ( !this.state.subscription ) { return false; }
			return item.address.toLowerCase() === this.state.subscription.ticketParams.paymentToken.toLowerCase();
		});

		if ( foundToken.length ) {
			return foundToken[0];
		}
		return undefined;
	}
	checkoutERC20Approve = () => {

		if ( !this.state.subscription ) { return; }

		this.setState({ checkoutApproving: true });
		this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));

		const erc20Contract = this.metamaskAdapter.getERC20Contract(this.state.subscription.ticketParams.paymentToken);
		erc20Contract?.makeAllowanceTransfer( this.state.subscription.ticketParams.paymentAmount, this.state.subscription.contractAddress )
			.then(() => {
				erc20Contract.getBalance();
				this.getSubscriptionContractAllowance();
				this.setState({ checkoutApproving: false });
				this.buyTicket();
			})
			.catch((e: any) => {
				console.log(e);
				this.setState({ checkoutApproving: false });
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `Cannot make allowance: ${e.message.split('\n')[0]}`,
					buttons: undefined,
					links: undefined
				}));
		});
	}
	buyTicket = () => {

		if ( !this.state.subscription ) { return; }

		const ticketValidPeriod = this.convertTimeToStr(this.state.subscription.ticketParams.ticketValidPeriod);

		this.setState({ checkoutProcessing: true });
		this.store.dispatch(setLoading({ msg: this.t('Waiting for buy') }));

		const tx = this.metamaskAdapter.batchWrapSubscriptionContract.methods.buyTicket(0);
		try {
			tx.estimateGas({ from: this.state.userAddress });
		} catch(e: any) {
			console.log('before send', e);
			this.setState({ checkoutProcessing: false });
			this.store.dispatch(unsetLoading());
			this.store.dispatch(setError({
				text: `Cannot buy ticket: ${e.message.split('\n')[0]}`,
				buttons: undefined,
				links: undefined
			}));
		}
		tx.send({ from: this.state.userAddress })
			.then((data: any) => {

				if ( this.state.subscription ) {
					const erc20Contract = this.metamaskAdapter.getERC20Contract(this.state.subscription.contractAddress);
					if ( erc20Contract ) {
						erc20Contract.getBalance();
					}
				}

				this.metamaskAdapter.updateBatchWrapSubscription();
				this.setState({ checkoutProcessing: false, subscriptionPopup: false });
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setInfo({
					text: `${this.t('Subcription is successfully activated for')} ${ ticketValidPeriod } ${this.t('month')}`,
					 buttons: [{
						text: 'Ok',
						clickFunc: () => { this.store.dispatch(clearInfo()) }
					 }],
					links: [{
						text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
						url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
					}]
				}));
			})
			.catch((e: any) => {
				console.log(e);
				this.setState({ checkoutProcessing: false });
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `Cannot buy ticket: ${e.message.split('\n')[0]}`,
					buttons: undefined,
					links: undefined
				}));
		});
	}
	getSubscriptionPopupSubmitBtn() {

		if ( !this.state.subscription ) { return null; }

		if ( this.state.checkoutApproving ) {
			return (
				<button className="btn" disabled={ true }>
					{ this.t('Waiting for approve') }
				</button>
			)
		}
		if ( this.state.checkoutProcessing ) {
			return (
				<button className="btn" disabled={ true }>
					{ this.t('Waiting for ticket buy') }
				</button>
			)
		}

		const token = this.getSubsciptionToken();
		if ( !token ) { return null; }
		if ( this.state.subscription.ticketParams.paymentAmount.gt(new BigNumber(token.balance)) ) {
			return ( <button className="btn" disabled={ true }>{ this.t('Not enough') }</button> )
		}
		if ( this.state.subscription.ticketParams.paymentAmount.gt(new BigNumber(this.state.subscriptionContractAllowance)) ) {
			return ( <button
				className="btn"
				onClick={() => { this.checkoutERC20Approve() }}
			>{ this.t('Approve and checkout') }</button> )
		}
		return (
			<button
				className="btn"
				onClick={() => { this.buyTicket() }}
			>{ this.t('Checkout') }</button>
		)

	}
	async getSubscriptionContractAllowance() {
		let allowance = new BigNumber(0);
		const token = this.getSubsciptionToken();
		if ( token && this.metamaskAdapter && this.state.subscription ) {
			const erc20contract = this.metamaskAdapter.getERC20Contract(token.address);
			if ( erc20contract ) {
				allowance = await erc20contract.getAllowanceToAddress( this.state.subscription.contractAddress );
				this.setState({ subscriptionContractAllowance: allowance })
			}
		}
		return allowance;
	}
	getSubscriptionPopup() {

		const subsciptionToken = this.getSubsciptionToken();

		if ( this.state.subscription && this.state.subscriptionPopup && subsciptionToken ) {

			const timelockPeriod = this.convertTimeToStr(this.state.subscription.ticketParams.timelockPeriod);
			const ticketValidPeriod = this.convertTimeToStr(this.state.subscription.ticketParams.ticketValidPeriod);

			return (
				<div className="modal">
					<div className="modal__inner">
						<div className="modal__bg"></div>
						<div className="container">
							<div className="modal__content">
								<div
									className="modal__close"
									onClick={() => { this.setState({ subscriptionPopup: false }) }}
								>
									<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fillRule="evenodd" clipRule="evenodd" d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z" fill="white"></path>
										<path fillRule="evenodd" clipRule="evenodd" d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z" fill="white"></path>
									</svg>
								</div>
								<div className="c-add">
									<div className="c-add__text">
										<div className="h2">{ this.t('SAFT Subscription') }</div>
										<div className="checkout-info">
											<div>
												{ this.t('Wrap') } { ' ' }
												{ tokenToFloat(this.state.subscription.ticketParams.paymentAmount, this.getSubsciptionToken()?.decimals || 18).toString() } { ' ' }
												{ this.getSubsciptionToken()?.symbol } { ' ' }
												{ this.t('with time lock for') } { ' ' }
												{ timelockPeriod } { ' ' }
												{ this.t('to get SAFT access for') } { ' ' }
												{ ticketValidPeriod } { ' ' }.
											</div>
										</div>
									</div>
									<div className="c-add__coins">
										<div className="c-add__max">
											<div> <span>Max: </span><span className="sum">{ tokenToFloat( subsciptionToken.balance, subsciptionToken.decimals || 18 ).toString() }</span></div>
											<div> <span>Allowance: </span><span className="sum">{ tokenToFloat( this.state.subscriptionContractAllowance, subsciptionToken.decimals || 18 ).toString() }</span></div>
										</div>
										<div className="c-add__form">
												<div className="form-row">
													<div className="col col-12 col-sm-7">
														<div className="input-group">
															<input
																className="input-control"
																type="text"
																value={ tokenToFloat(this.state.subscription.ticketParams.paymentAmount, subsciptionToken.decimals || 18 ).toString() }
																disabled={ true }
															/>
															<div className="select-coin">
																<div className="select-coin__value">
																	<span className="field-unit">
																		<span className="i-coin"><img src={ subsciptionToken.icon || default_icon } alt="" /></span>
																		{ subsciptionToken.symbol || this.state.subscription.contractAddress }
																	</span>
																</div>
															</div>
														</div>
													</div>
													<div className="col col-12 col-sm-5">
														{ this.getSubscriptionPopupSubmitBtn() }
													</div>
												</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
	}

	render() {
		return (
			<React.Fragment>
			<main className="s-main">
				<div className="container">
					{ this.getSubscriptionBlock() }
					{ this.getMintBlock() }
					{ this.getRecipientsBlock() }
					{ this.getCollateralBlock() }
					{ this.getSubmitBtn() }
					{/* { this.getHistoryBlock() } */}

				</div>
			</main>
			{
				this.state.inProgressPopup ?
					this.getInProgressPopup() : null
			}
			{
				this.getSubscriptionPopup()
			}
			</React.Fragment>
		)
	}
}

export default withTranslation("translations")(withRouter(BatchWrapPage));