
import React               from 'react';
import Tippy               from '@tippyjs/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
	withRouter,
	match,
	Link
} from 'react-router-dom';
import {
	ERC20ContractParamsType,
	MetamaskAdapter,
	removeERC721Token,
	WrappedTokensStatType,
	WrappedTokenType
} from '../../models/BlockchainAdapter';
import {
	incompleteTokensRemove,
	setError,
	unsetLoading,
	clearError,
	clearSuccess,
	wrappedTokensClear,
	setLoading,
} from '../../reducers';

import AddValuePopup from '../AddValuePopup';
import TransferPopup from '../TransferPopup';
import ApprovePopup  from '../ApprovePopup';

import icon_logo        from '../../static/pics/logo.svg';
import icon_i_copy      from '../../static/pics/i-copy.svg';
import icon_i_link      from '../../static/pics/i-link.svg';
import icon_i_attention from '../../static/pics/i-attention.svg';
import icon_i_action    from '../../static/pics/i-action.svg';
import icon_i_plus      from '../../static/pics/i-plus.svg';
import default_icon     from '../../static/pics/coins/_default.png';
import icon_loading     from '../../static/pics/loading.svg';

import {
	addThousandSeparator,
	compactString,
	tokenToFloat,
	unixtimeToStr,
} from '../../models/_utils';
import {
	History,
	Location
} from 'history';
import { withTranslation, Trans } from "react-i18next";

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type NFTListProps = {
	store                 : any,
	metamaskAdapter       : MetamaskAdapter,
	showAuthMethodSelector: Function,
	t                     : any,
	match                 : match;
	location              : Location,
	history               : History,
}
type NFTListState = {
	input_nft_address  : string,
	input_token_id     : string,
	mintChecked        : boolean,
	tokenFetchRequested: boolean,
	tokenMintRequested : boolean,

	decimalsNative   : number,
	symbolNative     : string,
	iconNative       : string,
	minterContract   : string,

	techToken        : ERC20ContractParamsType,

	wrappedTokens    : Array<WrappedTokenType>,
	discoveredTokens : Array<WrappedTokenType>,
	waitingTokens    : Array<{ token: WrappedTokenType, msg: string }>,
	incompleteTokens : Array<WrappedTokenType>,
	wrappedTokensStat: WrappedTokensStatType,
	chainId          : number,
	metamaskLogged   : boolean,
	explorerBaseUrl  : string,
	marketplaceUrl   : string,

	wrappedPages         : number,
	discoveredPages      : number,
	wrappedCurrentPage   : number,
	discoveredCurrentPage: number,

	erc20CollateralTokens: Array<ERC20ContractParamsType>,

	copiedHint: undefined | { id: string, icon: string },

	addValueToken: WrappedTokenType | undefined,
	transferToken: WrappedTokenType | undefined,
	approveToken : WrappedTokenType | undefined,

	loadingInProgress: boolean,
}

class NFTList extends React.Component<NFTListProps, NFTListState> {

	store                 : any;
	unsubscribe!          : Function;
	metamaskAdapter       : MetamaskAdapter;
	showAuthMethodSelector: Function;
	t                     : any;
	copiedHintTimer       : number;
	copiedHintTimeout     : number;
	scrollToBlock         : React.RefObject<HTMLInputElement>;
	tokensOnPage          : number;
	mintSubscription      : any | undefined;

	constructor(props: NFTListProps) {
		super(props);

		this.store                  = props.store;
		this.metamaskAdapter        = props.metamaskAdapter;
		this.showAuthMethodSelector = props.showAuthMethodSelector;
		this.t                      = props.t;
		this.copiedHintTimer        = 0;
		this.tokensOnPage           = 12;
		this.scrollToBlock          = React.createRef();

		this.copiedHintTimeout      = 2; // s

		this.mintSubscription       = undefined;

		if ( !this.store.getState().metamaskAdapter.logged ) {
			this.store.dispatch(clearSuccess());
			this.store.dispatch(wrappedTokensClear());
			this.metamaskAdapter.wrapperContract?.getNFTTokens();
		}

		const waitingTokens: Array<{ token: WrappedTokenType, msg: string }> = this.store.getState().waitingTokens;
		const ignoredTokens: Array<{ contractAddress: string, tokenId: string }> = this.store.getState().ignoredTokens;
		const incompleteTokens: Array<WrappedTokenType> = this.store.getState().incompleteTokens
			.filter((item: WrappedTokenType) => {
				// const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => { return iitem.token.tokenUrl === item.tokenUrl });
				const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => {
					return iitem.token.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && iitem.token.tokenId === item.tokenId
				});
				return !foundWaiting.length;
			});
		const wrappedTokens: Array<WrappedTokenType> = this.store.getState().wrappedTokens
			.filter((item: WrappedTokenType) => {
				// const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => { return iitem.token.tokenUrl === item.tokenUrl });
				const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => {
					return iitem.token.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && iitem.token.tokenId === item.tokenId
				});
				return !foundWaiting.length;
			});
		const discoveredTokens: Array<WrappedTokenType> = this.store.getState().discoveredTokens
			.filter((item: WrappedTokenType) => {
				const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => {
					return iitem.token.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.token.tokenId}` === `${item.tokenId}`
				});
				return !foundWaiting.length;
			})
			.filter((item: WrappedTokenType) => {
				const foundWrapped = wrappedTokens.filter((iitem: WrappedTokenType) => {
					return ( iitem.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.tokenId}` === `${item.tokenId}` ) ||
					( iitem.originalContractAddress && iitem.originalTokenId && iitem.originalContractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.originalTokenId}` === `${item.tokenId}` )
				});
				return !foundWrapped.length;
			})
			.filter((item: WrappedTokenType) => {
				const foundIncomplete = incompleteTokens.filter((iitem: WrappedTokenType) => {
					return iitem.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.tokenId}` === `${item.tokenId}`
				});
				return !foundIncomplete.length;
			})
			.filter((item: WrappedTokenType) => {
				const foundIgnored = ignoredTokens.filter((iitem: { contractAddress: string, tokenId: string }) => {
					return iitem.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.tokenId}` === `${item.tokenId}`
				});
				return !foundIgnored.length;
			});

		this.state = {
			input_nft_address  : '',
			input_token_id     : '',
			mintChecked        : false,
			tokenFetchRequested: false,
			tokenMintRequested : false,

			decimalsNative   : this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative     : this.store.getState().metamaskAdapter.networkTokenTicket,
			iconNative       : this.store.getState().metamaskAdapter.networkTokenIcon,
			minterContract   : this.store.getState().metamaskAdapter.minterContract,

			techToken        : this.store.getState().erc20TechTokenParams,

			wrappedTokens    : wrappedTokens,
			waitingTokens    : waitingTokens,
			incompleteTokens : incompleteTokens,
			discoveredTokens : discoveredTokens,
			wrappedTokensStat: this.store.getState().wrappedTokensStat,
			chainId          : this.store.getState().metamaskAdapter.chainId,
			metamaskLogged   : this.store.getState().metamaskAdapter.logged,
			explorerBaseUrl  : this.store.getState().metamaskAdapter.explorerBaseUrl,
			marketplaceUrl   : this.store.getState().metamaskAdapter.marketplaceUrl,

			erc20CollateralTokens: this.store.getState().erc20CollateralTokens,

			copiedHint: undefined,

			addValueToken: undefined,
			transferToken: undefined,
			approveToken : undefined,

			wrappedPages         : Math.ceil( wrappedTokens.length / this.tokensOnPage ),
			discoveredPages      : Math.ceil( discoveredTokens.length / this.tokensOnPage ),
			wrappedCurrentPage   : 0,
			discoveredCurrentPage: 0,

			loadingInProgress    : this.store.getState().tokenLoadingInProgress,
		}
	}

	componentDidMount() {

		this.scrollToBlock.current?.scrollIntoView();

		this.unsubscribe = this.store.subscribe(() => {

			if ( this.state.tokenFetchRequested && this.metamaskAdapter.wrapperContract ) {
				this.setState({ tokenFetchRequested: false });
				this.submitFetchToken();
			}
			if ( this.state.tokenMintRequested && this.metamaskAdapter.wrapperContract ) {
				this.setState({ tokenMintRequested: false });
				this.submitMintToken();
			}

			const waitingTokens = this.store.getState().waitingTokens;
			const ignoredTokens: Array<{ contractAddress: string, tokenId: string }> = this.store.getState().ignoredTokens;
			const incompleteTokens = this.store.getState().incompleteTokens
				.filter((item: WrappedTokenType) => {
					// const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => { return iitem.token.tokenUrl === item.tokenUrl });
					const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => {
						return iitem.token.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && iitem.token.tokenId === item.tokenId
					});
					return !foundWaiting.length;
				});
			const wrappedTokens: Array<WrappedTokenType> = this.store.getState().wrappedTokens
				.filter((item: WrappedTokenType) => {
					// const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => { return iitem.token.tokenUrl === item.tokenUrl });
					const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => {
						return iitem.token.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && iitem.token.tokenId === item.tokenId
					});
					return !foundWaiting.length;
				});
			const discoveredTokens: Array<WrappedTokenType> = this.store.getState().discoveredTokens
				.filter((item: WrappedTokenType) => {
					const foundWaiting = waitingTokens.filter((iitem: { token: WrappedTokenType, msg: string }) => {
						return iitem.token.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.token.tokenId}` === `${item.tokenId}`
					});
					return !foundWaiting.length;
				})
				.filter((item: WrappedTokenType) => {
					const foundWrapped = wrappedTokens.filter((iitem: WrappedTokenType) => {
						return ( iitem.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.tokenId}` === `${item.tokenId}` ) ||
							( iitem.originalContractAddress && iitem.originalTokenId && iitem.originalContractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.originalTokenId}` === `${item.tokenId}` )
					});
					return !foundWrapped.length;
				})
				.filter((item: WrappedTokenType) => {
					const foundIncomplete = incompleteTokens.filter((iitem: WrappedTokenType) => {
						return iitem.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.tokenId}` === `${item.tokenId}`
					});
					return !foundIncomplete.length;
				})
				.filter((item: WrappedTokenType) => {
					const foundIgnored = ignoredTokens.filter((iitem: { contractAddress: string, tokenId: string }) => {
						return iitem.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() && `${iitem.tokenId}` === `${item.tokenId}`
					});
					return !foundIgnored.length;
				});

			this.setState({
				decimalsNative       : this.store.getState().metamaskAdapter.networkTokenDecimals,
				iconNative           : this.store.getState().metamaskAdapter.networkTokenIcon,
				symbolNative         : this.store.getState().metamaskAdapter.networkTokenTicket,
				minterContract       : this.store.getState().metamaskAdapter.minterContract,
				techToken            : this.store.getState().erc20TechTokenParams,
				wrappedTokens        : wrappedTokens,
				waitingTokens        : waitingTokens,
				incompleteTokens     : incompleteTokens,
				discoveredTokens     : discoveredTokens,
				wrappedTokensStat    : this.store.getState().wrappedTokensStat,
				chainId              : this.store.getState().metamaskAdapter.chainId,
				metamaskLogged       : this.store.getState().metamaskAdapter.logged,
				explorerBaseUrl      : this.store.getState().metamaskAdapter.explorerBaseUrl,
				marketplaceUrl       : this.store.getState().metamaskAdapter.marketplaceUrl,
				erc20CollateralTokens: this.store.getState().erc20CollateralTokens,

				wrappedPages         : Math.ceil( wrappedTokens.length / this.tokensOnPage ),
				discoveredPages      : Math.ceil( discoveredTokens.length / this.tokensOnPage ),

				loadingInProgress    : this.store.getState().tokenLoadingInProgress,
			});
		});
 	}
	componentWillUnmount() { this.unsubscribe(); }

	// ----- WRAP BLOCK -----
	async submitFetchToken() {

		let is721 = true;

		if ( this.metamaskAdapter.wrapperContract.canWrapper('checkIs721') ) {
			is721 = await this.metamaskAdapter.wrapperContract.checkIsERC721(this.state.input_nft_address);
		}

		if ( !is721 ) {
			this.store.dispatch(setError({
				text: this.t('Address is not ERC721-compatible'),
				buttons: [{
					text: this.t('Try anyway'),
					clickFunc: () => {
						this.store.dispatch(clearError());
						this.props.history.push(`/token?chain=${this.state.chainId}&contractAddress=${this.state.input_nft_address}&tokenId=${this.state.input_token_id}`);
					}
				},
				{
					text: this.t('Change address'),
					clickFunc: () => {
						this.store.dispatch(clearError());
						this.store.dispatch(unsetLoading());
					}
				}],
				links: undefined
			}));
			return;
		}

		const foundContract = this.metamaskAdapter.wrapperContract.previousContracts.filter((iitem) => {
			return this.state.input_nft_address.toLowerCase() === iitem.address.toLowerCase()
		})
		if (
			this.metamaskAdapter.wrapperContract.contractAddress.toLowerCase() === this.state.input_nft_address.toLowerCase() ||
			foundContract.length
		) {
			this.store.dispatch(setError({
				text: this.t('You cannot wrap wrapped token'),
				buttons: [{
					text: this.t('Preview token'),
					clickFunc: () => {
						this.store.dispatch(clearError());
						this.props.history.push(`/token?chain=${this.state.chainId}&contractAddress=${this.state.input_nft_address}&tokenId=${this.state.input_token_id}`);
					}
				},
				{
					text: this.t('Change address'),
					clickFunc: () => {
						this.store.dispatch(clearError());
						this.store.dispatch(unsetLoading());
					}
				}],
				links: undefined
			}));
			return;
		}

		this.props.history.push(`/token?chain=${this.state.chainId}&contractAddress=${this.state.input_nft_address}&tokenId=${this.state.input_token_id}`);
	}
	tokenMinted(tokenId: string) {
		if ( this.mintSubscription ) { this.mintSubscription.unsubscribe(); }
		this.store.dispatch(unsetLoading());
		this.props.history.push(`/token?chain=${this.state.chainId}&contractAddress=${this.state.minterContract}&tokenId=${tokenId}`);
	}
	async submitMintToken() {
		if ( !this.state.minterContract ) { return; }

		this.store.dispatch(setLoading({ msg: this.t('Waiting for mint') }));
		// mintToken(
		// 	this.metamaskAdapter,
		// 	this.state.minterContract,
		// 	this.store.getState().account.address
		// )
		// 		.then((data) => {
		// 			this.store.dispatch(unsetLoading());
		// 			this.props.history.push(`/token?chain=${this.state.chainId}&contractAddress=${this.state.minterContract}&tokenId=${data.events.Transfer.returnValues.tokenId}`);
		// 		})
		// 		.catch((e) => {
		// 			console.log('Cannot mint after send: ', e);
		// 			let errorMsg = '';

		// 			if ('message' in e) {
		// 				try {
		// 					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
		// 					errorMsg = errorParsed.message
		// 						.replace('execution reverted: ', '');
		// 				} catch(ignored) {}
		// 			}

		// 			this.store.dispatch(setError({
		// 				text: `${this.t('Cannot mint token')}: ${errorMsg || e}`,
		// 				buttons: undefined,
		// 				links: undefined,
		// 			}));

		// 			this.store.dispatch(unsetLoading());
		// 			return;
		// 		})

		const userAddress = this.store.getState().account.address;
		const contractAddress = this.state.minterContract;
		let   minterAbi = require(`../../abis/minter.json`);
		try { minterAbi = require(`../../abis/${contractAddress}.json`) } catch (ignored) {}
		try { minterAbi = require(`../../abis/${contractAddress.toLowerCase()}.json`) } catch (ignored) {}

		const contract = new this.metamaskAdapter.web3.eth.Contract(minterAbi as any, contractAddress);

		const tx = contract.methods.mint( userAddress );

		if ( this.state.chainId !== 137 ) {
			this.mintSubscription = contract.events.Transfer(
				{
					fromBlock: 'earliest',
					filter: { from: '0x0000000000000000000000000000000000000000', to: userAddress }
				},
				(e: any, data: any) => {
					console.log('Mint event caught', data);
					this.tokenMinted(data.returnValues.tokenId);
				}
			);
		}

		// pre-send transaction check
		try {
			await tx.estimateGas({ from: userAddress })
		} catch(e: any) {
			let errorMsg = '';
			try {
				errorMsg = e.message;
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errorMsg = errorParsed.originalError.message;
				} catch(ignored) {}
			} catch(ignored) {
				errorMsg = e;
			}
			throw new Error(errorMsg);
		}

		tx
			.send({ from: userAddress })
			.then((data: any) => {
				console.log('Mint response', data);
				if ( this.state.chainId === 137 ) {
					console.log('plan delayed');
					setTimeout(() => {
						console.log('start delayed');
						this.tokenMinted(data.events.Transfer.returnValues.tokenId);
					}, 30*1000)
				} else {
					console.log('start now');
					this.tokenMinted(data.events.Transfer.returnValues.tokenId);
				}
			})
			.catch((e: any) => {
				console.log('Cannot mint after send: ', e);
				let errorMsg = '';

				if ('message' in e) {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						errorMsg = errorParsed.message
							.replace('execution reverted: ', '');
					} catch(ignored) {}
				}

				this.store.dispatch(setError({
					text: `${this.t('Cannot mint token')}: ${errorMsg || e.message}`,
					buttons: undefined,
					links: undefined,
				}));

				this.store.dispatch(unsetLoading());
				return;
			})
	}
	getSubmitBtn() {
		if ( this.state.mintChecked ) {
			return (
				<button
					className="btn btn-grad"
					disabled={ false }
					onClick={async (e) => {
						e.preventDefault();
						this.store.dispatch(clearError());

						if ( !this.state.metamaskLogged ) {
							this.showAuthMethodSelector();
							this.setState({ tokenMintRequested: true });
						} else {
							this.submitMintToken();
						}
					}}
				>{ this.t('Mint and wrap now') }</button>
			)
		} else {
			return (
				<button
					className="btn btn-grad"
					disabled={
						this.state.input_nft_address === '' ||
						this.state.input_token_id    === '' ||
						( this.metamaskAdapter && this.metamaskAdapter.web3 && !this.metamaskAdapter.web3.utils.isAddress(this.state.input_nft_address) ) ||
						( (!this.metamaskAdapter || !this.metamaskAdapter.web3) && (this.state.input_nft_address.length !== 42 || !this.state.input_nft_address.startsWith('0x')) )
					}
					onClick={async (e) => {
						e.preventDefault();
						this.store.dispatch(clearError());

						if ( !this.state.metamaskLogged ) {
							this.showAuthMethodSelector();
							this.setState({ tokenFetchRequested: true });
						} else {
							this.submitFetchToken();
						}
					}}
				>{ this.t('Wrap now') }</button>
			)
		}
	}
	getWrapBlock() {
		return (
			<div className="c-wrap">
			<div className="c-wrap__header">
				<div className="h3">{ this.t('Wrap NFT') }</div>
				<div className="c-wrap__info">
					<div>
					{ this.t('Non-fungible token wrap') } <span className="text-grad">{ this.t('absolutly') }</span> <span className="text-grad">{ this.t('free') }</span> { this.t('for author (only gas)') }
					</div>
					<img src={ icon_i_attention } alt="" />
				</div>
			</div>
				<div className="c-wrap__form">
				<form>
					<div className="row">
						<div className="col col-12 col-md-5 col-lg-6">
							<label className="input-label">{ this.t('NFT Address') }</label>
							<input
								className="input-control"
								type="text"
								placeholder={ this.t('Paste here') }
								disabled={ this.state.mintChecked }
								value={ this.state.input_nft_address }
								onChange={(e) => {
									this.store.dispatch(clearError());

									this.setState({
										input_nft_address: e.target.value.toLowerCase().replace(/[^a-f0-9x]/g, ""),
									})
								}}
							/>
						</div>
						<div className="col col-12 col-md-4 col-lg-3">
							<label className="input-label">{ this.t('Token ID') }</label>
							<input
								className="input-control"
								type="text"
								placeholder="99 999"
								disabled={ this.state.mintChecked }
								value={ this.state.input_token_id }
								onChange={(e) => {
									this.store.dispatch(clearError());

									this.setState({
										input_token_id: e.target.value.toLowerCase().replace(/[^0-9]/g, ""),
									})
								}}
							/>
						</div>
						<div className="col col-12 col-md-3 col-lg-3">
							<label className="input-label">&nbsp;</label>
							{ this.getSubmitBtn() }
						</div>

						{
							this.state.minterContract ?
							( <div className="col col-12 mt-2 mb-1">
								<div className="row">
									<div className="col-auto mb-2">
										<small>{ this.t('Don\'t have an NFT yet?') }</small>
									</div>
									<div className="col-auto pl-sm-0">
										<label className="checkbox">
											<input
												type="checkbox"
												checked={ this.state.mintChecked }
												onChange={(e) => {
													this.setState({ mintChecked: e.target.checked });
												}}
											/>
											<span className="check"></span>
											<span>{ this.t('Mint original NFT') }</span>
										</label>
									</div>
								</div>
							</div> ) : null
						}

					</div>
				</form>
			</div>
			</div>
		)
	}
	// ----- END WRAP BLOCK -----

	// ----- STAT BLOCK -----
	getStatWrappedBlock() {
		return (
			<div className="col col-12 col-md-6 col-lg-3">
				<div className="field-wrap">
					<div className="field-row">
						<label className="field-label">
							{ this.t('Wrapped') }
							<Tippy
								content={ this.t('Amount of owned wrapped NFTs') }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 260 }
							>
								<span className="i-tip black"></span>
							</Tippy>
						</label>
					</div>
					<div className="field-row">
						<input
							className="field-control"
							type="text"
							value={ this.state.wrappedTokensStat.count }
							readOnly={ true }
						/>
						<span className="field-unit">{ this.t('NFT') }</span>
					</div>
				</div>
			</div>
		)
	}
	getStatCollateralBlock() {
		let erc20CollateralStats = undefined;
		if ( this.state.wrappedTokensStat.erc20Collateral ) {
			erc20CollateralStats = this.state.wrappedTokensStat.erc20Collateral.map((item) => {
				const foundToken = this.state.erc20CollateralTokens.filter((iitem) => {
					if ( !item.address || !iitem.address ) { return false; }
					return item.address.toLowerCase() === iitem.address.toLowerCase()
				});
				if ( foundToken.length ) {
					return {
						amount: item.amount,
						...foundToken[0]
					}
				} else {
					return {
						amount: item.amount,
						address: item.address,
						symbol: item.address,
						icon: default_icon,
						decimals: 18
					}
				}
			});
		}
		let assetsQty = this.state.wrappedTokensStat.erc20Collateral.length;
		if ( this.state.wrappedTokensStat.collateral && !this.state.wrappedTokensStat.collateral.eq(0) ) { assetsQty = assetsQty + 1 }

		return (
			<div className="col col-12 col-md-6 col-lg-3">
				<div className="field-wrap">
					<div className="field-row">
						<label className="field-label">
							{ this.t('Collateral') }
							<Tippy
								content={ this.t('Assets stored inside wrapped NFTs') }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 260 }
							>
								<span className="i-tip black"></span>
							</Tippy>
						</label>
						{/* <span className="field-data">$ 5 098.94</span> */}
					</div>
					<div className="field-row">
						<div className="field-control field-collateral">
							<div className="coins">
								{
									!this.state.wrappedTokensStat.collateral.eq(0) ?
									( <span className="i-coin"><img src={ this.state.iconNative || default_icon } alt="" /></span> ) : ''
								}
								{
									erc20CollateralStats?.filter((item) => { return !item.amount.eq(0) })
										.map((item) => {
											return ( <span className="i-coin" key={ item.address }><img src={ item.icon } alt="" /></span> )
										})
								}
							</div>
							<span className="field-unit">
								{ assetsQty || null }
								{ ' ' }
								{ assetsQty ? ( assetsQty === 1 ? this.t('asset') : this.t('assets') ) : this.t('no assets') }
							</span>
							{
								assetsQty ?
								(
									<div className="field-collateral__details bottom">
										<div className="inner">
											<table>
												<tbody>
													{
														!this.state.wrappedTokensStat.collateral.eq(0) ?
														(
															<tr>
																<td><span className="unit-sum">
																{
																	this.state.wrappedTokensStat.collateral && this.state.decimalsNative
																	? addThousandSeparator(tokenToFloat(this.state.wrappedTokensStat.collateral, this.state.decimalsNative).toString())
																	: '0.000'
																}
																</span></td>
																<td><span className="field-unit"><span className="i-coin"><img src={ this.state.iconNative || default_icon } alt="" /></span>{ this.state.symbolNative }</span></td>
															</tr>
														) : null
													}
													{
														erc20CollateralStats?.filter((item) => { return !item.amount.eq(0) })
															.map((item) => {
																return (
																	<tr key={ item.address }>
																		<td><span className="unit-sum">{ tokenToFloat(item.amount, item.decimals || 0).toString() }</span></td>
																		<td><span className="field-unit">
																		<span className="i-coin"><img src={ item.icon } alt="" /></span>
																			<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${item.address}` }>{ item.symbol }</a>
																		</span></td>
																	</tr>
																)
															})
													}
												</tbody>
											</table>
										</div>
									</div>
								) : null
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
	getStatFeeBlock() {
		// ----- GET LABEL TO WRITE ON BLOCK -----
		// let mainFeeLabel = { amount: '—', icon: default_icon, symbol: '' };
		// if ( this.state.wrappedTokensStat.fee.length ) {
		// 	const foundTechTokenFee = this.state.wrappedTokensStat.fee.filter((item) => { return item.address.toLowerCase() === this.state.techToken.address.toLowerCase() });
		// 	let tokenData;
		// 	if ( foundTechTokenFee.length ) {
		// 		tokenData = this.metamaskAdapter.getERC20Contract(foundTechTokenFee[0].address)?.erc20Params;
		// 		if ( tokenData ) {
		// 			mainFeeLabel = {
		// 				amount: addThousandSeparator(tokenToFloat(foundTechTokenFee[0].amount, tokenData.decimals || 18).toString()),
		// 				icon: tokenData.icon,
		// 				symbol: tokenData.symbol
		// 			};
		// 		}
		// 	} else {
		// 		tokenData = this.metamaskAdapter.getERC20Contract(this.state.wrappedTokensStat.fee[0].address)?.erc20Params;
		// 		if ( tokenData ) {
		// 			mainFeeLabel = {
		// 				amount: addThousandSeparator(tokenToFloat(this.state.wrappedTokensStat.fee[0].amount, tokenData.decimals || 18).toString()),
		// 				icon: tokenData.icon,
		// 				symbol: tokenData.symbol
		// 			};
		// 		}
		// 	}
		// }
		// ----- END GET LABEL TO WRITE ON BLOCK -----
		let assetsQty = this.state.wrappedTokensStat.fee.filter((item) => { return !item.amount.eq(0) }).length;

		return (
			<div className="col col-12 col-md-6 col-lg-3">
				<div className="field-wrap">
					<div className="field-row">
						<label className="field-label">
							{ this.t('Collected Fees') }
							<Tippy
								content={ this.t("Collected transfer fees by user's wNFTs (stored inside your wNFTs)") }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 260 }
							>
								<span className="i-tip black"></span>
							</Tippy>
						</label>
						{/* <span className="field-data">$ 0.0</span> */}
					</div>

					<div className="field-row">
						<div className="field-control field-collateral">
							<div className="coins">
								{
									this.state.wrappedTokensStat.fee?.filter((item) => { return !item.amount.eq(0) })
										.map((item) => {
											const addressToUse = item.address && item.address === '0x0000000000000000000000000000000000000000' ? this.state.techToken.address : item.address;
											const tokenData = this.metamaskAdapter.getERC20Contract(addressToUse)?.erc20Params;
											if ( tokenData ) {
												return ( <span className="i-coin" key={ tokenData.address }><img src={ tokenData.icon } alt="" /></span> )
											} else {
												return ( <span className="i-coin" key={ item.address }><img src={ default_icon } alt="" /></span> )
											}
										})
								}
							</div>
							<span className="field-unit">
								{ assetsQty || null }
								{ ' ' }
								{ assetsQty ? ( assetsQty === 1 ? this.t('asset') : this.t('assets') ) : this.t('no assets') }
							</span>
							{
								assetsQty ?
								(
									<div className="field-collateral__details bottom">
										<div className="inner">
										<table>
										<tbody>
										{
											this.state.wrappedTokensStat.fee?.filter((item) => { return !item.amount.eq(0) })
												.map((item) => {
													const addressToUse = item.address && item.address === '0x0000000000000000000000000000000000000000' ? this.state.techToken.address : item.address;
													const tokenData = this.metamaskAdapter.getERC20Contract(addressToUse)?.erc20Params;
													if ( tokenData ) {
														return (
															<tr key={ tokenData.address }>
																<td><span className="unit-sum">{ addThousandSeparator(tokenToFloat(item.amount, tokenData.decimals || 18).toString()) }</span></td>
																<td><span className="field-unit">
																	<span className="i-coin"><img src={ tokenData.icon } alt="" /></span>
																	<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${tokenData.address}` }>{ tokenData.symbol }</a>
																</span></td>
															</tr>
														)
													} else {
														return (
															<tr key={ item.address }>
																<td><span className="unit-sum">{ addThousandSeparator(tokenToFloat(item.amount, 18).toString()) }</span></td>
																<td><span className="field-unit">
																	<span className="i-coin"><img src={ default_icon } alt="" /></span>
																	<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${item.address}` }>{ item.address }</a>
																</span></td>
															</tr>
														)
													}
												})
										}
										</tbody>
										</table>
										</div>
									</div>
								) : null
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
	getStatRoyaltyBlock() {
		// ----- GET LABEL TO WRITE ON BLOCK -----
		// let mainRoyaltyLabel = { amount: '—', icon: default_icon, symbol: '' };
		// if ( this.state.wrappedTokensStat.royalties.length ) {
		// 	const foundTechTokenFee = this.state.wrappedTokensStat.royalties.filter((item) => { return item.address.toLowerCase() === this.state.techToken.address.toLowerCase() });
		// 	let tokenData;
		// 	if ( foundTechTokenFee.length ) {
		// 		tokenData = this.metamaskAdapter.getERC20Contract(foundTechTokenFee[0].address)?.erc20Params;
		// 		if ( tokenData ) {
		// 			mainRoyaltyLabel = {
		// 				amount: addThousandSeparator(tokenToFloat(foundTechTokenFee[0].amount, tokenData.decimals || 18).toString()),
		// 				icon: tokenData.icon,
		// 				symbol: tokenData.symbol
		// 			};
		// 		}
		// 	} else {
		// 		tokenData = this.metamaskAdapter.getERC20Contract(this.state.wrappedTokensStat.royalties[0].address)?.erc20Params;
		// 		if ( tokenData ) {
		// 			mainRoyaltyLabel = {
		// 				amount: addThousandSeparator(tokenToFloat(this.state.wrappedTokensStat.royalties[0].amount, tokenData.decimals || 18).toString()),
		// 				icon: tokenData.icon,
		// 				symbol: tokenData.symbol
		// 			};
		// 		}
		// 	}
		// }
		// ----- END GET LABEL TO WRITE ON BLOCK -----
		let assetsQty = this.state.wrappedTokensStat.fee.filter((item) => { return !item.amount.eq(0) }).length;

		return (
			<div className="col col-12 col-md-6 col-lg-3">
				<div className="field-wrap">
					<div className="field-row">
						<label className="field-label">
							{ this.t('Royalty') }
							<Tippy
								content={ this.t('Roalties generated and distributed by displayed wNFTs') }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 260 }
							>
								<span className="i-tip black"></span>
							</Tippy>
						</label>
						{/* <span className="field-data">$ 0.0</span> */}
					</div>

					<div className="field-row">
						<div className="field-control field-collateral">
							<div className="coins">
								{
									this.state.wrappedTokensStat.royalties?.filter((item) => { return !item.amount.eq(0) })
										.map((item) => {
											const addressToUse = item.address && item.address === '0x0000000000000000000000000000000000000000' ? this.state.techToken.address : item.address;
											const tokenData = this.metamaskAdapter.getERC20Contract(addressToUse)?.erc20Params;
											if ( tokenData ) {
												return ( <span className="i-coin" key={ tokenData.address }><img src={ tokenData.icon } alt="" /></span> )
											} else {
												return ( <span className="i-coin" key={ item.address }><img src={ default_icon } alt="" /></span> )
											}
										})
								}
							</div>
							<span className="field-unit">
								{ assetsQty || null }
								{ ' ' }
								{ assetsQty ? ( assetsQty === 1 ? this.t('asset') : this.t('assets') ) : this.t('no assets') }
							</span>
							{
								assetsQty ?
								(
									<div className="field-collateral__details bottom">
										<div className="inner">
										<table>
										<tbody>
										{
											this.state.wrappedTokensStat.royalties?.filter((item) => { return !item.amount.eq(0) })
												.map((item) => {
													const addressToUse = item.address && item.address === '0x0000000000000000000000000000000000000000' ? this.state.techToken.address : item.address;
													const tokenData = this.metamaskAdapter.getERC20Contract(addressToUse)?.erc20Params;
													if ( tokenData ) {
														return (
															<tr key={ tokenData.address }>
																<td><span className="unit-sum">{ addThousandSeparator(tokenToFloat(item.amount, tokenData.decimals || 18).toString()) }</span></td>
																<td><span className="field-unit">
																	<span className="i-coin"><img src={ tokenData.icon } alt="" /></span>
																	<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${ tokenData.address}` }>{ tokenData.symbol }</a>
																</span></td>
															</tr>
														)
													} else {
														return (
															<tr key={ item.address }>
																<td><span className="unit-sum">{ addThousandSeparator(tokenToFloat(item.amount, 18).toString()) }</span></td>
																<td><span className="field-unit">
																	<span className="i-coin"><img src={ default_icon } alt="" /></span>
																	<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${ item.address}` }>{ item.address }</a>
																</span></td>
															</tr>
														)
													}
												})
											}
										</tbody>
										</table>
										</div>
									</div>
								) : null
						}
						</div>
					</div>
				</div>
			</div>
		)
	}
	getStatsBlock() {
		return (
			<div className="c-stat">
				<div className="row">
					{ this.getStatWrappedBlock() }
					{ this.getStatCollateralBlock() }
					{ this.getStatFeeBlock() }
					{ this.getStatRoyaltyBlock() }
				</div>
			</div>
		)
	}
	// ----- END STAT BLOCK -----

	// ----- TOKEN BLOCK -----
	getCopiedHint(token: WrappedTokenType, icon: string) {
		let foundHint = false;
		if ( this.state.copiedHint ) {
		 	foundHint = ( this.state.copiedHint.id === `${token.contractAddress}${token.tokenId}` ) && ( this.state.copiedHint.icon === icon );
		}
		return (<span className="btn-action-info" style={{display: foundHint ? 'block' : 'none' }}>{ this.t('Copied') }</span>)
	}
	getTokenIdBlock(token: WrappedTokenType) {
		if ( `${token.tokenId}`.length < 8 ) {
			return (
				<CopyToClipboard
					text={ token.tokenId }
					onCopy={() => {
						this.setState({
							copiedHint: { id: `${token.contractAddress}${token.tokenId}`, icon: 'id' }
						});
						clearTimeout(this.copiedHintTimer);
						this.copiedHintTimer = window.setTimeout(() => { this.setState({
							copiedHint: undefined
						}); }, this.copiedHintTimeout*1000);
					}}
				>
					<button className="btn-copy">
						<span>
							<span className="title">{ this.t('ID') }</span>
							{ compactString(token.tokenId) }
						</span>
						<img src={icon_i_copy} alt="" />
						{ this.getCopiedHint(token, 'id') }
					</button>
				</CopyToClipboard>
			)
		}

		return (
			<CopyToClipboard
				text={ token.tokenId }
				onCopy={() => {
					this.setState({
						copiedHint: { id: `${token.contractAddress}${token.tokenId}`, icon: 'id' }
					});
					clearTimeout(this.copiedHintTimer);
					this.copiedHintTimer = window.setTimeout(() => { this.setState({
						copiedHint: undefined
					}); }, this.copiedHintTimeout*1000);
				}}
			>
				<button className="btn-copy">
					<Tippy
						content={ token.tokenId }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 512 }
					>
						<span>
							<span className="title">{ this.t('ID') }</span>
							{ compactString(token.tokenId) }
						</span>
					</Tippy>
					<img src={icon_i_copy} alt="" />
					{ this.getCopiedHint(token, 'id') }
				</button>
			</CopyToClipboard>
		)
	}
	getTokenCardHeader(token: WrappedTokenType) {
		return (
			<div>
				<CopyToClipboard
					text={ token.contractAddress }
					onCopy={() => {
						this.setState({
							copiedHint: { id: `${token.contractAddress}${token.tokenId}`, icon: 'address' }
						});
						clearTimeout(this.copiedHintTimer);
						this.copiedHintTimer = window.setTimeout(() => { this.setState({
							copiedHint: undefined
						}); }, this.copiedHintTimeout*1000);
					}}
				>
					<button className="btn-copy">
						<Tippy
							content={ token.contractAddress }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 512 }
						>
							<span>
								<span className="title">{ this.t('ADDRESS') }</span>
								{ compactString(token.contractAddress) }
							</span>
						</Tippy>
						<img src={icon_i_copy} alt="" />
						{ this.getCopiedHint(token, 'address') }
					</button>
				</CopyToClipboard>
				{ this.getTokenIdBlock(token) }
			</div>
		)
	}
	getTokenCardFooter(token: WrappedTokenType, type: string, auxMsg: string | undefined) {
		if ( type === 'incomplete' ) {
			return (
				<Link
					className="btn btn-lg btn-primary"
					to={`/token?chain=${this.state.chainId}&contractAddress=${token.contractAddress}&tokenId=${token.tokenId}`}
				>{ this.t('Continue wrap') }</Link>
			)
		}
		if ( type === 'discovered' ) {
			return (
				<Link
					className="btn btn-lg btn-primary"
					to={`/token?chain=${this.state.chainId}&contractAddress=${token.contractAddress}&tokenId=${token.tokenId}`}
				>{ this.t('WRAP') }</Link>
			)
		}
		if ( type === 'waiting' ) {
			return (
				<div className="w-card__status">
					<div>
						<span>{ auxMsg?.toUpperCase() }</span>
					</div>
				</div>
			)
		}


		const conditions = [];

		const nowDate = new BigNumber(new Date().getTime());
		if ( token.unwrapAfter && token.unwrapAfter.gt( nowDate ) ) { conditions.push(unixtimeToStr(token.unwrapAfter)) }

		if (
			token.unwraptFeeThreshold && token.backedTokens && token.unwraptFeeThreshold.gt( token.backedTokens )
		) {
			const addressToFilter = token.transferFeeToken || '0x0000000000000000000000000000000000000000';
			let   currencyToShow  = this.state.techToken;
			const foundCurrency   = this.state.erc20CollateralTokens.filter( (item) => {
				if ( !item.address ) { return false; }
				return item.address.toLowerCase() === addressToFilter.toLowerCase()
			} );
			if ( foundCurrency.length ) { currencyToShow = foundCurrency[0]; }

			const diff = tokenToFloat(token.unwraptFeeThreshold.plus(-token.backedTokens), currencyToShow.decimals || 18);
			conditions.push(`${addThousandSeparator(diff.toString())} ${currencyToShow.symbol}`)
		}

		if (conditions.length) {
			return (
				<div className="w-card__status">
					<div>
						<span className="small">{ this.t('Unwrap will be ready after Time or Value Unlock.') }</span>
						<span>{ conditions.join(', ') }</span>
					</div>
				</div>
			)
		} else {
			return (
				<Link
					className="btn btn-lg btn-yellow"
					to={`/token?chain=${this.state.chainId}&contractAddress=${token.contractAddress}&tokenId=${token.tokenId}`}
				>{ this.t('UNWRAP') }</Link>
			)
		}

	}
	getTokenCardMenu(token: WrappedTokenType, type: string) {
		const getTransferCurrency = () => {
			if ( !token.transferFee || token.transferFee.eq(0) ) { return '' }
			if ( !token.transferFeeToken || token.transferFeeToken === '0x0000000000000000000000000000000000000000' ) { return this.state.techToken.symbol }
			const foundERC20 = this.state.erc20CollateralTokens.filter((item) => {
				if ( !item.address ) { return false; }
				return item.address.toLowerCase() === token.transferFeeToken.toLowerCase()
			});
			if ( foundERC20.length ) {
				return foundERC20[0].symbol
			} else {
				return this.state.techToken.symbol
			}
		}
		return (
			<div className="w-card__action">
				<button className="btn-action">
					<img src={icon_i_action} alt="" />
				</button>

				<ul className="list-action">
					<li>
						<CopyToClipboard
							text={ `${window.location.origin}/#/token?chain=${this.state.chainId}&contractAddress=${token.contractAddress}&tokenId=${token.tokenId}` }
							onCopy={() => {
								this.setState({
									copiedHint: { id: `${token.contractAddress}${token.tokenId}`, icon: 'tokenlinkimg' }
								});
								clearTimeout(this.copiedHintTimer);
								this.copiedHintTimer = window.setTimeout(() => { this.setState({
									copiedHint: undefined
								}); }, this.copiedHintTimeout*1000);
							}}
						>
							<button>
							{ this.t('Copy NFT URL') }
							</button>
						</CopyToClipboard>
					</li>
					{ type === 'wrapped' &&
						this.metamaskAdapter.wrapperContract.canWrapper('transfer', token.contractAddress) ?
						(<li>
							<button
								onClick={() => {
									this.setState({
										transferToken: token,
									})
								}}
							>
								{ this.t('Transfer') }
								<span className="data">
									{
										token.transferFee && !token.transferFee.eq(0) && this.state.techToken.decimals
										? addThousandSeparator(tokenToFloat(token.transferFee, this.state.techToken.decimals).toString())
										: ''
									}
									{ ' ' }
									{ getTransferCurrency() }
								</span>
							</button>
						</li>) : ''
					}
					{ type !== 'wrapped' ?
						(<li>
							<button
								onClick={() => {
									this.setState({
										transferToken: token,
									})
								}}
							>
								{ this.t('Transfer') }
							</button>
						</li>) : ''
					}
					{ type === 'wrapped' && this.state.marketplaceUrl ?
						(<li>
							<button
								onClick={() => {
									window.open(`${this.state.marketplaceUrl}${this.state.chainId}/${token.contractAddress}/${token.tokenId}`, '_blank')
								}}
							>
								{ this.t('Sell') }
							</button>
						</li>) : ''
					}

					<li>
						<button
							onClick={() => {
								this.setState({
									approveToken: token,
								})
							}}
						>
							{ this.t('Set approval for all ERC721 tokens of contract') }
						</button>
					</li>

					{
						type === 'incomplete' ?
						(<li>
							<button
								onClick={() => {
									this.store.dispatch(incompleteTokensRemove(token));
									removeERC721Token(token, this.state.chainId)
								}}
							>{ this.t('Delete NFT') }</button>
						</li> ) : ''
					}
				</ul>
			</div>
		)
	}
	getTokenMedia(token: WrappedTokenType) {
		if ( token.image === undefined ) {
			return (
				<div className="inner">
					<div className="default">
						<img src={ icon_logo } alt="" />
						<span><Trans i18nKey="Loading NON-FUNGIBLE TOKEN Preview" components={[<br />]} /></span>
					</div>
				</div>
			)
		}
		if ( token.image === '' ) {
			return (
				<div className="inner">
					<div className="default">
						<img src={ icon_logo } alt="" />
						<span><Trans i18nKey="Cannot load NON-FUNGIBLE TOKEN Preview" components={[<br />]} /></span>
					</div>
				</div>
			)
		}

		return (
			<div className="inner">
				<video className="img" src={ token.image } poster={ token.image } autoPlay={ true } muted={ true } loop={ true } />
			</div>
		)
	}
	getTokenCollateralBlock(token: WrappedTokenType, type: string) {

		let bakedERC20Values = undefined;
		if ( token.backedERC20Value ) {
			bakedERC20Values = token.backedERC20Value.map((item) => {
				const foundToken = this.state.erc20CollateralTokens.filter((iitem) => {
					if ( !item.address || !iitem.address ) { return false; }
					return item.address.toLowerCase() === iitem.address.toLowerCase()
				});
				if ( foundToken.length ) {
					return {
						amount: item.amount,
						...foundToken[0]
					}
				} else {
					return {
						amount: item.amount,
						address: item.address,
						symbol: item.address,
						icon: default_icon,
						decimals: 18
					}
				}
			});
		}
		let assetsQty = token.backedERC20Value.length;
		if ( token.backedValue && !token.backedValue.eq(0) ) { assetsQty = assetsQty + 1 }

		return (
			<div className="field-wrap">
				<div className="field-row">
					<label className="field-label">{ this.t('Collateral') }</label>
				</div>
				<div className="field-row">
					<div className="field-control field-collateral">
						{
							( token.backedValue && !token.backedValue.eq(0) ) || ( bakedERC20Values && bakedERC20Values.length ) ?
							(
								<div className="coins">
									{
										token.backedValue && !token.backedValue.eq(0) ?
										( <span className="i-coin"><img src={ this.state.iconNative } alt="" /></span> ) : null
									}
									{
										bakedERC20Values?.map((item) => { return ( <span key={ item.address } className="i-coin"><img src={ item.icon } alt="" /></span> ) })
									}
								</div>
							) : null
						}
						<div className="sum">
							{ assetsQty || '' }
							{ ' ' }
							{ assetsQty ? ( assetsQty === 1 ? this.t('asset') : this.t('assets') ) : this.t('no assets') }
						</div>
						{
							assetsQty ?
							( <div className="field-collateral__details">
								<div className="inner">
									<table>
										<tbody>
											{
												token.backedValue && !token.backedValue.eq(0) ?
												(
													<tr>
														<td><span className="unit-sum">
														{
															token.backedValue && this.state.decimalsNative
																? addThousandSeparator(tokenToFloat(token.backedValue, this.state.decimalsNative).toString())
																: '—'
														}
														</span></td>
														<td><span className="field-unit">
															<span className="i-coin"><img src={ this.state.iconNative } alt="" /></span>{ this.state.symbolNative }
														</span></td>
													</tr>
												) : null
											}
											{
												bakedERC20Values?.map((item) => { return (
													<tr key={ item.address }>
														<td><span className="unit-sum">{ addThousandSeparator(tokenToFloat(item.amount, item.decimals || 0).toString()) }</span></td>
														<td><span className="field-unit">
															<span className="i-coin"><img src={ item.icon } alt="" /></span>
															<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${ item.address}` }>{ item.symbol }</a>
														</span></td>
													</tr>
												) })
											}
										</tbody>
									</table>
								</div>
							</div> ) : null
					}
					</div>
					{
						type === 'wrapped' &&
							this.metamaskAdapter.wrapperContract.canWrapper('addvalue', token.contractAddress) ?
						(
							<button
								className="btn-add"
								onClick={() => {
									this.setState({
										addValueToken: token,
									})
								}}
							>
								<img src={icon_i_plus} alt="" />
							</button>
						) : ''
					}
				</div>
			</div>
		)
	}
	getTokenCollectedFeeBlock(token: WrappedTokenType) {
		const getTransferCurrency = () => {
			if ( !token.transferFeeToken || token.transferFeeToken === '0x0000000000000000000000000000000000000000' ) {
				return { address: undefined, symbol: this.state.techToken.symbol, icon: this.state.techToken.icon || default_icon }
			}
			const foundERC20 = this.state.erc20CollateralTokens.filter((item) => {
				if ( !item.address ) { return false; }
				return item.address.toLowerCase() === token.transferFeeToken.toLowerCase()
			});
			if ( foundERC20.length ) {
				return { address: foundERC20[0].address, symbol: foundERC20[0].symbol, icon: foundERC20[0].icon || default_icon }
			} else {
				return { address: this.state.techToken.address, symbol: this.state.techToken.symbol, icon: this.state.techToken.icon || default_icon }
			}
		}
		const feeToken = token.transferFeeToken && token.transferFeeToken !== '0x0000000000000000000000000000000000000000'
			? this.metamaskAdapter.getERC20Contract(token.transferFeeToken)?.erc20Params || { icon: default_icon, symbol: token.transferFeeToken, decimals: 18 }
			: undefined;

		if ( token.backedTokens && !token.backedTokens.eq(0) && addThousandSeparator(tokenToFloat(token.backedTokens, feeToken?.decimals || this.state.decimalsNative).toString()).length > 7 ) {
			return (
				<div className="field-wrap">
					<div className="field-row">
						<label className="field-label">{ this.t('Collected Fees') }</label>
					</div>
					<Tippy
						content={ addThousandSeparator(tokenToFloat(token.backedTokens, feeToken?.decimals || this.state.decimalsNative).toString()) }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 512 }
					>
						<div className="field-row">
							<div className="field-control">
								<span>{
									token.backedTokens && !token.backedTokens.eq(0)
										? addThousandSeparator(tokenToFloat(token.backedTokens, feeToken?.decimals || this.state.decimalsNative).toString())
										: '—'
								}</span>
							</div>
							{
								token.backedTokens && !token.backedTokens.eq(0) ?
								( <span className="field-unit">
									<span className="i-coin"><img src={ getTransferCurrency().icon } alt="" /></span>
									{ getTransferCurrency().symbol }
								</span> ) : null
							}
						</div>
					</Tippy>
				</div>
			)
		} else {
			return (
				<div className="field-wrap">
					<div className="field-row">
						<label className="field-label">{ this.t('Collected Fees') }</label>
					</div>
					<div className="field-row">
						<div className="field-control">
							<span>{
								token.backedTokens && !token.backedTokens.eq(0)
									? addThousandSeparator(tokenToFloat(token.backedTokens, feeToken?.decimals || this.state.decimalsNative).toString())
									: '—'
							}</span>
						</div>
						{
							token.backedTokens && !token.backedTokens.eq(0) ?
							( <span className="field-unit">
								<span className="i-coin"><img src={ getTransferCurrency().icon } alt="" /></span>
								{
									getTransferCurrency().address ?
									( <a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${ getTransferCurrency().address}` }>{ getTransferCurrency().symbol }</a> ) :
									getTransferCurrency().symbol
								}
							</span> ) : null
						}
					</div>
				</div>
			)
		}
	}
	getTokenTransferFeeBlock(token: WrappedTokenType) {
		const getTransferCurrency = () => {
			if ( !token.transferFeeToken || token.transferFeeToken === '0x0000000000000000000000000000000000000000' ) {
				return { address: this.state.techToken.address, symbol: this.state.techToken.symbol, icon: this.state.techToken.icon || default_icon }
			}
			const foundERC20 = this.state.erc20CollateralTokens.filter((item) => {
				if ( !item.address ) { return false; }
				return item.address.toLowerCase() === token.transferFeeToken.toLowerCase()
			});
			if ( foundERC20.length ) {
				return { address: foundERC20[0].address, symbol: foundERC20[0].symbol, icon: foundERC20[0].icon || default_icon }
			} else {
				return { address: this.state.techToken.address, symbol: this.state.techToken.symbol, icon: this.state.techToken.icon || default_icon }
			}
		}
		const feeToken = token.transferFeeToken && token.transferFeeToken !== '0x0000000000000000000000000000000000000000'
			? this.metamaskAdapter.getERC20Contract(token.transferFeeToken)?.erc20Params || { icon: default_icon, symbol: token.transferFeeToken, decimals: 18 }
			: undefined;

		if ( token.transferFee && !token.transferFee.eq(0) && addThousandSeparator(tokenToFloat(token.transferFee, feeToken?.decimals || this.state.decimalsNative).toString()).length > 7 ) {
			return (
				<div className="field-wrap">
					<div className="field-row">
						<label className="field-label">{ this.t('Transfer Fee') }</label>
					</div>
					<Tippy
						content={ addThousandSeparator(tokenToFloat(token.transferFee, feeToken?.decimals || this.state.decimalsNative).toString()) }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 512 }
					>
						<div className="field-row">
							<div className="field-control">
								<span>{
									token.transferFee && !token.transferFee.eq(0)
										? addThousandSeparator(tokenToFloat(token.transferFee, feeToken?.decimals || this.state.decimalsNative).toString())
										: '—'
								}</span>
							</div>
							{
								token.transferFee && !token.transferFee.eq(0) ?
								( <span className="field-unit">
									<span className="i-coin"><img src={ getTransferCurrency().icon } alt="" /></span>
									{
										getTransferCurrency().address ?
										( <a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${ getTransferCurrency().address}` }>{ getTransferCurrency().symbol }</a> ) :
										getTransferCurrency().symbol
									}
								</span> ) : null
							}
						</div>
					</Tippy>
				</div>
			)
		} else {
			return (
				<div className="field-wrap">
					<div className="field-row">
						<label className="field-label">{ this.t('Transfer Fee') }</label>
					</div>
					<div className="field-row">
						<div className="field-control">
							<span>{
								token.transferFee && !token.transferFee.eq(0)
									? addThousandSeparator(tokenToFloat(token.transferFee, feeToken?.decimals || this.state.decimalsNative).toString())
									: '—'
							}</span>
						</div>
						{
							token.transferFee && !token.transferFee.eq(0) ?
							( <span className="field-unit">
								<span className="i-coin"><img src={ getTransferCurrency().icon } alt="" /></span>
								{
									getTransferCurrency().address ?
									( <a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${ getTransferCurrency().address}` }>{ getTransferCurrency().symbol }</a> ) :
									getTransferCurrency().symbol
								}
							</span> ) : null
						}
					</div>
				</div>
			)
		}
	}
	getTokenRoyaltyBlock(token: WrappedTokenType) {

		// ----- ROYALTY VALUE -----
		// const getTransferCurrency = () => {
		// 	if ( !token.transferFeeToken || token.transferFeeToken === '0x0000000000000000000000000000000000000000' ) {
		// 		return { symbol: this.state.techToken.symbol, icon: this.state.techToken.icon || default_icon }
		// 	}
		// 	const foundERC20 = this.state.erc20CollateralTokens.filter((item) => {
		// 	if ( !item.address ) { return false; }
		// 	return item.address.toLowerCase() === token.transferFeeToken.toLowerCase()
		// });
		// 	if ( foundERC20.length ) {
		// 		return { symbol: foundERC20[0].symbol, icon: foundERC20[0].icon || default_icon }
		// 	} else {
		// 		return { symbol: this.state.techToken.symbol, icon: this.state.techToken.icon || default_icon }
		// 	}
		// }

		// return (
		// 	<div className="field-wrap">
		// 		<div className="field-row">
		// 			<label className="field-label">{ this.t('Royalty') }</label>
		// 		</div>
		// 		<div className="field-row">
		// 			<div className="field-control">
		// 				<span>{
		// 					token.royalty && !token.royalty.eq(0)
		// 						? addThousandSeparator(tokenToFloat(token.royalty, this.state.techToken.decimals || 18).toString())
		// 						: '—'
		// 				}</span>
		// 			</div>
		// 			<span className="field-unit">
		// 				<span className="i-coin"><img src={ getTransferCurrency().icon } alt="" /></span>
		// 				{ getTransferCurrency().symbol }
		// 			</span>
		// 		</div>
		// 	</div>
		// )
		// ----- END ROYALTY VALUE -----

		// ----- ROYALTY PERCENT -----
		if ( token.royaltyPercent && !token.royaltyPercent.eq(0) && token.royaltyPercent.toString().length > 7 ) {
			return (
				<div className="field-wrap">
					<div className="field-row">
						<label className="field-label">{ this.t('Royalty') }</label>
					</div>
					<Tippy
						content={ token.royaltyPercent.toString() }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 512 }
					>
						<div className="field-row">
							<div className="field-control">
								<span>{
									token.royaltyPercent && !token.royaltyPercent.eq(0)
										? token.royaltyPercent.toString()
										: '—'
								}</span>
							</div>
							{
								token.royaltyPercent && !token.royaltyPercent.eq(0) ?
								( <span className="field-unit">
									%
								</span> ) : null
							}
						</div>
					</Tippy>
				</div>
			)
		} else {
			return (
				<div className="field-wrap">
					<div className="field-row">
						<label className="field-label">{ this.t('Royalty') }</label>
					</div>
					<div className="field-row">
						<div className="field-control">
							<span>{
								token.royaltyPercent && !token.royaltyPercent.eq(0)
									? token.royaltyPercent.toString()
									: '—'
							}</span>
						</div>
						{
							token.royaltyPercent && !token.royaltyPercent.eq(0) ?
							( <span className="field-unit">
								%
							</span> ) : null
						}
					</div>
				</div>
			)
		}
		// ----- END ROYALTY VALUE -----
	}
	getTokenBlock(token: WrappedTokenType, type: string, auxMsg: string | undefined) {
		return (
			<div className="c-col" key={`${token.contractAddress}${token.tokenId}`}>
				<div className="w-card w-card-preview">
					<div className="bg">
						<div className="w-card__nft">
							{ this.getTokenCardHeader(token) }
							{ this.getTokenCardMenu(token, type) }
						</div>

						<div className="w-card__token">
							<CopyToClipboard
								text={ `${window.location.origin}/#/token?chain=${this.state.chainId}&contractAddress=${token.contractAddress}&tokenId=${token.tokenId}` }
								onCopy={() => {
									this.setState({
										copiedHint: { id: `${token.contractAddress}${token.tokenId}`, icon: 'tokenlinkimg' }
									});
									clearTimeout(this.copiedHintTimer);
									this.copiedHintTimer = window.setTimeout(() => { this.setState({
										copiedHint: undefined
									}); }, this.copiedHintTimeout*1000);
								}}
							>
								<button className="btn-token-link">
									<img src={ icon_i_link } alt="" />
									{ this.getCopiedHint(token, 'tokenlinkimg') }
								</button>
							</CopyToClipboard>
							{ this.getTokenMedia(token) }
						</div>

						<div className="w-card__param">
							<div className="form-row">
								{ this.getTokenCollateralBlock(token, type) }
							</div>
							<div className="form-row">
								{ this.getTokenCollectedFeeBlock(token) }
								{ this.getTokenTransferFeeBlock(token) }
								{ this.getTokenRoyaltyBlock(token) }
							</div>

							{ this.getTokenCardFooter(token, type, auxMsg) }

						</div>
					</div>
				</div>
			</div>
		)
	}
	// ----- END TOKEN BLOCK -----

	getInProgressTokensList() {
		if (
			// !this.state.incompleteTokens.length &&
			!this.state.waitingTokens.length
		) {
			return null
		}

		return (
			<React.Fragment>
			<div className="db-section">
			<div className="divider right short"></div>
			<div className="container">
				<div className="c-header">
					<div className="h3">{ this.t('In progress') }</div>
				</div>
				<div className="c-row" ref={ this.scrollToBlock }>
					{
						this.state.waitingTokens
							// .sort((item, prev) => {
							// 	if ( item.token.contractAddress < prev.token.contractAddress ) { return -1 }
							// 	if ( item.token.contractAddress > prev.token.contractAddress ) { return  1 }

							// 	try {
							// 		if ( new BigNumber(item.token.tokenId).isNaN() || new BigNumber(prev.token.tokenId).isNaN() ) {
							// 			if ( item.token.tokenId < prev.token.tokenId ) { return -1 }
							// 			if ( item.token.tokenId > prev.token.tokenId ) { return  1 }
							// 		}
							// 		const itemTokenIdNumber = new BigNumber(item.token.tokenId);
							// 		const prevTokenIdNumber = new BigNumber(prev.token.tokenId);

							// 		if ( itemTokenIdNumber.lt(prevTokenIdNumber) ) { return -1 }
							// 		if ( itemTokenIdNumber.gt(prevTokenIdNumber) ) { return  1 }
							// 	} catch ( ignored ) {
							// 		if ( item.token.tokenId < prev.token.tokenId ) { return -1 }
							// 		if ( item.token.tokenId > prev.token.tokenId ) { return  1 }
							// 	}

							// 	return 0
							// })
							.map((item: { token: WrappedTokenType, msg: string }) => { return this.getTokenBlock(item.token, 'waiting', item.msg) })
					}
					{/* {
						this.state.incompleteTokens
							// .sort((item, prev) => {

							// 	if ( item.contractAddress < prev.contractAddress ) { return -1 }
							// 	if ( item.contractAddress > prev.contractAddress ) { return  1 }

							// 	try {
							// 		if ( new BigNumber(item.tokenId).isNaN() || new BigNumber(prev.tokenId).isNaN() ) {
							// 			if ( item.tokenId < prev.tokenId ) { return -1 }
							// 			if ( item.tokenId > prev.tokenId ) { return  1 }
							// 		}
							// 		const itemTokenIdNumber = new BigNumber(item.tokenId);
							// 		const prevTokenIdNumber = new BigNumber(prev.tokenId);

							// 		if ( itemTokenIdNumber.lt(prevTokenIdNumber) ) { return -1 }
							// 		if ( itemTokenIdNumber.gt(prevTokenIdNumber) ) { return  1 }
							// 	} catch ( ignored ) {
							// 		if ( item.tokenId < prev.tokenId ) { return -1 }
							// 		if ( item.tokenId > prev.tokenId ) { return  1 }
							// 	}

							// 	return 0
							// })
							.map((item: WrappedTokenType) => { return this.getTokenBlock(item, 'incomplete', undefined) })
					} */}
				</div>
			</div>
			</div>
			</React.Fragment>
		)
	}
	getYourTokenPagination() {
		if ( this.state.wrappedPages < 2 ) { return null; }
		return (
			<div className="pagination">
				<ul>
					<li>
						<button
							className={`arrow ${ this.state.wrappedCurrentPage === 0 ? 'disabled' : '' }`}
							onClick={(e) => {
								e.preventDefault();
								if ( this.state.wrappedCurrentPage === 0 ) { return; }
								this.setState({ wrappedCurrentPage: this.state.wrappedCurrentPage - 1 })
							}}
						>
							<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M24.0684 10.3225C24.504 10.7547 24.5067 11.4582 24.0744 11.8938L15.9623 20.0679L23.9397 28.1062C24.372 28.5418 24.3693 29.2453 23.9338 29.6775C23.4982 30.1098 22.7947 30.1071 22.3624 29.6716L13.6082 20.8505C13.1783 20.4173 13.1783 19.7184 13.6082 19.2852L22.4971 10.3284C22.9294 9.89287 23.6329 9.89019 24.0684 10.3225Z" fill="white"></path>
							</svg>
						</button>
					</li>
					{
						Array.from({ length: this.state.wrappedPages }).map((item, idx) => {
							return (
								<li key={ idx }>
									<button
										className={ this.state.wrappedCurrentPage === idx ? 'active' : ''}
										onClick={(e) => { e.preventDefault(); this.setState({ wrappedCurrentPage: idx }) }}
									>
										{ idx + 1 }
									</button>
								</li>
							)
						})
					}
					<li>
						<button
							className={`arrow ${ this.state.wrappedCurrentPage === (this.state.wrappedPages - 1) ? 'disabled' : '' }`}
							onClick={(e) => {
								e.preventDefault();
								if ( this.state.wrappedCurrentPage === (this.state.wrappedPages - 1) ) { return; }
								this.setState({ wrappedCurrentPage: this.state.wrappedCurrentPage + 1 })
							}}
						>
							<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M15.9316 29.6775C15.496 29.2453 15.4933 28.5418 15.9256 28.1062L24.0377 19.9321L16.0603 11.8938C15.628 11.4582 15.6307 10.7547 16.0662 10.3225C16.5018 9.89019 17.2053 9.89287 17.6376 10.3284L26.3918 19.1495C26.8217 19.5827 26.8217 20.2816 26.3918 20.7148L17.5029 29.6716C17.0706 30.1071 16.3671 30.1098 15.9316 29.6775Z" fill="white"></path>
							</svg>
						</button>
					</li>
				</ul>
			</div>
		)
	}
	getYourTokensFooter() {
		if ( this.state.loadingInProgress ) {
			return (
				<div className="lp-list__footer">
					<img className="loading" src={ icon_loading } alt="" />
				</div>
			)
		}

		if ( !this.state.wrappedTokens.length ) {
			return (
				<div className="lp-list__footer">
					<div className="nomore">There's no more wNFT yet</div>
				</div>
			)
		}
	}
	getYourTokensBody() {
		return (
			<React.Fragment>
				<div className="c-row" ref={ this.scrollToBlock }>
					{
						this.state.wrappedTokens
							.sort((item, prev) => {
								if (
									item.contractAddress.toLowerCase() === this.metamaskAdapter.chainConfig.currentWrapperContract.address.toLowerCase() &&
									prev.contractAddress.toLowerCase() !== this.metamaskAdapter.chainConfig.currentWrapperContract.address.toLowerCase()
								) {
									return -1
								}
								if (
									prev.contractAddress.toLowerCase() === this.metamaskAdapter.chainConfig.currentWrapperContract.address.toLowerCase() &&
									item.contractAddress.toLowerCase() !== this.metamaskAdapter.chainConfig.currentWrapperContract.address.toLowerCase()
								) {
									return 1
								}
								if ( item.contractAddress.toLowerCase() < prev.contractAddress.toLowerCase() ) { return -1 }
								if ( item.contractAddress.toLowerCase() > prev.contractAddress.toLowerCase() ) { return  1 }

								try {
									if ( new BigNumber(item.tokenId).isNaN() || new BigNumber(prev.tokenId).isNaN() ) {
										if ( item.tokenId < prev.tokenId ) { return  1 }
										if ( item.tokenId > prev.tokenId ) { return -1 }
									}
									const itemTokenIdNumber = new BigNumber(item.tokenId);
									const prevTokenIdNumber = new BigNumber(prev.tokenId);

									if ( itemTokenIdNumber.lt(prevTokenIdNumber) ) { return  1 }
									if ( itemTokenIdNumber.gt(prevTokenIdNumber) ) { return -1 }
								} catch ( ignored ) {
									if ( item.tokenId < prev.tokenId ) { return  1 }
									if ( item.tokenId > prev.tokenId ) { return -1 }
								}

								return 0
							})
							.slice(
								(this.state.wrappedCurrentPage * this.tokensOnPage),
								(this.state.wrappedCurrentPage * this.tokensOnPage) + this.tokensOnPage
							)
							.map((item: WrappedTokenType) => { return this.getTokenBlock(item, 'wrapped', undefined) })
					}
				</div>
				{ this.getYourTokenPagination() }
			</React.Fragment>
		)
	}
	getYourTokensList() {

		return (
			<React.Fragment>
			<div className="db-section">
			<div className="divider right short"></div>
			<div className="container">
				<div className="c-header">
					<div className="h3">{ this.t('Your wNFTs') }</div>
				</div>
				{ this.getYourTokensBody() }
				{ this.getYourTokensFooter() }
			</div>
			</div>
			</React.Fragment>
		)
	}
	getDiscoveredTokenPagination() {
		if ( this.state.discoveredPages < 2 ) { return null; }
		return (
			<div className="pagination">
				<ul>
					<li>
						<button
							className={`arrow ${ this.state.discoveredCurrentPage === 0 ? 'disabled' : '' }`}
							onClick={(e) => {
								e.preventDefault();
								if ( this.state.discoveredCurrentPage === 0 ) { return; }
								this.setState({ discoveredCurrentPage: this.state.discoveredCurrentPage - 1 })
							}}
						>
							<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M24.0684 10.3225C24.504 10.7547 24.5067 11.4582 24.0744 11.8938L15.9623 20.0679L23.9397 28.1062C24.372 28.5418 24.3693 29.2453 23.9338 29.6775C23.4982 30.1098 22.7947 30.1071 22.3624 29.6716L13.6082 20.8505C13.1783 20.4173 13.1783 19.7184 13.6082 19.2852L22.4971 10.3284C22.9294 9.89287 23.6329 9.89019 24.0684 10.3225Z" fill="white"></path>
							</svg>
						</button>
					</li>
					{
						Array.from({ length: this.state.discoveredPages }).map((item, idx) => {
							return (
								<li key={ idx }>
									<button
										className={ this.state.discoveredCurrentPage === idx ? 'active' : ''}
										onClick={(e) => { e.preventDefault(); this.setState({ discoveredCurrentPage: idx }) }}
									>
										{ idx + 1 }
									</button>
								</li>
							)
						})
					}
					<li>
						<button
							className={`arrow ${ this.state.discoveredCurrentPage === (this.state.discoveredPages - 1) ? 'disabled' : '' }`}
							onClick={(e) => {
								e.preventDefault();
								if ( this.state.discoveredCurrentPage === (this.state.discoveredPages - 1) ) { return; }
								this.setState({ discoveredCurrentPage: this.state.discoveredCurrentPage + 1 })
							}}
						>
							<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M15.9316 29.6775C15.496 29.2453 15.4933 28.5418 15.9256 28.1062L24.0377 19.9321L16.0603 11.8938C15.628 11.4582 15.6307 10.7547 16.0662 10.3225C16.5018 9.89019 17.2053 9.89287 17.6376 10.3284L26.3918 19.1495C26.8217 19.5827 26.8217 20.2816 26.3918 20.7148L17.5029 29.6716C17.0706 30.1071 16.3671 30.1098 15.9316 29.6775Z" fill="white"></path>
							</svg>
						</button>
					</li>
				</ul>
			</div>
		)
	}
	getDiscoveredTokensList() {
		if ( !this.state.discoveredTokens.length ) { return null }
		return (
			<React.Fragment>
			<div className="db-section">
			<div className="divider right short-20"></div>
			<div className="container">
				<div className="c-header">
					<div className="h3">{ this.t('Your discovered NFTs') }</div>
				</div>

				<div className="c-row">
					{
						this.state.discoveredTokens
							// sort by contractaddress and tokenid
							// .sort((item, prev) => {

							// 	if ( item.contractAddress.toLowerCase() < prev.contractAddress.toLowerCase() ) { return -1 }
							// 	if ( item.contractAddress.toLowerCase() > prev.contractAddress.toLowerCase() ) { return  1 }

							// 	try {
							// 		if ( new BigNumber(item.tokenId).isNaN() || new BigNumber(prev.tokenId).isNaN() ) {
							// 			if ( item.tokenId < prev.tokenId ) { return -1 }
							// 			if ( item.tokenId > prev.tokenId ) { return  1 }
							// 		}
							// 		const itemTokenIdNumber = new BigNumber(item.tokenId);
							// 		const prevTokenIdNumber = new BigNumber(prev.tokenId);

							// 		if ( itemTokenIdNumber.lt(prevTokenIdNumber) ) { return -1 }
							// 		if ( itemTokenIdNumber.gt(prevTokenIdNumber) ) { return  1 }
							// 	} catch ( ignored ) {
							// 		if ( item.tokenId < prev.tokenId ) { return -1 }
							// 		if ( item.tokenId > prev.tokenId ) { return  1 }
							// 	}

							// 	return 0
							// })
							// sort by blocknumber and logindex
							.sort((item, prev) => {

								if ( !item.sortParams.blockNumber || !item.sortParams.logIndex ) { return  1 }
								if ( !prev.sortParams.blockNumber || !prev.sortParams.logIndex ) { return -1 }

								if ( item.sortParams.blockNumber.lt(prev.sortParams.blockNumber) ) { return  1 }
								if ( item.sortParams.blockNumber.gt(prev.sortParams.blockNumber) ) { return -1 }

								if ( item.sortParams.logIndex.lt(prev.sortParams.logIndex) ) { return  1 }
								if ( item.sortParams.logIndex.gt(prev.sortParams.logIndex) ) { return -1 }

								return 0
							})
							.slice(
								(this.state.discoveredCurrentPage * this.tokensOnPage),
								(this.state.discoveredCurrentPage * this.tokensOnPage) + this.tokensOnPage
							)
							.map((item: WrappedTokenType) => { return this.getTokenBlock(item, 'discovered', undefined) })
					}
				</div>
				{ this.getDiscoveredTokenPagination() }
			</div>
			</div>
			</React.Fragment>
		)
	}

	render() {
		return (
			<React.Fragment>
			<main className="s-main">
				<div className="container">

					{ this.getWrapBlock() }
					{ this.getStatsBlock() }

				</div>

				{ this.getInProgressTokensList() }
				{ this.getYourTokensList() }
				{ this.getDiscoveredTokensList() }

			</main>
			{
				this.state.addValueToken ?
					<AddValuePopup
						store={ this.store }
						metamaskAdapter={ this.metamaskAdapter }
						token={ this.state.addValueToken }
						closePopup={()=>{ this.setState({ addValueToken: undefined }) }}
					/> : null
			}
			{
				this.state.transferToken ?
					<TransferPopup
						store={ this.store }
						metamaskAdapter={ this.metamaskAdapter }
						token={ this.state.transferToken }
						closePopup={()=>{ this.setState({ transferToken: undefined }) }}
						history={ this.props.history }
					/> : null
			}{
				this.state.approveToken ?
					<ApprovePopup
						store={ this.store }
						metamaskAdapter={ this.metamaskAdapter }
						token={ this.state.approveToken }
						closePopup={()=>{ this.setState({ approveToken: undefined }) }}
						history={ this.props.history }
					/> : null
			}
			</React.Fragment>
		)
	}
}

export default withTranslation("translations")(withRouter(NFTList));