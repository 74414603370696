
import React from 'react';
import Tippy from '@tippyjs/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
	withRouter,
	match
} from 'react-router-dom';
import {
	History,
	Location
} from 'history';
import {
	WrappedTokenType,
	ERC20ContractParamsType,
	MetamaskAdapter,
	getERC721Token,
} from '../../models/BlockchainAdapter';
import { isFirefox } from 'react-device-detect';

import {
	clearError,
	gotoList,
	requestChain,
	setError,
	setLoading,
	tokenPreviewClear,
	tokenUpdate,
	unsetLoading,
	waitingTokensAdd,
	waitingTokensRemove,
} from '../../reducers';
import {
	addThousandSeparator,
	compactString,
	monthesNames,
	removeThousandSeparator,
	tokenToFloat,
	tokenToInt,
	unixtimeToStr
} from '../../models/_utils';

import AddValuePopup from '../AddValuePopup';
import TransferPopup from '../TransferPopup';

import icon_logo     from '../../static/pics/header-logo.svg';
import icon_i_copy   from '../../static/pics/i-copy.svg';
import icon_i_action from '../../static/pics/i-action.svg';
import icon_i_plus   from '../../static/pics/i-plus.svg';
import icon_i_link   from '../../static/pics/i-link.svg';
import default_icon  from '../../static/pics/coins/_default.png';

import { withTranslation, Trans } from "react-i18next";

// @ts-ignore
import queryString from 'query-string';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type NFTPreviewProps = {
	store                 : any,
	metamaskAdapter       : MetamaskAdapter,
	showAuthMethodSelector: Function;
	t                     : any,
	match                 : match;
	location              : Location,
	history               : History,
}
type NFTPreviewState = {
	chainId                 : number,
	userAddress             : string,
	balanceNative           : BigNumber,
	decimalsNative          : number,
	iconNative              : string,
	symbolNative            : string,
	decimalsToken           : number,
	symbolToken             : string,
	iconToken               : string,
	token                   : WrappedTokenType | undefined,
	isWrapped               : boolean,
	advancedOpened          : boolean,
	input_collateral        : string,
	input_fees              : string,
	input_fees_erc20token   : ERC20ContractParamsType | undefined,
	input_royalties         : string,
	input_royaltiesRec      : string,
	input_unlock_fee        : string,
	input_unlock_time       : string,
	input_unlock_time_pretty: string,
	fees_checked            : boolean,
	unlock_fee_checked      : boolean,
	unlock_time_checked     : boolean,
	explorerBaseUrl         : string,
	marketplaceUrl          : string,

	techToken            : ERC20ContractParamsType,
	erc20CollateralTokens: Array<ERC20ContractParamsType>,
	transferAllowances   : Array<{ wrapperAddress: string, transferModelAddress: string, erc20TokenAddress: string, allowance: BigNumber }>,

	addValueToken: boolean,
	transferToken: boolean,
	checkoutApproving: boolean,

	copiedHint: undefined | { id: string, icon: string },
}

class NFTPreview extends React.Component<NFTPreviewProps, NFTPreviewState> {

	store                 : any;
	metamaskAdapter       : MetamaskAdapter;
	showAuthMethodSelector: Function;
	unsubscribe!          : Function;
	unlisten!             : Function;
	focusable             : any;
	t                     : any;

	contractAddress       : string;
	tokenId               : string;
	requestedChain        : number | undefined;
	metamaskChainRequested: boolean;
	tokenFetchRequested   : boolean;

	copiedHintTimer       : number;
	copiedHintTimeout     : number;

	constructor(props: NFTPreviewProps) {
		super(props);

		this.store                  = props.store;
		this.metamaskAdapter        = props.metamaskAdapter;
		this.t                      = props.t;
		this.showAuthMethodSelector = props.showAuthMethodSelector;
		this.tokenFetchRequested    = false;
		this.contractAddress        = '';
		this.tokenId                = '';
		this.requestedChain         = undefined;
		this.metamaskChainRequested = false;

		this.focusable = {};

		this.copiedHintTimer        = 0;
		this.copiedHintTimeout      = 2; // s

		const token = this.store.getState().tokenPreview;
		const isWrapped = !!token &&
			(token.contractAddress.toLowerCase() === this.metamaskAdapter.wrapperContract.contractAddress.toLowerCase() ||
			!!this.metamaskAdapter.wrapperContract.previousContracts.filter((item) => { return token && item.address.toLowerCase() === token.contractAddress.toLowerCase() }).length);

		let erc20CollateralTokensFiltered = [];
		if ( this.metamaskAdapter.wrapperContract && token ) {
			erc20CollateralTokensFiltered = this.store.getState().erc20CollateralTokens.filter((item: ERC20ContractParamsType) => {
				if ( !item.address ) { return false; }
				return this.metamaskAdapter.wrapperContract.wrapperAllowedERC20
					.map((item) => { return item.toLowerCase() })
					.includes( item.address.toLowerCase() );
			});
		}

		this.state = {
			chainId                 : this.store.getState().metamaskAdapter.chainId,
			userAddress             : this.store.getState().account.address,
			balanceNative           : this.store.getState().account.balanceNative,
			decimalsNative          : this.store.getState().metamaskAdapter.networkTokenDecimals,
			symbolNative            : this.store.getState().metamaskAdapter.networkTokenTicket,
			iconNative              : this.store.getState().metamaskAdapter.networkTokenIcon,
			decimalsToken           : this.store.getState().erc20TechTokenParams.decimals,
			symbolToken             : this.store.getState().erc20TechTokenParams.symbol,
			iconToken               : this.store.getState().erc20TechTokenParams.icon,
			token                   : token,
			isWrapped               : isWrapped,
			advancedOpened          : false,
			input_collateral        : '',
			input_fees              : '',
			input_fees_erc20token   : undefined,
			input_royalties         : '',
			input_royaltiesRec      : '',
			input_unlock_fee        : '',
			input_unlock_time       : '',
			input_unlock_time_pretty: '',
			fees_checked            : false,
			unlock_fee_checked      : false,
			unlock_time_checked     : false,
			explorerBaseUrl         : '',
			marketplaceUrl          : this.store.getState().metamaskAdapter.marketplaceUrl,

			techToken            : this.store.getState().erc20TechTokenParams,
			erc20CollateralTokens: erc20CollateralTokensFiltered,
			transferAllowances   : this.store.getState().transferModelAllowances,

			addValueToken: false,
			transferToken: false,
			checkoutApproving: false,

			copiedHint: undefined,
		}

		const urlParams = queryString.parse(this.props.location.search);
		if (!urlParams.contractAddress || !urlParams.tokenId || !urlParams.chain) {
			setTimeout(
				() => {
					this.store.dispatch(setError({
						text: 'Wrong url format',
						buttons: [{
							text: 'Ok',
							clickFunc: () => {
								this.props.history.push('/list');
								this.store.dispatch(clearError());
							}
						}],
						links: undefined
					}))
				},
			100);
			return;
		}
		this.contractAddress = `${urlParams.contractAddress}`;
		this.tokenId         = `${urlParams.tokenId}`;

		if ( this.store.getState().metamaskAdapter.logged && this.metamaskAdapter.wrapperContract ) {
			if ( this.store.getState().metamaskAdapter.requestChainId && this.store.getState().metamaskAdapter.requestChainId !== this.store.getState().metamaskAdapter.chainId ) {
				this.store.dispatch(setError({
					text: this.t(`You are trying to open chain which does not match one selected in metamask`),
					buttons: [
						{
							text: this.t('Cancel'),
							clickFunc: async () => {
								window.location.href = '/#/list';
								this.store.dispatch(requestChain( this.store.getState().metamaskAdapter.chainId ));
								this.store.dispatch(clearError());
							}
						},
						{
							text: this.t('Switch network'),
							clickFunc: () => {
								(window as any).ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: '0x' + Number(this.store.getState().metamaskAdapter.requestChainId).toString(16) }], // chainId must be in hexadecimal numbers
								})
							}
						},
					],
					links: undefined
				}));
			} else {
				this.getTokenSubmit();
			}
		} else {
			if ( !localStorage.getItem('provider_type') ) {
				this.showAuthMethodSelector( false );
				this.tokenFetchRequested = true;
				return;
			} else {
				this.tokenFetchRequested = true;
			}
		}
	}

	componentDidMount() {
		this.unsubscribe = this.store.subscribe(() => {

			if (
				this.tokenFetchRequested &&
				!this.state.token &&
				this.store.getState().metamaskAdapter.logged &&
				this.metamaskAdapter.wrapperContract &&
				this.metamaskAdapter.wrapperContract.previousContractsLoaded &&
				this.store.getState().metamaskAdapter.requestChainId && this.store.getState().metamaskAdapter.requestChainId === this.store.getState().metamaskAdapter.chainId
			) {
				this.tokenFetchRequested = false;
				this.getTokenSubmit();
			}

			const token = this.store.getState().tokenPreview;
			const isWrapped = !!token &&
				(token.contractAddress.toLowerCase() === this.metamaskAdapter.wrapperContract.contractAddress.toLowerCase() ||
				!!this.metamaskAdapter.wrapperContract.previousContracts.filter((item) => { return token && item.address.toLowerCase() === token.contractAddress.toLowerCase() }).length);

			let erc20CollateralTokensFiltered = [];
			if ( this.metamaskAdapter.wrapperContract && token ) {
				erc20CollateralTokensFiltered = this.store.getState().erc20CollateralTokens.filter((item: ERC20ContractParamsType) => {
					return true;
					// if ( !item.address ) { return false; }
					// return this.metamaskAdapter.wrapperContract.wrapperAllowedERC20
					// 	.map((item) => { return item.toLowerCase() })
					// 	.includes( item.address.toLowerCase() );
				});
			}

			this.setState({
				chainId        : this.store.getState().metamaskAdapter.chainId,
				userAddress    : this.store.getState().account.address,

				token          : token,
				isWrapped      : isWrapped,
				balanceNative  : this.store.getState().account.balanceNative,
				decimalsNative : this.store.getState().metamaskAdapter.networkTokenDecimals,
				symbolNative   : this.store.getState().metamaskAdapter.networkTokenTicket,
				iconNative     : this.store.getState().metamaskAdapter.networkTokenIcon,
				decimalsToken  : this.store.getState().erc20TechTokenParams.decimals,
				symbolToken    : this.store.getState().erc20TechTokenParams.symbol,
				iconToken      : this.store.getState().erc20TechTokenParams.icon,
				explorerBaseUrl: this.store.getState().metamaskAdapter.explorerBaseUrl,
				marketplaceUrl : this.store.getState().metamaskAdapter.marketplaceUrl,

				techToken            : this.store.getState().erc20TechTokenParams,
				erc20CollateralTokens: erc20CollateralTokensFiltered,
				transferAllowances   : this.store.getState().transferModelAllowances,
			});
		});
		this.unlisten = this.props.history.listen((location) => {
			if ( location.pathname.toLowerCase() !== '/token' ) { return; }

			const urlParams = queryString.parse(this.props.location.search);
			if (!urlParams.contractAddress || !urlParams.tokenId || !urlParams.chain) {
				setTimeout(
					() => {
						this.store.dispatch(setError({
							text: this.t('Wrong url format'),
							buttons: [{
								text: 'Ok',
								clickFunc: () => {
									this.props.history.push('/list');
									this.store.dispatch(clearError());
								}
							}],
							links: undefined
						}))
					},
				100);
				return;
			}
			this.contractAddress = `${urlParams.contractAddress}`;
			this.tokenId         = `${urlParams.tokenId}`;

			if ( this.store.getState().metamaskAdapter.requestChainId && this.store.getState().metamaskAdapter.requestChainId !== this.store.getState().metamaskAdapter.chainId ) {
				this.store.dispatch(setError({
					text: this.t(`You are trying to open chain which does not match one selected in metamask`),
					buttons: [
						{
							text: this.t('Cancel'),
							clickFunc: async () => {
								window.location.href = '/#/list';
								this.store.dispatch(requestChain( this.store.getState().metamaskAdapter.chainId ));
								this.store.dispatch(clearError());
							}
						},
						{
							text: this.t('Switch network'),
							clickFunc: () => {
								(window as any).ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: '0x' + Number(this.store.getState().metamaskAdapter.requestChainId).toString(16) }], // chainId must be in hexadecimal numbers
								})
							}
						},
					],
					links: undefined
				}));
			} else {
				this.getTokenSubmit();
			}
		});
 	}
	componentWillUnmount() {
		this.unsubscribe();
		this.unlisten();
		this.store.dispatch(tokenPreviewClear());
	}

	async getTokenSubmit() {
		this.store.dispatch(setLoading({ msg: this.t('Loading NFT-token') }));

		let token: WrappedTokenType;
		// try to get token from wrappedtokens DOES NOT WORK
		// const foundToken = this.store.getState().wrappedTokens.filter((item: WrappedTokenType) => { return item.contract.toLowerCase() this.contractAddress.toLowerCase() && item.tokenId === this.tokenId })
		// if ( foundToken.length ) {
		// 	token = foundToken[0];
		// 	this.store.dispatch(tokenUpdate( token ));
		// 	this.store.dispatch(unsetLoading());
		// } else {

			try {
				if (
					this.contractAddress.toLowerCase() === this.metamaskAdapter.wrapperContract.contractAddress.toLowerCase() ||
					!!this.metamaskAdapter.wrapperContract.previousContracts.filter((item) => { return item.address.toLowerCase() === this.contractAddress.toLowerCase() }).length
				) {
					let contract;
					if ( this.contractAddress.toLowerCase() === this.metamaskAdapter.wrapperContract.contractAddress.toLowerCase() ) {
						contract = this.metamaskAdapter.wrapperContract.contract;
					} else {
						const foundContract = this.metamaskAdapter.wrapperContract.previousContracts.filter((item) => { return item.address.toLowerCase() === this.contractAddress.toLowerCase() })
						if ( foundContract.length ) {
							contract = foundContract[0].contract
						}
					}

					if ( contract ) {
						token = await this.metamaskAdapter.wrapperContract.getNFTTokenById(
							this.contractAddress,
							contract,
							this.tokenId,
							this.metamaskAdapter.chainId || 0,
						);
					} else {
						throw(new Error(`${this.t('Cannot find wrapper contract')}: ${this.contractAddress}`))
					}
				} else {
					token = await getERC721Token(
						this.metamaskAdapter,
						this.contractAddress,
						this.tokenId,
						this.store.getState().account.address,
						this.t,
						{ blockNumber: undefined, logIndex: undefined }
					)
				}
			} catch(e: any) {
				console.log(e);
				this.store.dispatch(setError({
					text: e.message,
					buttons: [{
						text: 'Ok',
						clickFunc: () => {
							this.props.history.push('/list');
							this.store.dispatch(clearError());
						}
					}],
					links: undefined
				}))
				this.store.dispatch(unsetLoading());
				return;
			}

			if ( !token ) {
				this.store.dispatch(setError({
					text: this.t('Cannot connect to contract'),
					buttons: [{
						text: 'Ok',
						clickFunc: () => {
							this.props.history.push('/list');
							this.store.dispatch(clearError());
						}
					}],
					links: undefined
				}));
				this.store.dispatch(unsetLoading());
				return;
			}

			if (
				token.contractAddress.toLowerCase() !== this.metamaskAdapter.wrapperContract.contractAddress.toLowerCase() &&
				!this.metamaskAdapter.wrapperContract.previousContracts.filter((item) => { return token && item.address.toLowerCase() === token.contractAddress.toLowerCase() }).length
			) {
				// this.store.dispatch(incompleteTokensAdd( token ));
				// saveERC721Token( token );
			}
			if ( token ) { this.store.dispatch(tokenUpdate( token )); }
			this.store.dispatch(unsetLoading());

		// }
	}
	unwrapToken() {

		if ( !this.state.token ) { return; }
		this.metamaskAdapter.wrapperContract.unwrapToken(this.state.token);
		this.store.dispatch(gotoList());
		this.props.history.push('/list');

	}
	wrapToken() {

		if ( !this.state.token ) { return; }

		const collateral  = this.state.input_collateral
			? tokenToInt(new BigNumber(this.state.input_collateral), this.state.decimalsNative)
			: new BigNumber(0);
		const fee         = this.state.input_fees && this.state.fees_checked
			? tokenToInt(new BigNumber(this.state.input_fees), this.state.decimalsNative)
			: new BigNumber(0);
		const feeToken = this.state.input_fees_erc20token ? this.state.input_fees_erc20token.address : '0x0000000000000000000000000000000000000000';
		const unlockFee  = this.state.input_unlock_fee && this.state.unlock_fee_checked
			? tokenToInt(new BigNumber(this.state.input_unlock_fee), this.state.decimalsToken)
			: new BigNumber(0);
		const royalties   = this.state.input_royalties
			? new BigNumber(this.state.input_royalties)
			: new BigNumber(0);
		const royaltiesRec = this.state.input_royalties
			? this.state.input_royaltiesRec ? this.state.input_royaltiesRec : this.state.userAddress
			: '0x0000000000000000000000000000000000000000'

		const daysToAdd = parseInt(this.state.input_unlock_time) || 0;
		const unlockTime = this.state.unlock_time_checked
			? new BigNumber(parseInt(`${this.addDays(daysToAdd).getTime() / 1000}`))
			: new BigNumber(0);

		this.metamaskAdapter.wrapperContract.wrapToken({
			token: this.state.token,
			collateral: collateral,
			unwrapAfter: unlockTime,
			transferFeeToken: feeToken,
			transferFee: fee,
			royaltyPercent: royalties,
			royaltyRec: royaltiesRec,
			unwrapFee: unlockFee,
		})
		this.store.dispatch(gotoList());
		this.props.history.push('/list');
	}

	// ----- CHECKS -----
	checkBalance() {
		if ( this.state.input_collateral === '' ) {
			return (<span className="field-unit">
				<span className="i-coin"><img src={ this.state.iconNative } alt="" /></span>
				{ this.state.symbolNative }
			</span>)
		}
		const balance = this.state.balanceNative;
		const inputValue = new BigNumber(this.state.input_collateral);
		const decimals = this.state.decimalsNative;
		const parsedInput = tokenToInt(inputValue, decimals);
		if ( balance.gt(parsedInput) ) {
			return (<span className="field-unit">
				<span className="i-coin"><img src={ this.state.iconNative } alt="" /></span>
				{ this.state.symbolNative }
			</span>)
		} else {
			return (<span className="field-error">
				<Trans i18nKey="DONT HAVE" components={[<br />]} />
				{ ' ' }
				{ this.state.symbolNative }
			</span>)
		}
	}
	checkUnlockFees() {

		if ( !this.state.unlock_fee_checked ) { return '' }

		let fee;
		if ( !this.state.fees_checked ) {
			fee = new BigNumber(0);
		} else {
			fee = new BigNumber(this.state.input_fees).isNaN() ? new BigNumber(0) : new BigNumber(this.state.input_fees);
		}
		let unlock_fee;
		if ( !this.state.unlock_fee_checked ) {
			unlock_fee = new BigNumber(0);
		} else {
			unlock_fee = new BigNumber(this.state.input_unlock_fee).isNaN() ? new BigNumber(0) : new BigNumber(this.state.input_unlock_fee);
		}

		if ( unlock_fee.gt(0) && !fee.gt(0) ) {
			return (<span className="field-error">
				<Trans i18nKey="ENTER FEE" components={[<br />]} />
			</span>)
		} else {
			return (
				<span className="field-unit">
					<span className="i-coin"><img src={ this.state.input_fees_erc20token ? this.state.input_fees_erc20token.icon : this.state.iconToken } alt="" /></span>
					{ this.state.input_fees_erc20token ? this.state.input_fees_erc20token.symbol : 'techNIFTSY' }
				</span>
			)
		}
	}
	checkRoyalty() {
		let fee;
		if ( !this.state.fees_checked ) {
			fee = new BigNumber(0);
		} else {
			fee = new BigNumber(this.state.input_fees).isNaN() ? new BigNumber(0) : new BigNumber(this.state.input_fees);
		}
		const royalty = new BigNumber(this.state.input_royalties).isNaN() ? new BigNumber(0) : new BigNumber(this.state.input_royalties);
		if ( royalty.gt(0) && !fee.gt(0) ) {
			return (<span className="field-error">
				<Trans i18nKey="ENTER FEE" components={[<br />]} />
			</span>)
		} else {
			return (<span className="field-unit">%</span>)
		}
	}
	checkRoyaltyRec() {
		if ( !this.state.input_royaltiesRec ) { return ''; }

		if ( !this.state.input_royalties ) {
			return (<span className="field-error">
				<Trans i18nKey="ENTER ROYALTY" components={[<br />]} />
			</span>)
		}

		if ( this.metamaskAdapter && this.metamaskAdapter.web3 ) {
			if ( !this.metamaskAdapter.web3.utils.isAddress(this.state.input_royaltiesRec) ) {
				return (<span className="field-error">
					<Trans i18nKey="Wrong format" components={[<br />]} />
				</span>)
			} else {
				return '';
			}
		} else {
			if ( this.state.input_royaltiesRec.length !== 42 || !this.state.input_royaltiesRec.startsWith('0x') ) {
				return (<span className="field-error">
					<Trans i18nKey="Wrong format" components={[<br />]} />
				</span>)
			} else {
				return '';
			}
		}
	}
	isSubmitDisabled() {
		let fee;
		if ( !this.state.fees_checked ) {
			fee = new BigNumber(0);
		} else {
			fee = new BigNumber(this.state.input_fees).isNaN() ? new BigNumber(0) : new BigNumber(this.state.input_fees);
		}
		let unlock_fee;
		if ( !this.state.unlock_fee_checked ) {
			unlock_fee = new BigNumber(0);
		} else {
			unlock_fee = new BigNumber(this.state.input_unlock_fee).isNaN() ? new BigNumber(0) : new BigNumber(this.state.input_unlock_fee);
		}
		const royalty = new BigNumber(this.state.input_royalties).isNaN() ? new BigNumber(0) : new BigNumber(this.state.input_royalties);

		if ( this.state.input_royaltiesRec ) {
			if ( !this.state.input_royalties ) { return true; }

			if ( this.metamaskAdapter && this.metamaskAdapter.web3 ) {
				if ( !this.metamaskAdapter.web3.utils.isAddress(this.state.input_royaltiesRec) ) { return true; }
			} else {
				if ( this.state.input_royaltiesRec.length !== 42 || !this.state.input_royaltiesRec.startsWith('0x') ) { return true }
			}
		}

		if ( Number(this.state.input_unlock_time) > 365 ) { return true; }

		if ( royalty.gt(0) && !fee.gt(0) ) { return true; }
		if ( unlock_fee.gt(0) && !fee.gt(0) ) { return true; }

		const balance = this.state.balanceNative;
		const inputValue = new BigNumber(this.state.input_collateral);
		const decimals = this.state.decimalsNative;
		const parsedInput = tokenToInt(inputValue, decimals);
		if ( !(this.state.input_collateral === '') && !balance.gt(parsedInput) ) { return true; }

		return false;
	}
	addDays(num: number) {
		const output = new Date();
		output.setDate(output.getDate() + num);
		return output;
	}
	addedDate() {
		const daysToAdd = parseInt(this.state.input_unlock_time) || 0;

		if (daysToAdd > 365) { return this.t('365 days max') }

		const output = this.addDays(daysToAdd);
		return `${output.getDate()} ${monthesNames[output.getMonth()]} ${output.getFullYear()}`;
	}
	// ----- END CHECKS -----

	// ----- WRAPPED -----
	getWrappedCollateralBlock() {
		if ( !this.state.token ) { return; }

		let bakedERC20Values = undefined;
		if ( this.state.token.backedERC20Value ) {
			bakedERC20Values = this.state.token.backedERC20Value.map((item) => {
				const foundToken = this.state.erc20CollateralTokens.filter((iitem) => {
					if ( !item.address || !iitem.address ) { return false }
					return item.address.toLowerCase() === iitem.address.toLowerCase()
				});
				if ( foundToken.length ) {
					return {
						amount: item.amount,
						...foundToken[0]
					}
				} else {
					return {
						amount: item.amount,
						address: item.address,
						symbol: item.address,
						icon: default_icon,
						decimals: 18
					}
				}
			});
		}

		let assetsQty = this.state.token.backedERC20Value.length;
		if ( this.state.token.backedValue && !this.state.token.backedValue.eq(0) ) { assetsQty = assetsQty + 1 }

		return (
			<div className="field-wrap">
				<div className="field-row">
					<label className="field-label">
						{ this.t('Collateral') }
						<Tippy
							content={ this.t('Assets with which your wrapped NFT is provided') }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 260 }

						>
							<span className="i-tip"></span>
						</Tippy>
					</label>
				</div>
				<div className="field-row">
					<div className="field-control field-collateral">
						{
							( this.state.token.backedValue && !this.state.token.backedValue.eq(0) ) || ( bakedERC20Values && bakedERC20Values.length ) ?
							(
								<div className="coins">
									{
										this.state.token.backedValue && !this.state.token.backedValue.eq(0) ?
										( <span className="i-coin"><img src={ this.state.iconNative } alt="" /></span> ) : null
									}
									{
										bakedERC20Values?.map((item) => { return ( <span key={ item.address } className="i-coin"><img src={ item.icon } alt="" /></span> ) })
									}
								</div>
							) : null
						}
						<div className="sum">
							{ assetsQty || '' }
							{ ' ' }
							{ assetsQty ? ( assetsQty === 1 ? this.t('asset') : this.t('assets') ) : this.t('no assets') }
						</div>
						{
							assetsQty ?
							( <div className="field-collateral__details">
								<div className="inner">
									<table>
										<tbody>
											{
												this.state.token.backedValue && !this.state.token.backedValue.eq(0) ?
												(
													<tr>
														<td><span className="unit-sum">
														{
															this.state.token.backedValue && this.state.decimalsNative
																? addThousandSeparator(tokenToFloat(this.state.token.backedValue, this.state.decimalsNative).toString())
																: '—'
														}
														</span></td>
														<td> <span className="field-unit"><span className="i-coin"><img src={ this.state.iconNative } alt="" /></span>{ this.state.symbolNative }</span></td>
													</tr>
												) : null
											}
											{
												bakedERC20Values?.map((item) => { return (
													<tr key={ item.address }>
														<td><span className="unit-sum">{ addThousandSeparator(tokenToFloat(item.amount, item.decimals || 0).toString()) }</span></td>
														<td><span className="field-unit">
															<span className="i-coin"><img src={ item.icon } alt="" /></span>
															<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${item.address}` }>{ item.symbol }</a>
														</span></td>
													</tr>
												) })
											}
										</tbody>
									</table>
								</div>
							</div> ) : null
						}
					</div>
					{
						this.metamaskAdapter.wrapperContract.canWrapper('addvalue', this.state.token.contractAddress) ?
						(
							<button
								className="btn-add"
								onClick={() => {
									this.setState({
										addValueToken: true,
									})
								}}
							>
								<img src={icon_i_plus} alt="" />
							</button>
						) : ''
					}
				</div>
			</div>
		)
	}
	getWrappedCollectedFeeBlock() {
		const feeToken = this.state.token && this.state.token.transferFeeToken && this.state.token.transferFeeToken !== '0x0000000000000000000000000000000000000000'
			? this.metamaskAdapter.getERC20Contract(this.state.token.transferFeeToken)?.erc20Params || { address: '0x0000000000000000000000000000000000000000', icon: default_icon, symbol: this.state.token.transferFeeToken, decimals: 18 }
			: undefined;

		if (
			this.state.token &&
			this.state.token.backedTokens &&
			!this.state.token.backedTokens.eq(0) &&
			addThousandSeparator(tokenToFloat(this.state.token.backedTokens, feeToken?.decimals || this.state.decimalsNative).toString()).length > 7
		) {
			return (
				<div className="field-wrap col-sm-6">
					<div className="field-row">
						<label className="field-label">
							<span>{ this.t('Collected Fees') }</span>
							<Tippy
								content={ this.t('Accumulated transfer fee on this wNFT balance') }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 260 }
							>
								<span className="i-tip"></span>
							</Tippy>
						</label>
						{/* <span className="field-data">$ 167.29    </span> */}
					</div>
					<Tippy
						content={ addThousandSeparator(tokenToFloat(this.state.token.backedTokens, feeToken?.decimals || this.state.decimalsNative).toString()) }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 260 }
					>
						<div className="field-row">
							<div className="field-control">
								<span>
								{
									this.state.token && this.state.token.backedTokens && !this.state.token.backedTokens.eq(0)
										? addThousandSeparator(tokenToFloat(this.state.token.backedTokens, feeToken?.decimals || this.state.decimalsNative).toString())
										: '—'
								}
								</span>
							</div>
							{
								this.state.token && this.state.token.backedTokens && !this.state.token.backedTokens.eq(0)
									? (<span className="field-unit">
										{ feeToken ? ( <span className="i-coin"><img src={ feeToken.icon } alt="" /></span> ) : ( <span className="i-coin"><img src={ this.state.iconNative } alt="" /></span> ) }
										{ feeToken ? (<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${feeToken.address}` }>{ feeToken.symbol }</a> ) : this.state.symbolNative }
									</span>)
									: ''
							}
						</div>
					</Tippy>
				</div>
			)
		} else {
			return (
				<div className="field-wrap col-sm-6">
					<div className="field-row">
						<label className="field-label">
							<span>{ this.t('Collected Fees') }</span>
							<Tippy
								content={ this.t('Accumulated transfer fee on this wNFT balance') }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 260 }
							>
								<span className="i-tip"></span>
							</Tippy>
						</label>
						{/* <span className="field-data">$ 167.29    </span> */}
					</div>
					<div className="field-row">
						<div className="field-control">
							<span>
							{
								this.state.token && this.state.token.backedTokens && !this.state.token.backedTokens.eq(0)
									? addThousandSeparator(tokenToFloat(this.state.token.backedTokens, feeToken?.decimals || this.state.decimalsNative).toString())
									: '—'
							}
							</span>
						</div>
						{
							this.state.token && this.state.token.backedTokens && !this.state.token.backedTokens.eq(0)
								? (<span className="field-unit">
									{ feeToken ? ( <span className="i-coin"><img src={ feeToken.icon } alt="" /></span> ) : ( <span className="i-coin"><img src={ this.state.iconNative } alt="" /></span> ) }
									{ feeToken ? (<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${feeToken.address}` }>{ feeToken.symbol }</a> ) : this.state.symbolNative }
								</span>)
								: ''
						}
					</div>
				</div>
			)
		}
	}
	getWrappedTransferFeeBlock() {
		const getTransferCurrency = () => {
			if ( !this.state.token || !this.state.token.transferFeeToken || this.state.token.transferFeeToken === '0x0000000000000000000000000000000000000000' ) {
				return { address: this.state.techToken.address, symbol: this.state.techToken.symbol, icon: this.state.techToken.icon || default_icon }
			}
			const foundERC20 = this.state.erc20CollateralTokens.filter((item) => {
				if ( !this.state.token ) { return false; }
				if ( !item.address     ) { return false; }
				return item.address.toLowerCase() === this.state.token.transferFeeToken.toLowerCase()
			});
			if ( foundERC20.length ) {
				return { address: foundERC20[0].address, symbol: foundERC20[0].symbol, icon: foundERC20[0].icon || default_icon }
			} else {
				return { address: this.state.techToken.address, symbol: this.state.techToken.symbol, icon: this.state.techToken.icon || default_icon }
			}
		}
		let feeToken = this.state.token && this.state.token.transferFeeToken && this.state.token.transferFeeToken !== '0x0000000000000000000000000000000000000000'
			? this.metamaskAdapter.getERC20Contract(this.state.token.transferFeeToken)?.erc20Params || { address: '0x0000000000000000000000000000000000000000', icon: default_icon, symbol: this.state.token.transferFeeToken, decimals: 18 }
			: undefined;

		if (
			this.state.token &&
			this.state.token.transferFee &&
			!this.state.token.transferFee.eq(0) &&
			addThousandSeparator(tokenToFloat(this.state.token.transferFee, feeToken?.decimals || this.state.decimalsNative).toString()).length > 7
		) {
			return (
				<div className="field-wrap col-sm-6">
					<div className="field-row">
						<label className="field-label">
							<span>{ this.t('Transfer Fee') }</span>
							<Tippy
								content={ this.t('Amount of fee for the transfer of wrapped NFT') }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 260 }
							>
								<span className="i-tip"></span>
							</Tippy>
						</label>
						{/* <span className="field-data">$ 167.29    </span> */}
					</div>
					<Tippy
						content={ addThousandSeparator(tokenToFloat(this.state.token.transferFee, feeToken?.decimals || this.state.decimalsNative).toString()) }
						appendTo={ document.getElementsByClassName("wrapper")[0] }
						trigger='mouseenter'
						interactive={ false }
						arrow={ false }
						maxWidth={ 260 }
					>
						<div className="field-row">
							<div className="field-control">
								<span>
								{
									this.state.token && this.state.token.transferFee && !this.state.token.transferFee.eq(0)
										? addThousandSeparator(tokenToFloat(this.state.token.transferFee, feeToken?.decimals || this.state.decimalsNative).toString())
										: '—'
								}
								</span>
							</div>
							{
								this.state.token && this.state.token.transferFee && !this.state.token.transferFee.eq(0)
									? (
										<span className="field-unit">
											<span className="i-coin"><img src={ getTransferCurrency().icon } alt="" /></span>
											{
												getTransferCurrency().address ?
												( <a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${ getTransferCurrency().address}` }>{ getTransferCurrency().symbol }</a> ) :
												getTransferCurrency().symbol
											}
										</span>
									)
									: ''
							}
						</div>
					</Tippy>
				</div>
			)
		} else {
			return (
				<div className="field-wrap col-sm-6">
					<div className="field-row">
						<label className="field-label">
							<span>{ this.t('Transfer Fee') }</span>
							<Tippy
								content={ this.t('Amount of fee for the transfer of wrapped NFT') }
								appendTo={ document.getElementsByClassName("wrapper")[0] }
								trigger='mouseenter'
								interactive={ false }
								arrow={ false }
								maxWidth={ 260 }
							>
								<span className="i-tip"></span>
							</Tippy>
						</label>
						{/* <span className="field-data">$ 167.29    </span> */}
					</div>
					<div className="field-row">
						<div className="field-control">
							<span>
							{
								this.state.token && this.state.token.transferFee && !this.state.token.transferFee.eq(0)
									? addThousandSeparator(tokenToFloat(this.state.token.transferFee, feeToken?.decimals || this.state.decimalsNative).toString())
									: '—'
							}
							</span>
						</div>
						{
							this.state.token && this.state.token.transferFee && !this.state.token.transferFee.eq(0)
								? (
									<span className="field-unit">
										<span className="i-coin"><img src={ getTransferCurrency().icon } alt="" /></span>
										{
											getTransferCurrency().address ?
											( <a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${ getTransferCurrency().address}` }>{ getTransferCurrency().symbol }</a> ) :
											getTransferCurrency().symbol
										}
									</span>
								)
								: ''
						}
					</div>
				</div>
			)
		}
	}
	getWrappedRoyaltyBlock() {
		// const feeToken = this.state.token && this.state.token.transferFeeToken && this.state.token.transferFeeToken !== '0x0000000000000000000000000000000000000000'
		// 	? this.metamaskAdapter.getERC20Contract(this.state.token.transferFeeToken)?.erc20Params || { icon: default_icon, symbol: this.state.token.transferFeeToken, decimals: 18 }
		// 	: undefined;

		return (
			<div className="field-wrap col-sm-6">
				<div className="field-row">
					<label className="field-label">{ this.t('Royalty') }
						<Tippy
							content={ this.t('Percent of royalty from the transfer fee amount') }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 260 }
						>
							<span className="i-tip"></span>
						</Tippy>
					</label>
					{/* <span className="field-data">$ 167.29    </span> */}
				</div>
				<div className="field-row">
					<div className="field-control">
					{
							this.state.token && this.state.token.royaltyPercent && !this.state.token.royaltyPercent.eq(0)
								? this.state.token.royaltyPercent.toString()
								: '—'
						}
					</div>
					{
						this.state.token && this.state.token.royaltyPercent && !this.state.token.royaltyPercent.eq(0)
							? (<span className="field-unit">
								%
							</span>)
							: ''
					}
				</div>
			</div>
		)
	}
	getWrappedRoyaltyRecBlock() {
		return (
			<div className="field-wrap col-sm-6">
				<div className="field-row">
					<label className="field-label">{ this.t('Royalty recipient') }
						<Tippy
							content={ this.t('Address of royalty income reciever') }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 260 }
						>
							<span className="i-tip"></span>
						</Tippy>
					</label>
					{/* <span className="field-data">$ 167.29    </span> */}
				</div>
				{ this.state.token && this.state.token.royaltyRec && this.state.token.royaltyRec !== '0x0000000000000000000000000000000000000000' ?
					(
						<Tippy
							content={ this.state.token.royaltyRec }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 500 }
						>
							<div className="field-row">
								<div className="field-control">
									<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/token/${this.state.token.royaltyRec}` }>{ compactString(this.state.token.royaltyRec) }</a>
								</div>
							</div>
						</Tippy>
					) :
					(
						<div className="field-row">
							<div className="field-control">
								—
							</div>
						</div>
					)
				}
			</div>
		)
	}
	getWrappedRestrictionBlock() {

		if ( !this.state.token ) { return null; }

		const conditions = [];

		const nowDate = new BigNumber(new Date().getTime());
		if ( this.state.token.unwrapAfter && this.state.token.unwrapAfter.gt( nowDate ) ) { conditions.push(unixtimeToStr(this.state.token.unwrapAfter)) }

		if (
			this.state.token.unwraptFeeThreshold && this.state.token.backedTokens && this.state.token.unwraptFeeThreshold.gt( this.state.token.backedTokens )
		) {
			const addressToFilter = this.state.token.transferFeeToken || '0x0000000000000000000000000000000000000000';
			let   currencyToShow  = this.state.techToken;
			const foundCurrency   = this.state.erc20CollateralTokens.filter((item) => {
				if ( !item.address ) { return false; }
				return item.address.toLowerCase() === addressToFilter.toLowerCase()
			});
			if ( foundCurrency.length ) { currencyToShow = foundCurrency[0]; }

			const diff = tokenToFloat(this.state.token.unwraptFeeThreshold.plus(-this.state.token.backedTokens), currencyToShow.decimals || 18);
			conditions.push(`${addThousandSeparator(diff.toString())} ${currencyToShow.symbol}`)
		}

		if ( conditions.length !== 0) {
			return (
				<div className="w-card__status">
					<div>
						<span className="small">{ this.t('Unwrap will be ready after Time or Value Unlock.') }</span>
						<span>{ conditions.join(', ') }</span>
					</div>
				</div>
			)
		}

		return null;
	}
	// ----- END WRAPPED -----

	// ----- NOT WRAPPED -----
	getNotWrappedCollateralBlock() {
		return (
			<div className="field-wrap col-sm-6">
				<div className="field-row">
					<label className="field-label">{ this.t('Collateral') }
						<Tippy
							content={ this.t('Assets with which you provide your nft') }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 260 }
						>
							<span className="i-tip"></span>
						</Tippy>
					</label>
					{/* <span className="field-data">$ 167.29    </span> */}
				</div>
				<div className="field-row">
					<input
						className="field-control"
						placeholder="0.00"
						type="text"
						value={ addThousandSeparator(this.state.input_collateral) }
						onChange={(e) => {
							let value = removeThousandSeparator(e.target.value);
							if ( value.split('.')[1] && value.split('.')[1].length > this.state.decimalsNative ) { return; }
							if ( value.startsWith('00') ) { return; }
							if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
								if ( new BigNumber(value).isNaN() ) { return; }
								value = new BigNumber(value).toString();
							}
							this.setState({ input_collateral: value })
						}}
					/>
					{ this.checkBalance() }
				</div>
			</div>
		)
	}
	getNotWrappedFeeBlock() {
		return (
			<div className="field-wrap col-sm-6"
				onClick={() => {
					setTimeout(() => { if ( this.focusable.fees ) { this.focusable.fees.focus() } }, 1);
				}}
			>
				<div className="field-row">
					<div className="field-label">
						<label className="checkbox">
							<input
								type="checkbox"
								checked={ this.state.fees_checked }
								onChange={(e) => {
									if (e.target.checked) {
										this.setState({ fees_checked: e.target.checked })
										setTimeout(() => { this.focusable.fees.focus() }, 1);
									} else {
										this.setState({
											fees_checked: e.target.checked,
											unlock_fee_checked: e.target.checked,
											input_royalties: '',
											advancedOpened: false,
										})
									}

								}}
							/>
							<span className="check"> </span>
							<span>{ this.t('Fees') }</span>
						</label>
						<Tippy
							content={ this.t('Amount of transfer fee. Sender has to pay this fee to transfer wNFT. So that fee amount must be approved to this contract before any wNFT transfer (marketplace trading)') }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 260 }
						>
							<span className="i-tip"></span>
						</Tippy>
					</div>
					{/* <span className="field-data">$ 167.29    </span> */}
				</div>
				<div
					className="field-row"
					onClick={() => {
						if ( !this.state.fees_checked ) {
							this.setState({ fees_checked: true });
							setTimeout(() => { this.focusable.fees.focus() }, 1);
						}
					}}
				>
					<input
						className="field-control"
						placeholder="0.00"
						type="text"
						style={{ pointerEvents: isFirefox ? 'none' : undefined }}
						ref={ (e) => { this.focusable.fees = e } }
						value={ addThousandSeparator(this.state.input_fees) }
						disabled={ !this.state.fees_checked }
						onChange={(e) => {
							let value = removeThousandSeparator(e.target.value);
							if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
								if ( new BigNumber(value).isNaN() ) { return; }
								value = new BigNumber(value).toString();
							}
							this.setState({ input_fees: value, })
						}}
					/>
					<div className="select-coin">
						<div className="select-coin__value">
							<span className="field-unit">
								<span className="i-coin"><img src={ this.state.input_fees_erc20token ? this.state.input_fees_erc20token.icon : this.state.iconToken } alt="" /></span>
								{ this.state.input_fees_erc20token ? this.state.input_fees_erc20token.symbol : this.state.symbolToken }
							</span>
							<svg className="arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 0.529297L5 4.76459L9 0.529297" stroke="white"></path>
							</svg>
						</div>
						<ul className="select-coin__list">
							<li
								onClick={() => { this.setState({ input_fees_erc20token: undefined }); }}
							><span className="field-unit"><span className="i-coin"><img src={ this.state.iconToken } alt="" /></span>{ this.state.symbolToken }</span></li>
							{
								this.state.erc20CollateralTokens
								.filter((item) => { return !!item.address })
								.map((item) => { return (
									<li
										key={ item.address }
										onClick={() => { this.setState({ input_fees_erc20token: item }); }}
									><span className="field-unit"><span className="i-coin"><img src={ item.icon } alt="" /></span>{ item.symbol }</span></li>
								) })
							}
						</ul>
					</div>
				</div>
			</div>
		)
	}
	getNotWrappedRoyaltyBlock() {
		return (
			<div className="field-wrap col-sm-6">
				<div className="field-row">
					<label className="field-label">{ this.t('Royalty') }
						<Tippy
							content={ this.t('Percent of royalty from the transfer fee amount') }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 260 }
						>
							<span className="i-tip"></span>
						</Tippy>
					</label>
					{/* <span className="field-data">$ 167.29    </span> */}
				</div>
				<div className="field-row">
					<input
						className="field-control"
						placeholder="0"
						type="text"
						value={ this.state.input_royalties }
						onChange={(e) => {
							const value = e.target.value.replaceAll(' ', '');
							if (
								value === '' ||
								isNaN(parseInt(value))
							) {
								this.setState({ input_royalties: '' });
								return;
							}
							if ( parseInt(value) > 100 ) {
								return;
							}

							if ( !isNaN(this.addDays(parseInt(value)).getTime()) ) {
								this.setState({ input_royalties: `${parseInt(value)}` })
							}

						}}
					/>
					{ this.checkRoyalty() }
				</div>
			</div>
		)
	}
	getNotWrappedRoyaltyRecBlock() {
		return (
			<div className="field-wrap col-sm-6">
				<div className="field-row">
					<label className="field-label">{ this.t('Royalty recipient') }
						<Tippy
							content={ this.t('Address of royalty income reciever') }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 260 }
						>
							<span className="i-tip"></span>
						</Tippy>
					</label>
					{/* <span className="field-data">$ 167.29    </span> */}
				</div>
				<div className="field-row">
					<input
						className="field-control"
						placeholder="0x..."
						type="text"
						value={ this.state.input_royaltiesRec }
						onChange={(e) => {
							let value;
							if ( e.target.value.match(/[абвгдеёжзийклмнопрстуфхцчшщъыьэюяghijklmnopqrstuvwyz]+$/) ) {
								value = this.state.input_royaltiesRec;
							} else {
								value = e.target.value;
							}
							this.setState({ input_royaltiesRec: value });
						}}
					/>
					{ this.checkRoyaltyRec() }
				</div>
			</div>
		)
	}
	getNotWrappedFeeUnlockBlock() {
		return (
			<div className="field-wrap col-sm-6"
				onClick={() => {
					setTimeout(() => { this.focusable.unlock_fee.focus() }, 1);
					this.setState({
						fees_checked: true,
						advancedOpened: true
					});
				}}
			>
				<div className="field-row">
					<div className="field-label">
						<label className="checkbox">
							<input
								type="checkbox"
								checked={ this.state.unlock_fee_checked }
								onChange={(e) => {
									if ( e.target.checked ) {
										setTimeout(() => { this.focusable.unlock_fee.focus() }, 1);
										this.setState({ fees_checked: true, advancedOpened: true });
									}
									this.setState({ unlock_fee_checked: e.target.checked })
								}}
							/>
							<span className="check" > </span><span>Fee Unlock</span>
						</label>
						<Tippy
							content={ this.t('Unwrap will be available only after accumulated transfer fee will achieve this amount') }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 260 }
						>
							<span className="i-tip"></span>
						</Tippy>
					</div>
					{/* <span className="field-data">$ 167.29      </span> */}
				</div>
				<div
					className="field-row"
					onClick={() => {
						if ( !this.state.unlock_fee_checked ) {
							this.setState({ unlock_fee_checked: true });
							setTimeout(() => { this.focusable.unlock_fee.focus() }, 1);
						}
					}}
				>
					<input
						className="field-control"
						placeholder="0.00"
						type="text"
						style={{ pointerEvents: isFirefox ? 'none' : undefined }}
						ref={ (e) => { this.focusable.unlock_fee = e } }
						value={ addThousandSeparator(this.state.input_unlock_fee) }
						onChange={(e) => {
							let value = removeThousandSeparator(e.target.value);
							if ( value !== '' && !value.endsWith('.') && !value.endsWith('0') ) {
								if ( new BigNumber(value).isNaN() ) { return; }
								value = new BigNumber(value).toString();
							}
							this.setState({ input_unlock_fee: value })
						}}
						disabled={ !this.state.unlock_fee_checked }
					/>
					{ this.checkUnlockFees() }
				</div>
			</div>
		)
	}
	getNotWrappedTimeUnlockBlock() {
		return (
			<div className="field-wrap col-sm-6"
				onClick={() => {
					setTimeout(() => { this.focusable.unlock_time.focus() }, 1);
				}}
			>
				<div className="field-row">
					<div className="field-label">
						<label className="checkbox">
							<input
								type="checkbox"
								checked={ this.state.unlock_time_checked }
								onChange={(e) => {
									if ( e.target.checked ) {
										setTimeout(() => { this.focusable.unlock_time.focus() }, 1);
									}
									this.setState({ unlock_time_checked: e.target.checked })
								}}
							/>
							<span className="check"></span>
							<span>{ this.t('Time Unlock') }</span>
						</label>
						<Tippy
							content={ this.t('Unwrap will be available only after this date') }
							appendTo={ document.getElementsByClassName("wrapper")[0] }
							trigger='mouseenter'
							interactive={ false }
							arrow={ false }
							maxWidth={ 260 }
						>
							<span className="i-tip"></span>
						</Tippy>
					</div>
					<span className="field-data">{ this.addedDate() }</span>
				</div>
				<div
					className="field-row"
					onClick={() => {
						if ( !this.state.unlock_time_checked ) {
							this.setState({ unlock_time_checked: true });
							setTimeout(() => { this.focusable.unlock_time.focus() }, 1);
						}
					}}
				>
					<input
						className="field-control"
						placeholder="0"
						type="text"
						style={{ pointerEvents: isFirefox ? 'none' : undefined }}
						ref={ (e) => { this.focusable.unlock_time = e } }
						value={ addThousandSeparator(this.state.input_unlock_time) }
						onChange={(e) => {
							const value = e.target.value.replaceAll(' ', '');
							if (
								value === '' ||
								isNaN(parseInt(value))
							) {
								this.setState({ input_unlock_time: '' });
								return;
							}
							if ( !isNaN(this.addDays(parseInt(value)).getTime()) ) {
								this.setState({ input_unlock_time: `${parseInt(value)}` })
							}
						}}
						disabled={ !this.state.unlock_time_checked }
					/>
					<span className="field-unit">{ this.t('days') }</span>
				</div>
			</div>
		)
	}
	// ----- END NOT WRAPPED -----
	getCopiedHint(token: WrappedTokenType, icon: string) {
		let foundHint = false;
		if ( this.state.copiedHint ) {
		 	foundHint = ( this.state.copiedHint.id === `${token.contractAddress}${token.tokenId}` ) && ( this.state.copiedHint.icon === icon );
		}
		return (<span className="btn-action-info" style={{display: foundHint ? 'block' : 'none' }}>Copied</span>)
	}
	getMenuCopyLinkButton() {
		if ( !this.state.token ) { return null; }

		return (
			<li>
				<CopyToClipboard
					text={ `${window.location.origin}/#/token?chain=${this.state.chainId}&contractAddress=${this.state.token.contractAddress}&tokenId=${this.state.token.tokenId}` }
					onCopy={() => {
						if ( !this.state.token ) { return; }
						this.setState({
							copiedHint: { id: `${this.state.token.contractAddress}${this.state.token.tokenId}`, icon: 'tokenlinkimg' }
						});
						clearTimeout(this.copiedHintTimer);
						this.copiedHintTimer = window.setTimeout(() => { this.setState({
							copiedHint: undefined
						}); }, this.copiedHintTimeout*1000);
					}}
				>
					<button>
						Copy NFT URL
					</button>
				</CopyToClipboard>
			</li>
		)
	}
	checkoutERC20Approve = () => {
		const token = this.state.token;
		if ( !token || !token.transferFee ) { return; }

		this.setState({ checkoutApproving: true });
		this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));
		this.store.dispatch(waitingTokensAdd({ token: token, msg: this.t('Waiting for approve') }));

		const erc20Contract = this.metamaskAdapter.getERC20Contract(token.transferFeeToken);
		if ( !erc20Contract ) { return; }

		let addressTo = token.contractAddress;
		const foundAllowance = this.state.transferAllowances
			.filter((item) => { return item.wrapperAddress.toLowerCase() === token?.contractAddress.toLowerCase() })
			.filter((item) => { return item.erc20TokenAddress.toLowerCase() === token?.transferFeeToken.toLowerCase() })
		if ( foundAllowance.length ) { addressTo = foundAllowance[0].transferModelAddress }
		erc20Contract.makeAllowanceTransfer(token.transferFee, addressTo)
			.then(() => {
				erc20Contract.getBalance();
				this.metamaskAdapter.wrapperContract.updateTransferAllowance();
				this.setState({ checkoutApproving: false });
				if ( token ) { this.store.dispatch(waitingTokensRemove(token)) };
				this.store.dispatch(unsetLoading());
			})
			.catch((e: any) => {
				console.log(e);
				this.setState({ checkoutApproving: false });
				if ( token ) { this.store.dispatch(waitingTokensRemove(token)) };
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `Cannot make allowance: ${e.message.split('\n')[0]}`,
					buttons: undefined,
					links: undefined
				}));
			});

	}
	getMenuApproveFeeButton() {
		if (
			!this.state.token ||
			!this.state.token.transferFeeToken ||
			!this.state.token.transferFee ||
			!this.state.isWrapped ||
			this.state.token.owner !== this.state.userAddress ||
			this.state.token.transferFeeToken === '0x0000000000000000000000000000000000000000'
		) { return null; }

		const foundToken = this.state.transferAllowances.filter((item: { wrapperAddress: string, transferModelAddress: string, erc20TokenAddress: string, allowance: BigNumber }) => {
			if ( !this.state.token ) { return false }
			return item.erc20TokenAddress.toLowerCase() === this.state.token.transferFeeToken.toLowerCase()
		});
		if ( !foundToken.length ) { return null; }

		const allowance = foundToken[0].allowance;
		const transferFee = this.state.token.transferFee;
		if ( new BigNumber(allowance).gte(new BigNumber(transferFee)) ) {
			return null;
		}

		const getTransferCurrency = () => {
			if ( !this.state.token || !this.state.token.transferFee || this.state.token.transferFee.eq(0) ) { return '' }

			if ( !this.state.token.transferFeeToken || this.state.token.transferFeeToken === '0x0000000000000000000000000000000000000000' ) { return this.state.techToken.symbol }
			const foundERC20 = this.state.erc20CollateralTokens.filter((item) => {
				if ( !this.state.token ) { return false; }
				if ( !item.address     ) { return false; }
				return item.address.toLowerCase() === this.state.token.transferFeeToken.toLowerCase()
			});
			if ( foundERC20.length ) {
				return foundERC20[0].symbol
			} else {
				return this.state.techToken.symbol
			}
		}

		return (
			<li>
				<button
					onClick={() => {
						this.checkoutERC20Approve();
					}}
				>
					Approve
					<span className="data">
						{
							this.state.token.transferFee && !this.state.token.transferFee.eq(0) && this.state.techToken.decimals
							? addThousandSeparator(tokenToFloat(this.state.token.transferFee, this.state.techToken.decimals).toString())
							: ''
						}
						{ ' ' }
						{ getTransferCurrency() }
					</span>
				</button>
			</li>
		)
	}
	getMenuNotWrappedTransferButton() {
		if ( !this.state.token ) { return null; }
		if ( this.state.isWrapped ) { return null }

		return (
			<li>
				<button
					onClick={() => {
						this.setState({
							transferToken: true,
						})
					}}
				>
					Transfer
				</button>
			</li>
		)
	}
	getMenuWrappedTransferButton() {
		if ( !this.state.token ) { return null; }

		if (
			this.state.isWrapped &&
			this.state.token.owner.toLowerCase() === this.state.userAddress.toLowerCase() &&
			this.metamaskAdapter.wrapperContract.canWrapper('transfer', this.state.token.contractAddress)
		) {
			const getTransferCurrency = () => {
				if ( !this.state.token || !this.state.token.transferFee || this.state.token.transferFee.eq(0) ) { return '' }

				if ( !this.state.token.transferFeeToken || this.state.token.transferFeeToken === '0x0000000000000000000000000000000000000000' ) { return this.state.techToken.symbol }
				const foundERC20 = this.state.erc20CollateralTokens.filter((item) => {
					if ( !this.state.token ) { return false; }
					if ( !item.address     ) { return false; }
					return item.address.toLowerCase() === this.state.token.transferFeeToken.toLowerCase()
				});
				if ( foundERC20.length ) {
					return foundERC20[0].symbol
				} else {
					return this.state.techToken.symbol
				}
			}

			return (
				<li>
					<button
						onClick={() => {
							this.setState({
								transferToken: true,
							})
						}}
					>
						Transfer
						<span className="data">
							{
								this.state.token.transferFee && !this.state.token.transferFee.eq(0) && this.state.techToken.decimals
								? addThousandSeparator(tokenToFloat(this.state.token.transferFee, this.state.techToken.decimals).toString())
								: ''
							}
							{ ' ' }
							{ getTransferCurrency() }
						</span>
					</button>
				</li>
			)
		} else {
			return null
		}
	}
	getMenuWrappedSellButton() {
		if ( !this.state.token )          { return null; }
		if ( !this.state.marketplaceUrl ) { return null; }
		if ( !this.state.isWrapped )      { return null; }
		if ( this.state.token.owner.toLowerCase() !== this.state.userAddress.toLowerCase() ) { return null; }

		return (
			<li>
				<button
					onClick={() => {
						if ( !this.state.token ) { return null; }
						window.open(`${this.state.marketplaceUrl}${this.state.chainId}/${this.state.token.contractAddress}/${this.state.token.tokenId}`, '_blank')
					}}
				>
					Sell
				</button>
			</li>
		)
	}
	getTokenMenu() {
		if ( !this.state.token ) { return null; }
		return (
			<div className="block">
				<div className="w-card__action">
					<button className="btn-action"><img src={ icon_i_action } alt="" /></button>
					<ul className="list-action">
						{ this.getMenuCopyLinkButton() }
						{ this.getMenuApproveFeeButton() }
						{ this.getMenuWrappedTransferButton() }
						{ this.getMenuWrappedSellButton() }
						{ this.getMenuNotWrappedTransferButton() }
					</ul>
				</div>
			</div>
		)
	}
	getTokenHeaderBlock() {
		return (
			<div className="form-row">
				<div className="block">

					<div className="label">{ this.t('NFT Address') }
						{
							this.state.isWrapped
								? (
									<Tippy
										content={ this.t('Minter Contract address of your wrapped nft') }
										appendTo={ document.getElementsByClassName("wrapper")[0] }
										trigger='mouseenter'
										interactive={ false }
										arrow={ false }
										maxWidth={ 260 }
									>
										<span className="i-tip"></span>
									</Tippy>
								)
								: (
									<Tippy
										content={ this.t('Minter Contract address of your original nft') }
										appendTo={ document.getElementsByClassName("wrapper")[0] }
										trigger='mouseenter'
										interactive={ false }
										arrow={ false }
										maxWidth={ 260 }
									>
										<span className="i-tip"></span>
									</Tippy>
								)
						}
					</div>

					{ this.state.token ?
						<CopyToClipboard
							text={ this.state.token ? this.state.token.contractAddress : '' }
							onCopy={() => {
								this.setState({
									copiedHint: { id: `${this.state.token?.contractAddress}${this.state.token?.tokenId}`, icon: 'address' }
								});
								clearTimeout(this.copiedHintTimer);
								this.copiedHintTimer = window.setTimeout(() => { this.setState({
									copiedHint: undefined
								}); }, this.copiedHintTimeout*1000);
							}}
						>
							<button className="btn-copy">
								<Tippy
									content={ this.state.token ? this.state.token.contractAddress : '' }
									appendTo={ document.getElementsByClassName("wrapper")[0] }
									trigger='mouseenter'
									interactive={ false }
									arrow={ false }
									maxWidth={ 512 }
								>
									<b className="label">{ this.state.token ? compactString(this.state.token.contractAddress) : '' }</b>
								</Tippy>
								<img src={icon_i_copy} alt="" />
								{ this.getCopiedHint(this.state.token, 'address') }
							</button>
						</CopyToClipboard>
						: null
					}
				</div>

				<div className="block">
					<div className="label">{ this.t('ID') }
					{
							this.state.isWrapped
								? (
									<Tippy
										content={ this.t('Token ID of your wrapped nft') }
										appendTo={ document.getElementsByClassName("wrapper")[0] }
										trigger='mouseenter'
										interactive={ false }
										arrow={ false }
										maxWidth={ 260 }
									>
										<span className="i-tip"></span>
									</Tippy>
								)
								: (
									<Tippy
										content={ this.t('Token ID of your original nft') }
										appendTo={ document.getElementsByClassName("wrapper")[0] }
										trigger='mouseenter'
										interactive={ false }
										arrow={ false }
										maxWidth={ 260 }
									>
										<span className="i-tip"></span>
									</Tippy>
								)
						}
					</div>

					{ this.state.token ?
						<CopyToClipboard
							text={ this.state.token.tokenId }
							onCopy={() => {
								this.setState({
									copiedHint: { id: `${this.state.token?.contractAddress}${this.state.token?.tokenId}`, icon: 'id' }
								});
								clearTimeout(this.copiedHintTimer);
								this.copiedHintTimer = window.setTimeout(() => { this.setState({
									copiedHint: undefined
								}); }, this.copiedHintTimeout*1000);
							}}
						>
							<button className="btn-copy">
								<Tippy
									content={ this.state.token.tokenId }
									appendTo={ document.getElementsByClassName("wrapper")[0] }
									trigger='mouseenter'
									interactive={ false }
									arrow={ false }
									maxWidth={ 512 }
								>
									<b className="label">{ compactString(this.state.token.tokenId) }</b>
								</Tippy>
								<img src={icon_i_copy} alt="" />
								{ this.getCopiedHint(this.state.token, 'id') }
							</button>
						</CopyToClipboard>
						: null
					}
				</div>

				{ this.getTokenMenu() }

			</div>
		)
	}
	getWrapUnwrapBtn() {
		if ( !this.state.token || this.state.token.owner !== this.state.userAddress ) {
			return null
		}

		if ( this.state.isWrapped ) {
			const conditions = [];

			const nowDate = new BigNumber(new Date().getTime());
			if ( this.state.token.unwrapAfter && this.state.token.unwrapAfter.gt( nowDate ) ) { conditions.push(unixtimeToStr(this.state.token.unwrapAfter)) }

			if (
				this.state.token.unwraptFeeThreshold && this.state.token.backedTokens && this.state.token.unwraptFeeThreshold.gt( this.state.token.backedTokens )
			) {
				const addressToFilter = this.state.token.transferFeeToken || '0x0000000000000000000000000000000000000000';
				let   currencyToShow  = this.state.techToken;
				const foundCurrency   = this.state.erc20CollateralTokens.filter( (item) => {
					if ( !item.address ) { return false; }
					return item.address.toLowerCase() === addressToFilter.toLowerCase()
				});
				if ( foundCurrency.length ) { currencyToShow = foundCurrency[0]; }

				const diff = tokenToFloat(this.state.token.unwraptFeeThreshold.plus(-this.state.token.backedTokens), currencyToShow.decimals || 18);
				conditions.push(`${addThousandSeparator(diff.toString())} ${currencyToShow.symbol}`)
			}

			if ( conditions.length === 0) {
				return (
					<div className="form-submit">
						<button
							className="btn btn-lg btn-yellow"
							onClick={() => { this.unwrapToken() }}
						>{ this.t('UNWRAP & CLAIM') }</button>
					</div>
				)
			} else { return null; }
		} else {
			return (
				<div className="form-submit">
					<button
						className="btn"
						disabled={ this.isSubmitDisabled() }
						onClick={() => { this.wrapToken() }}
					>{ this.t('WRAP TOKEN') }</button>
				</div>
			)
		}
	}
	getTokenDataBlock() {
		if ( !this.state.token ) { return null }
		if ( this.state.isWrapped ) {
			return (
				<React.Fragment>
					<div className="w-card__param">
						<div className="form-row">
							{ this.getWrappedCollateralBlock() }
						</div>
						<div className="form-row">
							{ this.getWrappedCollectedFeeBlock() }
							{ this.getWrappedTransferFeeBlock() }
						</div>
						<div className="form-row">
							{ this.getWrappedRoyaltyBlock() }
							{ this.getWrappedRoyaltyRecBlock() }
						</div>

						{ this.getWrapUnwrapBtn() }
					</div>
					{ this.getWrappedRestrictionBlock() }
				</React.Fragment>
			)
		} else {
			if ( this.state.token.owner === this.state.userAddress ) {
				return (
					<div className="w-card__param">
						<div className="form-row">
							{ this.getNotWrappedCollateralBlock() }
							{ this.getNotWrappedFeeBlock() }
						</div>
						<div className="form-row">
							{ this.getNotWrappedRoyaltyBlock() }
							{ this.getNotWrappedRoyaltyRecBlock() }
						</div>
						<div className="form-row">
							{ this.getNotWrappedFeeUnlockBlock() }
							{ this.getNotWrappedTimeUnlockBlock() }
						</div>

						{ this.getWrapUnwrapBtn() }
					</div>
				)
			} else {
				return (
					<div className="w-card__param">
						<div className="form-row">
						<div className="field-wrap">
							<div className="field-row">
								<span>
								{ this.t('This token belongs to') }
								{ ' ' }
								<a target="_blank" rel="noopener noreferrer" href={ `${this.state.explorerBaseUrl}/address/${this.state.token.owner}` }>{ compactString(this.state.token.owner) }</a>
								</span>
							</div>
						</div>
						</div>
					</div>
				)
			}
		}
	}
	getTokenMedia() {
		if ( this.state.token && this.state.token.image === undefined ) {
			return (
				<div className="inner">
					<div className="default">
						<img src={ icon_logo } alt="" />
						<span><Trans i18nKey="Loading NON-FUNGIBLE TOKEN Preview" components={[<br />]} /></span>
					</div>
				</div>
			)
		}
		if ( this.state.token && this.state.token.image === '' ) {
			return (
				<div className="inner">
					<div className="default">
						<img src={ icon_logo } alt="" />
						<span><Trans i18nKey="Cannot load NON-FUNGIBLE TOKEN Preview" components={[<br />]} /></span>
					</div>
				</div>
			)
		}

		return (
			<div className="inner">
				<video className="img" src={ this.state.token ? this.state.token.image : '' } poster={ this.state.token ? this.state.token.image : '' } autoPlay={ true } muted={ true } loop={ true } />
			</div>
		)
	}

	render() {

		return (
			<React.Fragment>
				<main className="s-main s-main_centered">
					<div className="container">
						<div className="w-card">
							<div className="bg">
								<div className="w-card__nft">
									{ this.getTokenHeaderBlock() }
								</div>

								<div className="w-card__token">
									{
										this.state.token ?
										<CopyToClipboard
											text={ `${window.location.origin}/#/token?chain=${this.state.chainId}&contractAddress=${this.state.token.contractAddress}&tokenId=${this.state.token.tokenId}` }
											onCopy={() => {
												if ( this.state.token ) {
													this.setState({
														copiedHint: { id: `${this.state.token.contractAddress}${this.state.token.tokenId}`, icon: 'tokenlinkimg' }
													});
													clearTimeout(this.copiedHintTimer);
													this.copiedHintTimer = window.setTimeout(() => { this.setState({
														copiedHint: undefined
													}); }, this.copiedHintTimeout*1000);
												}
											}}
										>
											<button className="btn-token-link">
												<img src={ icon_i_link } alt="" />
												{ this.getCopiedHint(this.state.token, 'tokenlinkimg') }
											</button>
										</CopyToClipboard> : null
									}
									{ this.getTokenMedia() }
								</div>

								{ this.getTokenDataBlock() }
							</div>
						</div>
					</div>
				</main>
				{
					this.state.token && this.state.addValueToken ?
						<AddValuePopup
							store={ this.store }
							metamaskAdapter={ this.metamaskAdapter }
							token={ this.state.token }
							closePopup={()=>{ this.setState({ addValueToken: false }) }}
						/> : null
				}
				{
					this.state.token && this.state.transferToken ?
						<TransferPopup
							store={ this.store }
							metamaskAdapter={ this.metamaskAdapter }
							token={ this.state.token }
							closePopup={()=>{ this.setState({ transferToken: false }) }}
							history={ this.props.history }
						/> : null
				}
			</React.Fragment>
		)
	}
}

export default withTranslation("translations")(withRouter(NFTPreview));