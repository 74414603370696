
import Web3 from 'web3';
import { Contract } from "web3-eth-contract";
import WalletConnectProvider from "@walletconnect/web3-provider";
import config from '../../config.json';

import {
	ERC20Contract,
	loadERC721TokenAll,
	removeERC721Token,
	WrapperContract
} from '.';

import {
	setError,
	resetAppData,

	metamaskConnectionSuccess,
	metamaskConnectionRejected,
	metamaskConnectionNotInstalled,
	metamaskSetChainParams,

	updateNativeBalance,

	metamaskSetAvailableChains,
	setAuthMethod,
	setLoading,
	unsetLoading,
	clearError,
	requestChain,
	incompleteTokensRemove,
} from '../../reducers';

import default_icon from '../../static/pics/coins/_default.png';

import BigNumber from 'bignumber.js';
import { batchWrapSubscriptionUpdateEndTime, batchWrapSubscriptionUpdateParams } from '../../reducers/actions';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

type MetamaskAdapterPropsType = {
	store: any,
	t    : any,
}
export type ChainParamsType = {
	chainId?              : number | undefined,
	chainName             : string,
	chainRPCUrl           : string,
	networkTokenTicket    : string,
	EIPPrefix             : string,
	networkTokenDecimals  : number | undefined,
	networkTokenIcon      : string | undefined,
	currentWrapperContract: {
		address   : string,
		createDate: string,
		features  : Array<string>,
	};
	wrapperContractOld            : Array<{ address: string, createDate: string, features: Array<string> }>,
	supportedERC20Tokens          : Array<string>,
	isTestNetwork                 : Boolean;
	explorerBaseUrl               : string;
	marketplaceUrl?               : string;
	explorerName                  : string;
	minterContract?               : string;
	batchWrapSubscriptionContract?: string;
};

export default class MetamaskAdapter {

	store                : any;
	web3!                : Web3;
	wcProvider!          : any;
	availiableChains     : Array<ChainParamsType>;
	chainId?             : number;
	chainConfig!         : ChainParamsType;
	userAddress!         : string;
	wrapperContract!     : WrapperContract;
	erc20CollateralTokens: Array<ERC20Contract>;
	unsubscribe          : () => {};
	chainChangeRequested : boolean;

	batchWrapSubscriptionContract!: Contract;

	t: any;

	accessAtempts: number;

	constructor(props: MetamaskAdapterPropsType) {
		this.store = props.store;
		this.t = props.t;
		this.availiableChains = config.CHAIN_SPECIFIC_DATA;
		this.store.dispatch(metamaskSetAvailableChains(this.availiableChains));
		this.erc20CollateralTokens = [];
		this.chainChangeRequested = false;

		this.accessAtempts = 0;

		this.unsubscribe = this.store.subscribe(() => {
			if ( this.store.getState().metamaskAdapter.logged && !this.chainChangeRequested && this.store.getState().metamaskAdapter.requestChainId && this.chainId && this.store.getState().metamaskAdapter.requestChainId !== this.chainId ) {
				this.chainChangeRequested = true;
				this.store.dispatch(setError({
					text: `You are trying to open chain which does not match one selected in metamask`,
					buttons: [
						{
							text: this.t('Continue with current'),
							clickFunc: async () => {
								this.store.dispatch(requestChain( undefined ));
								this.chainChangeRequested = false;
								window.location.href = '/#/list';
								loadERC721TokenAll(this, this.chainId || 0, this.userAddress).forEach((item) => {
									// In progress turned on
									// this.store.dispatch(incompleteTokensAdd( item ));

									// In progress turned off
									this.store.dispatch(incompleteTokensRemove(item));
									removeERC721Token(item, this.chainId || 0)
								});
								await this.getChainConfg();
								this.store.dispatch(clearError());
							}
						},
						{
							text: this.t('Switch network'),
							clickFunc: () => {
								(window as any).ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: '0x' + Number(this.store.getState().metamaskAdapter.requestChainId).toString(16) }], // chainId must be in hexadecimal numbers
								})
							}
						},
					],
					links: undefined
				}));
			}
		})
	}
	async connect() {
		const method = this.store.getState().metamaskAdapter.authMethod;

		this.store.dispatch(setLoading({ msg: this.t('Waiting for metamask login') }));
		if ( method === 'METAMASK' ) {
			try {
				if ( !(window as any).ethereum ) {
					// console.log((window as any).ethereum)
					this.store.dispatch(unsetLoading());
					this.store.dispatch(metamaskConnectionNotInstalled());
					this.store.dispatch(setError({
						text: this.t('No access to metamask'),
						buttons: [{
							text: this.t('Download extension or mobile app'),
							clickFunc: () => { window.open('https://metamask.io/download.html', "_blank"); }
						},
						{
							text: this.t('Close'),
							clickFunc: () => { this.store.dispatch(clearError()) }
						}],
						links: undefined
					}));
					return;
				} else {
					await (window as any).ethereum.request({ method: 'eth_requestAccounts' });
				}
			} catch(e) {
				this.store.dispatch(unsetLoading());
				this.accessAtempts++;
				console.log('Cannot connect to metamask:', e);

				if ( this.accessAtempts < 3 ) {
					setTimeout(() => { this.connect() }, 100);
				} else {
					this.accessAtempts = 0;
					this.store.dispatch(setError({
						text: this.t('You should grant access in Metamask or WalletConnect'),
						buttons: [{
							text: this.t('Try again'),
							clickFunc: () => { this.connect(); this.store.dispatch(clearError()); }
						}],
						links: undefined
					}));
					this.store.dispatch(metamaskConnectionRejected());
					localStorage.removeItem('provider_type');
				}

				return;
			}

			try {
				this.web3 = new Web3( (window as any).ethereum );
				localStorage.setItem('provider_type', 'METAMASK');
			} catch(e: any) {
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: this.t('Cannot connect to metamask'),
					buttons: [{
						text: this.t('Try again'),
						clickFunc: () => { this.connect() }
					}],
					links: undefined
				}));
				console.log(`Cannot connect to metamask: ${e.toString()}`);
				this.store.dispatch(metamaskConnectionRejected());
				localStorage.removeItem('provider_type');
			}

		}
		if ( method === 'WALLET_CONNECT' ) {
			const urls: any = {};
			this.availiableChains.forEach((item) => {
				if (!item.chainId) { return; }
				urls[item.chainId] = item.chainRPCUrl;
			});

			try {
				this.wcProvider = new WalletConnectProvider({
					rpc: urls,
				});
				await this.wcProvider.enable();
			} catch(e) {
				this.store.dispatch(unsetLoading());
				console.log('Cannot connect to wallet connect:', e);

				this.store.dispatch(setError({
					text: this.t('You should grant access in your wallet'),
					buttons: [{
						text: this.t('Try again'),
						clickFunc: () => {this.connect(); this.store.dispatch(clearError()); }
					},
					{
						text: this.t('Close'),
						clickFunc: () => { this.store.dispatch(clearError()); }
					}],
					links: undefined
				}));
				this.store.dispatch(metamaskConnectionRejected());
				localStorage.removeItem('provider_type');

				return;
			}
			try {
				this.web3 = new Web3( this.wcProvider );
				localStorage.setItem('provider_type', 'WALLET_CONNECT');
			} catch(e: any) {
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: this.t('Cannot connect to walletconnect'),
					buttons: [{
						text: this.t('Try again'),
						clickFunc: () => { this.connect() }
					}],
					links: undefined
				}));
				localStorage.removeItem('provider_type');
				console.log(`Cannot connect to walletconnect: ${e.toString()}`);
				this.store.dispatch(metamaskConnectionRejected());
			}

		}

		console.log('web3', this.web3);
		const accounts = await this.web3.eth.getAccounts();
		this.userAddress = accounts[0];
		this.store.dispatch(metamaskConnectionSuccess({
			address: this.userAddress,
		}));
		await this.getChainId();
		this.store.dispatch(setLoading({msg: this.t('Loading tokens')}));
		this.fetchNativeBalance();
		this.updateChainListener(method);
	}
	async getChainId() {
		const chainId = await this.web3.eth.getChainId()
		this.chainId = chainId;
		await this.checkUrlChain();
	}
	async checkUrlChain() {
		if ( this.store.getState().metamaskAdapter.requestChainId && this.store.getState().metamaskAdapter.requestChainId !== this.chainId ) {
			this.store.dispatch(setError({
				text: `You are trying to open chain which does not match one selected in metamask`,
				buttons: [
					{
						text: this.t('Continue with current'),
						clickFunc: async () => {
							window.location.href = '/#/list';
							loadERC721TokenAll(this, this.chainId || 0, this.userAddress).forEach((item) => {
								// In progress turned on
									// this.store.dispatch(incompleteTokensAdd( item ));

									// In progress turned off
									this.store.dispatch(incompleteTokensRemove(item));
									removeERC721Token(item, this.chainId || 0)
							});
							await this.getChainConfg();
							this.store.dispatch(clearError());
						}
					},
					{
						text: this.t('Switch network'),
						clickFunc: () => {
							(window as any).ethereum.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: '0x' + Number(this.store.getState().metamaskAdapter.requestChainId).toString(16) }], // chainId must be in hexadecimal numbers
							})
						}
					},
				],
				links: undefined
			}));
		} else {
			loadERC721TokenAll(this, this.chainId || 0, this.userAddress).forEach((item) => {
				// In progress turned on
				// this.store.dispatch(incompleteTokensAdd( item ));

				// In progress turned off
				this.store.dispatch(incompleteTokensRemove(item));
				removeERC721Token(item, this.chainId || 0)
			});
			await this.getChainConfg();
		}
	}
	async getChainConfg() {

		let foundChain = this.availiableChains.filter((item: ChainParamsType) => { return item.chainId === this.chainId });
		if ( !foundChain.length ) {
			const chosenAuthMethod = this.store.getState().metamaskAdapter.authMethod;
			this.store.dispatch(resetAppData());
			this.store.dispatch(setAuthMethod(chosenAuthMethod));
			localStorage.removeItem('walletconnect');
			const availableChainsStr = this.availiableChains.map((item) => { return item.isTestNetwork ? `${item.chainName} (testnet)` : item.chainName }).join(', ');
			this.store.dispatch(setError({
				text: `${this.t('Unsupported chain. Please choose from')}: ${ availableChainsStr }`,
				buttons: [{
					text: this.t('Connect again'),
					clickFunc: () => { this.connect() }
				}],
				links: undefined
			}));
			console.log('Cannot load domain info');
			return;
		}

		let icon = default_icon;
		try { icon = require(`../../static/pics/coins/${foundChain[0].networkTokenTicket.toLowerCase()}.jpeg`).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${foundChain[0].networkTokenTicket.toLowerCase()}.jpg` ).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${foundChain[0].networkTokenTicket.toLowerCase()}.png` ).default } catch (ignored) {}
		try { icon = require(`../../static/pics/coins/${foundChain[0].networkTokenTicket.toLowerCase()}.svg` ).default } catch (ignored) {}

		this.store.dispatch(metamaskSetChainParams({
			chainId                      : this.chainId,
			chainName                    : foundChain[0].chainName,
			chainRPCUrl                  : foundChain[0].chainRPCUrl,
			networkTokenTicket           : foundChain[0].networkTokenTicket,
			EIPPrefix                    : foundChain[0].EIPPrefix,
			networkTokenDecimals         : foundChain[0].networkTokenDecimals,
			networkTokenIcon             : icon,
			isTestNetwork                : foundChain[0].isTestNetwork,
			explorerBaseUrl              : foundChain[0].explorerBaseUrl,
			marketplaceUrl               : foundChain[0].marketplaceUrl,
			explorerName                 : foundChain[0].explorerName,
			minterContract               : foundChain[0].minterContract,
			batchWrapSubscriptionContract: foundChain[0].batchWrapSubscriptionContract,
		}));

		this.chainConfig = {
			chainId                      : this.chainId,
			chainName                    : foundChain[0].chainName,
			chainRPCUrl                  : foundChain[0].chainRPCUrl,
			networkTokenTicket           : foundChain[0].networkTokenTicket,
			EIPPrefix                    : foundChain[0].EIPPrefix,
			networkTokenDecimals         : foundChain[0].networkTokenDecimals,
			networkTokenIcon             : icon,
			currentWrapperContract       : foundChain[0].currentWrapperContract,
			wrapperContractOld           : foundChain[0].wrapperContractOld,
			supportedERC20Tokens         : foundChain[0].supportedERC20Tokens,
			isTestNetwork                : foundChain[0].isTestNetwork,
			explorerBaseUrl              : foundChain[0].explorerBaseUrl,
			marketplaceUrl               : foundChain[0].explorerBaseUrl,
			explorerName                 : foundChain[0].explorerName,
			minterContract               : foundChain[0].minterContract,
			batchWrapSubscriptionContract: foundChain[0].batchWrapSubscriptionContract,
		}

		await this.createWrapperContract();
		await this.createERC20Contracts(this.chainConfig.supportedERC20Tokens);
		this.createBatchWrapSubscriptionContract();

	}
	updateChainListener(authMethod: string) {
		if ( authMethod === 'METAMASK' ) {
			(window as any).ethereum.on('chainChanged', () => {
				window.location.reload();
			});
			(window as any).ethereum.on('accountsChanged', () => {
				window.location.reload();
			});
		}
		if ( authMethod === 'WALLET_CONNECT' ) {
			this.wcProvider.on("accountsChanged", (accounts: string[]) => {
				window.location.reload();
			});
			this.wcProvider.on("chainChanged", (chainId: number) => {
				window.location.reload();
			});
		}
	}
	async createERC20Contracts(erc20Contracts: Array<string>) {
		this.erc20CollateralTokens = await Promise.all(erc20Contracts.map(async (item) => {
			return new ERC20Contract({
				web3                : this.web3,
				store               : this.store,
				contractAddress     : item,
				contractType        : 'collateral',
				userAddress         : this.userAddress,
				wrapperAddress      : this.chainConfig.currentWrapperContract.address,
			});
		}));
	}
	async addERC20Contracts(erc20Contract: string) {
		if ( erc20Contract === '' || erc20Contract === '0x0000000000000000000000000000000000000000' ) { return; }

		let contract = undefined;
		try {
			contract = new ERC20Contract({
				web3                : this.web3,
				store               : this.store,
				contractAddress     : erc20Contract,
				contractType        : 'collateral',
				userAddress         : this.userAddress,
				wrapperAddress      : this.chainConfig.currentWrapperContract.address,
			});

			if ( contract ) {
				this.erc20CollateralTokens = [
					...this.erc20CollateralTokens.filter((item) => { return item.contractAddress.toLowerCase() !== erc20Contract.toLowerCase() }),
					contract
				]
			}
		} catch(ignored) {}
	}
	getERC20Contract(address: string): ERC20Contract | undefined {
		if ( !this.wrapperContract.erc20Contract ) { return undefined }
		if ( this.wrapperContract.erc20Contract.contractAddress.toLowerCase() === address.toLowerCase() ) { return this.wrapperContract.erc20Contract; }
		const foundContract = this.erc20CollateralTokens.filter((item: ERC20Contract) => { return item.contractAddress.toLowerCase() === address.toLowerCase() });
		if ( foundContract.length ) {
			return foundContract[0]
		} else {
			return undefined;
		}
	}
	async createWrapperContract() {

		try {
			this.wrapperContract = new WrapperContract({
				store              : this.store,
				metamaskAdapter    : this,
				web3               : this.web3,
				contractAddress    : this.chainConfig.currentWrapperContract.address,
				wrapperContractOld : this.chainConfig.wrapperContractOld,
				wrapperFeatures    : this.chainConfig.currentWrapperContract.features,
				userAddress        : this.userAddress,
				updateNativeBalance: () => { this.fetchNativeBalance() },
				t                  : this.t,
				erc20TokenAddress  : this.chainConfig.supportedERC20Tokens,
			});
		} catch(e: any) {
			this.store.dispatch(setError({
				text: e.message,
				buttons: [{
					text: 'Try again',
					clickFunc: () => { this.connect() }
				}],
				links: undefined
			}));
		}

		return;
	}
	async createBatchWrapSubscriptionContract() {
		if ( !this.chainConfig.batchWrapSubscriptionContract ) { return; }

		let batchWrapSubscriptionABI;
		try {
			batchWrapSubscriptionABI = require(`../../abis/batchwrapsubscription.json`);
		} catch(e) {
			console.log(`Cannot load ${this.chainConfig.batchWrapSubscriptionContract} batchwrapsubscription abi:`, e);
			throw new Error(`Cannot load batchWrapSubscriptionContract abi`);
		}
		this.batchWrapSubscriptionContract = new this.web3.eth.Contract(batchWrapSubscriptionABI, this.chainConfig.batchWrapSubscriptionContract);
		const ticketParams = await this.batchWrapSubscriptionContract.methods.ticketsOnSale(0).call();
		const endTime = await this.batchWrapSubscriptionContract.methods.validDistributors(this.userAddress).call();
		const endTimeParsed = new BigNumber(endTime).multipliedBy(1000);

		this.store.dispatch(batchWrapSubscriptionUpdateParams({
			ticketParams: {
				paymentToken: ticketParams.paymentToken,
				paymentAmount: new BigNumber(ticketParams.paymentAmount),
				timelockPeriod: new BigNumber(ticketParams.timelockPeriod),
				ticketValidPeriod: new BigNumber(ticketParams.ticketValidPeriod),
			},
			endTime: endTimeParsed,
			contractAddress: this.chainConfig.batchWrapSubscriptionContract
		}));
	}
	async updateBatchWrapSubscription() {
		const endTime = await this.batchWrapSubscriptionContract.methods.validDistributors(this.userAddress).call();
		const endTimeParsed = new BigNumber(endTime).multipliedBy(1000);

		this.store.dispatch(batchWrapSubscriptionUpdateEndTime({ endTime: endTimeParsed }));
	}

	async fetchNativeBalance() {
		const balance = new BigNumber(await this.web3.eth.getBalance(this.userAddress));
		this.store.dispatch(updateNativeBalance({ balance }));
	}

}