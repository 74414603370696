
import { default as urljoin } from 'url-join';

import BigNumber from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

export type DiscoveredToken = {
	blocknumber: BigNumber,
	contract_address: string,
	logindex: number,
	owner: string,
	token_id: string,
	token_uri: string,
}

export const fetchUserTokens = async (chainId: number, userAddress: string, page: number):Promise<Array<DiscoveredToken>> => {

	const tokensOnPage = 12

	const BASE_URL = process.env.REACT_APP_ORACLE_API_BASE_URL;
	if ( !BASE_URL ) { console.log('No oracle base url in .env'); return []; }

	const app_name   = window.location.host;
	const app_id     = process.env.REACT_APP_ORACLE_APP_ID;
	const app_key    = process.env.REACT_APP_ORACLE_APP_KEY;
	const key_active = process.env.REACT_APP_ORACLE_KEY_ACTIVE_TIME;

	if ( !app_id || !app_key || !key_active ) {
		console.log('No app_id or app_key of key_active in .env');
		return [];
	}

	const url = urljoin(BASE_URL, `/discover/721/user/${chainId}/${userAddress}?page=${page}&size=${tokensOnPage}`);

	async function sha256(message: string) {
		const msgBuffer = new TextEncoder().encode(message);
		const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
		return hashHex;
	}
	async function signTimed(name: string, key: string) {
		const now = new Date().getTime();
		const timeBlock = parseInt(`${now / (parseInt(key_active || '0') * 1000)}`);

		return sha256(name+key+timeBlock)
	}
	const tempKey = await signTimed(app_name, app_key);

	try {
		const resp = await fetch(url, {
			headers: {
				'Authorization': `${app_id}.${tempKey}`,
			}
		});

		const respParsed: Array<DiscoveredToken> = await resp.json();
		if ( !resp.ok ) {
			console.log('Cannot fetch token from oracle', respParsed);
			return [];
		}
		if ('error' in respParsed) {
			return [];
		}

		return respParsed;
	} catch (e) {
		console.log('Cannot fetch token from oracle', e);
		return [];
	}
}