
import Web3         from 'web3';
import { Contract } from "web3-eth-contract";
import erc721_abi   from '../../abis/_erc721.json';
import erc20_abi    from '../../abis/_erc20.json';
import {
	ERC20ContractParamsType,
	MetamaskAdapter,
	removeERC721Token,
	ERC20Contract,
	getERC721Token
} from '.';

import {
	wrappedTokensAdd,
	wrappedStatsUpdate,
	unsetLoading,
	setError,
	wrappedTokensClear,
	incompleteTokensRemove,
	waitingTokensRemove,
	setSuccess,
	waitingTokensAdd,
	setInfo,
	clearInfo,
	setLoading,
	updateTransferAllowance,
	gotoListRequest,
	discoveredTokensAdd,
	ignoredTokensAdd,
	setTokensLoading,
	unsetTokensLoading
} from '../../reducers';

import {
	fetchUserTokens,
	DiscoveredToken
} from '../APIService';

import {
	History,
} from 'history';

import icon_onb_2 from '../../static/pics/onb-2.png';
import icon_onb_3 from '../../static/pics/onb-3.png';
import icon_onb_5 from '../../static/pics/onb-5.png';

import BigNumber  from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100});

export type BatchWrapRecipientItem = {
	timeAdded: number,
	userAddress: string,
	tokenId: string,
}
export type BatchWrapCollateralItem = {
	tokenAddress: string,
	token: ERC20ContractParamsType | undefined,
	amount: BigNumber,
}

type WrapperContractPropsType = {
	store              : any,
	web3               : Web3,
	metamaskAdapter    : MetamaskAdapter,
	contractAddress    : string,
	wrapperFeatures    : Array<string>,
	wrapperContractOld : Array<{ address: string, features: Array<string> }>,
	userAddress        : string,
	erc20TokenAddress  : Array<string>,
	updateNativeBalance: Function,
	t                  : any,
}
export type WrappedTokenType = {
	owner                  : string,
	chainId                : number,
	contractAddress        : string,
	tokenId                : string,
	originalContractAddress: string | undefined,
	originalTokenId        : string | undefined,
	description            : string | undefined,
	image                  : string | undefined,
	name                   : string | undefined,
	tokenUrl               : string,
	backedTokens           : BigNumber | undefined,
	backedValue            : BigNumber | undefined,
	backedERC20Value       : Array<{amount: BigNumber, address: string}>,
	royalty                : BigNumber | undefined,
	royaltyPercent         : BigNumber | undefined,
	royaltyRec             : string,
	transferFee            : BigNumber | undefined,
	transferFeeToken       : string,
	unwrapAfter            : BigNumber | undefined,
	unwraptFeeThreshold    : BigNumber | undefined,
	sortParams             : {
		blockNumber: BigNumber | undefined,
		logIndex   : BigNumber | undefined,
	}
}
export type WrappedTokensStatType = {
	count          : number,
	collateral     : BigNumber,
	erc20Collateral: Array<{amount: BigNumber, address: string}>,
	fee            : Array<{amount: BigNumber, address: string}>,
	royalties      : Array<{amount: BigNumber, address: string}>,
};

export default class WrapperContract {

	web3                   : Web3;
	metamaskAdapter        : MetamaskAdapter;
	store                  : any;
	contractAddress        : string;
	userAddress            : string;
	contract               : Contract;
	wrapperFeatures        : Array<string>;
	erc20Contract!         : ERC20Contract;
	wrapperAllowedERC20    : Array<string>;
	wrapperContractOld     : Array<{ address: string, features: Array<string> }>;
	previousContracts!     : Array<{ address: string, contract: Contract, features: Array<string>, allowedERC20: Array<string> }>;
	erc20TokenAddress      : Array<string>;
	updateNativeBalance    : Function;
	t                      : any;
	previousContractsLoaded: boolean;
	tokenUpdateDelay       : number;
	tokenLastUpdate        : number;

	constructor(props: WrapperContractPropsType) {
		this.web3                    = props.web3;
		this.metamaskAdapter         = props.metamaskAdapter;
		this.store                   = props.store;
		this.contractAddress         = props.contractAddress;
		this.wrapperFeatures         = props.wrapperFeatures;
		this.userAddress             = props.userAddress;
		this.wrapperAllowedERC20     = [];
		this.previousContracts       = [];
		this.wrapperContractOld      = props.wrapperContractOld;
		this.erc20TokenAddress       = props.erc20TokenAddress;
		this.updateNativeBalance     = props.updateNativeBalance;
		this.t                       = props.t;
		this.previousContractsLoaded = false;
		this.tokenUpdateDelay        = 20;                         // s
		this.tokenLastUpdate         = 0;

		let wrapperABI;
		try {
			wrapperABI = require(`../../abis/${this.contractAddress}.json`);
		} catch(e) {
			console.log(`Cannot load ${this.contractAddress} wrapper abi:`, e);
			throw new Error(`Cannot load wrapper abi`);
		}
		this.contract = new this.web3.eth.Contract(wrapperABI, this.contractAddress);
		this.checkAllowedERC20();
		console.log('wrapper', this.contract);
		this.addTransferListener(this.contract);

		this.init();
	}

	async init() {
		await this.createPreviousContracts();
		this.previousContractsLoaded = true
		await this.getParams();

	}

	async getParams() {
		let techTokenAddress = '';
		try {
			techTokenAddress = await this.contract.methods.projectToken().call();
		} catch(e) {
			this.store.dispatch(unsetLoading());
			this.store.dispatch(setError({
				text: `Cannot connect to wrapper contract`,
				buttons: undefined,
				links: undefined
			}));
			return;
		}

		this.erc20Contract = new ERC20Contract({
			web3           : this.web3,
			store          : this.store,
			contractAddress: techTokenAddress,
			contractType   : 'tech',
			userAddress    : this.userAddress,
			wrapperAddress : this.contractAddress,
		});

		Promise.all(this.erc20TokenAddress.map(async (item) => {
			let wrapperModelParsed;
			try {
				const wrapperModel = await this.contract.methods.partnersTokenList(item).call();
				 wrapperModelParsed = wrapperModel.transferFeeModel;
			} catch (e) {
				wrapperModelParsed = this.contractAddress
			}
			const allowance = new BigNumber(await this.metamaskAdapter.getERC20Contract(item)?.contract.methods.allowance( this.userAddress, wrapperModelParsed ).call());

			this.store.dispatch(updateTransferAllowance({
				wrapperAddress: this.contractAddress,
				transferModelAddress: wrapperModelParsed,
				erc20TokenAddress: item,
				allowance,
			}))
		}));

		await this.getNFTTokens();
		await this.getDiscoveredTokens(1);
	}
	updateTransferAllowance() {
		Promise.all(this.store.getState().transferModelAllowances.map(async (item: { wrapperAddress: string, transferModelAddress: string, erc20TokenAddress: string, amount: BigNumber }) => {
			const allowance = new BigNumber(await this.metamaskAdapter.getERC20Contract(item.erc20TokenAddress)?.contract.methods.allowance( this.userAddress, item.transferModelAddress ).call());
			this.store.dispatch(updateTransferAllowance({
				...item,
				allowance,
			}))
		}));
	}
	canWrapper(feature: string, address?: string): boolean {

		if ( !address || address.toLowerCase() === this.contractAddress.toLowerCase() ) {
			return this.wrapperFeatures.includes(feature)
		}

		const foundContract = this.previousContracts.filter((item) => { return item.address.toLowerCase() === address.toLowerCase() });
		if ( !foundContract.length ) { return false }

		return foundContract[0].features.includes(feature);

	}
	async createPreviousContracts() {
		// if cannot load old contract ABI
		// fill it with empty string
		this.previousContracts = await Promise.all(this.wrapperContractOld
			// load abi for each contract address
			.map((item) => {
				let wrapperABIOld;
				try {
					wrapperABIOld = require(`../../abis/${item.address}.json`);
				} catch(e) {
					console.log(`Cannot load ${this.contractAddress} old wrapper abi:`, e)
					return {
						address : item.address,
						features: item.features,
						abi     : '',
					}
				}
				return {
					address : item.address,
					features: item.features,
					abi     : wrapperABIOld,
				}
			})
			// remove items with empty abi, which could not be loaded
			.filter((item) => {
				return item.abi !== '';
			})
			// create contracts with loaded abis
			.map(async (item) => {
				const createdContract = new this.web3.eth.Contract( item.abi, item.address);
				this.addTransferListener(createdContract);

				const allowedERC20: Array<string> = [];
				await Promise.all(this.erc20TokenAddress.map(async (item) => {
					try {
						const erc20Config = await createdContract.methods.partnersTokenList(item).call();
						if ( erc20Config.enabledForCollateral ) { allowedERC20.push(item) };
					} catch(fallback) {
						try {
							const erc20Config = await createdContract.methods.enabledForCollateral(item).call();
							if ( erc20Config ) { allowedERC20.push(item) };
						} catch(ignored) {}
					}
				}));

				return {
					address : item.address,
					features: item.features,
					contract: createdContract,
					allowedERC20,
				}
			}));
	}
	async checkAllowedERC20() {
		this.erc20TokenAddress.forEach(async (item) => {
			try {
				const erc20Config = await this.contract.methods.partnersTokenList(item).call();
				if ( erc20Config.enabledForCollateral ) {
					this.wrapperAllowedERC20.push(item)
				}
			} catch(fallback) {
				const erc20Config = await this.contract.methods.enabledForCollateral(item).call();
				if ( erc20Config ) {
					this.wrapperAllowedERC20.push(item)
				}
			}
		});
	}
	addTransferListener(contract: Contract) {
		contract.events.Transfer(
			{
				filter: { to: this.userAddress },
				fromBlock: 'earliest'
			},
			() => {
				console.log('Transfer event caught, updating')
				this.store.dispatch(wrappedTokensClear());
				this.getNFTTokens();
				this.store.dispatch(unsetLoading());
			}
		);
		contract.events.UnWrapped(
			{
				filter: { owner: this.userAddress },
				fromBlock: 'earliest'
			},
			() => {
				console.log('Unwrap event caught, updating')
				this.store.dispatch(wrappedTokensClear());
				this.getNFTTokens();
				this.store.dispatch(unsetLoading());
			}
		);
	}

	calcTokenStats(token: WrappedTokenType, tokenRoyalties: BigNumber | undefined) {
		let stat = this.store.getState().wrappedTokensStat;
		const summedERC20Collateral = this.sumERC20Collaterals(stat, token.backedERC20Value);

		stat = {
			count     : stat.count + 1,
			collateral: stat.collateral.plus(token.backedValue),
			erc20Collateral: [
				...stat.erc20Collateral.filter((item: { address: string, amount: BigNumber }) => {
					if ( summedERC20Collateral.filter((iitem) => { return item.address.toLowerCase() === iitem.address.toLowerCase() }).length ) {
						return false
					} else {
						return true
					}
				}),
				...summedERC20Collateral,
			],
			fee       : this.sumFees(stat.fee, token),
			royalties : tokenRoyalties ? this.sumRoyalties(stat.royalties, token.transferFeeToken, tokenRoyalties) : stat.royalties,
		}
		this.store.dispatch(wrappedStatsUpdate(stat));
		return stat;
	}
	async getWrappedTokenById(contractAddress: string, contract: Contract, tokenId: string, chainId: number): Promise<WrappedTokenType> {
		const token   = await this.getNFTTokenById(contractAddress, contract, tokenId, chainId);
		token.royalty = new BigNumber(0);

		// ----- ROYALTY EVENT BASED METHOD -----
		// let tokenEvents = [];
		// try {
		// 	tokenEvents = await this.contract.getPastEvents(
		// 		'NiftsyProtocolTransfer',
		// 		{
		// 			fromBlock: 'earliest',
		// 			filter: { wrappedTokenId: token.tokenId }
		// 		}
		// 	);
		// 	tokenRoyalties = tokenEvents.reduce((sum, item) => {
		// 		return new BigNumber(sum).plus(new BigNumber(item.returnValues.royalty))
		// 	}, new BigNumber(0))
		// } catch( igonored ) { console.log('Cannot get royalties') }
		// ----- END ROYALTY EVENT BASED METHOD -----

		// ----- ROYALTY CALC BASED METHOD -----
		if (
			token.backedTokens && !token.backedTokens.eq(0) &&
			token.royaltyPercent && !token.royaltyPercent.eq(0)
		) {
			token.royalty = token.backedTokens.dividedBy(new BigNumber(100).plus(-token.royaltyPercent)).multipliedBy(token.royaltyPercent)
		}
		// ----- END ROYALTY CALC BASED METHOD -----

		this.store.dispatch(incompleteTokensRemove(token));
		removeERC721Token(token, this.metamaskAdapter.chainId || 0);
		this.store.dispatch(waitingTokensRemove(token));
		if ( token.owner.toLowerCase() === this.userAddress.toLowerCase() ) {
			this.store.dispatch(wrappedTokensAdd({
				...token,
			}));
		}

		// console.log('token', token);
		return token;
	}
	async getNFTTokenById(contractAddress: string, contract: Contract, tokenId: string, chainId: number): Promise<WrappedTokenType> {
		const tokenUrl = await contract.methods.tokenURI(tokenId).call();
		const owner    = await contract.methods.ownerOf(tokenId).call()

		let tokenParsed;
		try {
			const token = await fetch(tokenUrl);
			tokenParsed = await token.json();
		} catch(e) {
			console.log('Cannot fetch tokenUrl', e)
		}

		const wrappedToken = await contract.methods.getWrappedToken(tokenId).call();
		const wrappedERC20 = await contract.methods.getERC20Collateral(tokenId).call();
		const wrappedERC20Parsed = wrappedERC20.map((item: any) => {
			if ( !this.metamaskAdapter.getERC20Contract(item.erc20Token) ) { this.metamaskAdapter.addERC20Contracts(item.erc20Token) }
			return { amount: new BigNumber(item.amount), address: item.erc20Token }
		});

		if ( !this.metamaskAdapter.getERC20Contract(wrappedToken.transferFeeToken) ) { await this.metamaskAdapter.addERC20Contracts(wrappedToken.transferFeeToken) }

		// console.log('tokenId', tokenId);
		// console.log('wrappedToken', wrappedToken);

		return {
			chainId,
			owner,
			originalContractAddress: wrappedToken.tokenContract,
			originalTokenId        : wrappedToken.tokenId,
			contractAddress        : contractAddress,
			description            : tokenParsed ? tokenParsed.description || ''               : '',
			image                  : tokenParsed ? tokenParsed.image       || ''               : '',
			name                   : tokenParsed ? tokenParsed.name        || ''               : '',
			tokenId                : `${tokenId}`,
			tokenUrl               : tokenUrl,
			backedERC20Value       : wrappedERC20Parsed,
			backedTokens           : new BigNumber(wrappedToken.backedTokens),
			backedValue            : new BigNumber(wrappedToken.backedValue),
			royalty                : undefined,
			royaltyPercent         : new BigNumber(wrappedToken.royaltyPercent),
			royaltyRec             : wrappedToken.royaltyBeneficiary,
			transferFee            : new BigNumber(wrappedToken.transferFee),
			transferFeeToken       : wrappedToken.transferFeeToken,
			unwrapAfter            : new BigNumber(wrappedToken.unwrapAfter).multipliedBy(1000),
			unwraptFeeThreshold    : new BigNumber(wrappedToken.unwraptFeeThreshold),
			sortParams             : { blockNumber: undefined, logIndex: undefined }
		}

	}
	sumERC20Collaterals(stat: WrappedTokensStatType, backedERC20Value: Array<{ address: string, amount: BigNumber }>) {
		return backedERC20Value.map((item) => {
			const foundTokenInStat = stat.erc20Collateral.filter((iitem: { address: string, amount: BigNumber }) => { return item.address.toLowerCase() === iitem.address.toLowerCase() });
			if ( foundTokenInStat.length ) {
				return {
					amount: foundTokenInStat[0].amount.plus(item.amount),
					address: item.address,
				}
			} else {
				return {
					amount: item.amount,
					address: item.address,
				}
			}
		});
	}
	sumFees(statFee: Array<{ amount: BigNumber, address: string }>, token: WrappedTokenType) {
		const getExistingAmount = ( feeTokenToSearch: string ) => {
			const foundAmount = statFee.filter((item) => { return item.address.toLowerCase() === feeTokenToSearch.toLowerCase() });
			if ( foundAmount.length ) {
				return foundAmount[0].amount
			} else {
				return new BigNumber(0);
			}
		}
		const addressToFilter = token.transferFeeToken || '0x0000000000000000000000000000000000000000';
		return [
			...statFee.filter((item) => { return item.address.toLowerCase() !== addressToFilter.toLowerCase() }),
			{
				address: addressToFilter,
				amount: getExistingAmount(addressToFilter).plus(token.backedTokens || new BigNumber(0))
			}
		]
	}
	sumRoyalties(statRoyalties: Array<{ amount: BigNumber, address: string }>, transferFeeToken: string | undefined, tokenRoyalties: BigNumber) {
		const getExistingAmount = ( feeTokenToSearch: string ) => {
			const foundAmount = statRoyalties.filter((item) => { return item.address.toLowerCase() === feeTokenToSearch.toLowerCase() });
			if ( foundAmount.length ) {
				return foundAmount[0].amount
			} else {
				return new BigNumber(0);
			}

		}
		const addressToFilter = transferFeeToken || '0x0000000000000000000000000000000000000000';
		return [
			...statRoyalties.filter((item) => { return item.address.toLowerCase() !== addressToFilter.toLowerCase() }),
			{
				address: addressToFilter,
				amount: getExistingAmount(addressToFilter).plus(tokenRoyalties || new BigNumber(0))
			}
		]
	}
	async getNFTTokens() {
		if ( new Date().getTime() - this.tokenLastUpdate < (this.tokenUpdateDelay * 1000) ) { return; }
		this.tokenLastUpdate = new Date().getTime();

		this.store.dispatch(setTokensLoading());

		const chainId = await this.web3.eth.getChainId();

		if ( this.store.getState()._loading === this.t('Loading tokens') ) {
			this.store.dispatch(unsetLoading());
		}

		// MAIN CONTRACT
		const balance = await this.contract.methods.balanceOf( this.userAddress ).call();
		if ( Number(balance) !== 0 ) {
			this.store.dispatch(gotoListRequest())
		}

		for (let idx = balance - 1; idx >= 0; idx--) {
			const contractTokenId = await this.contract.methods.tokenOfOwnerByIndex(this.userAddress, idx).call();
			const token = await this.getWrappedTokenById(this.contractAddress, this.contract, contractTokenId, chainId);
			this.calcTokenStats(token, token.royalty);
		};
		// END MAIN CONTRACT

		// PREVIOUS CONTRACTS
		for (let idx_contract = this.previousContracts.length - 1; idx_contract >= 0; idx_contract--) {

			const balance = await this.previousContracts[idx_contract].contract.methods.balanceOf( this.userAddress ).call();
			if ( Number(balance) !== 0 ) {
				this.store.dispatch(gotoListRequest());
			}

			for (let idx = 0; idx < balance; idx++) {
				const contractTokenId = await this.previousContracts[idx_contract].contract.methods.tokenOfOwnerByIndex(this.userAddress, idx).call();
				const token = await this.getWrappedTokenById(
					this.previousContracts[idx_contract].address,
					this.previousContracts[idx_contract].contract,
					contractTokenId, chainId
				);
				this.calcTokenStats(token, token.royalty);
			}
		};
		this.store.dispatch(unsetTokensLoading());
		// END PREVIOUS CONTRACTS
	}
	async getDiscoveredTokens(page: number) {
		const chainId = await this.web3.eth.getChainId();
		const discoveredTokens = await fetchUserTokens( chainId, this.userAddress, page );

		const tokensOnPage = 12;
		if ( discoveredTokens.length === tokensOnPage ) {
			this.getDiscoveredTokens(page + 1);
		}

		// filter nfts on wrapper contracts
		const discoveredTokensParsed = discoveredTokens.filter((item: DiscoveredToken) => {
			const foundPreviousContract = this.previousContracts.filter((iitem) => { return iitem.address.toLowerCase() === item.contract_address.toLowerCase() });
			return !foundPreviousContract.length && item.contract_address.toLowerCase() !== this.contractAddress.toLowerCase()
		});

		// do not filter nfts on wrapper contracts
		// const discoveredTokensParsed = discoveredTokens;

		discoveredTokensParsed.forEach((item) => {

			let blockNumber = undefined;
			if ( !new BigNumber(item.blocknumber).isNaN() ) { blockNumber = new BigNumber(item.blocknumber) }
			let logIndex = undefined;
			if ( !new BigNumber(item.logindex).isNaN() ) { logIndex = new BigNumber(item.logindex) }
			getERC721Token(
				this.metamaskAdapter,
				item.contract_address,
				item.token_id,
				this.userAddress,
				this.t,
				{ blockNumber: blockNumber, logIndex: logIndex }
			)
				.then((data) => {
					this.store.dispatch(discoveredTokensAdd(data));
				})
				.catch((e) => {
					console.log('Cannot fetch discovered token', item.contract_address, item.token_id, e)
				})
		});
	}
	async checkIsERC721(address: string) {
		const ERC721Signature = '0x80ac58cd';
		return await this.contract.methods.isERC721(address, ERC721Signature).call();
	}

	async checkApprove(contract721: Contract, tokenId: string) {
		const approvedAddress = await contract721.methods.getApproved(tokenId).call();
		return approvedAddress.toLowerCase() === this.contractAddress.toLowerCase();
	}
	async checkApproveAll(contract721: Contract) {
		const approvedAll = await contract721.methods.isApprovedForAll(this.userAddress, this.contractAddress).call();
		return approvedAll;
	}

	addCollateralSuccess(token: WrappedTokenType, erc20Contract: ERC20Contract | undefined, data: any) {
		this.store.dispatch(waitingTokensRemove(token));
		this.store.dispatch(unsetLoading());
		this.store.dispatch(wrappedTokensClear());
		this.getNFTTokens();
		this.updateNativeBalance();
		this.erc20Contract.getBalance();
		erc20Contract?.getBalance();

		this.store.dispatch(setInfo({
			text: this.t('Your Collateral Successfully Refilled'),
			 buttons: [{
				text: 'Ok',
				clickFunc: () => { this.store.dispatch(clearInfo()) }
			 }],
			links: [{
				text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
				url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
			}]
		}));

		this.store.dispatch(setSuccess({
			text: this.t('Your Collateral Successfully Refilled'),
			icon: '',
			token: undefined,
			transactionHash: data.transactionHash
		}));
	}
	addCollateralError(token: WrappedTokenType, e: any) {
		this.store.dispatch(waitingTokensRemove(token));
		this.store.dispatch(unsetLoading());
		console.log(e);

		let errorMsg = '';
		if ('message' in e) {
			errorMsg = e.message
		} else {
			try {
				const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
				errorMsg = errorParsed.originalError.message;
			} catch(ignored) {}
		}

		let links = undefined;
		if ('transactionHash' in e) {
			links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
		} else {
			try {
				const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
				const txHash = errorParsed.transactionHash;
				links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
			} catch(ignored) {}
		}

		this.store.dispatch(unsetLoading());
		this.store.dispatch(setError({
			text: `${this.t('Cannot add collateral')}: ${errorMsg}`,
			buttons: undefined,
			links: links,
		}));
	}
	async addCollaterallERC20(token: WrappedTokenType, erc20Token: ERC20ContractParamsType, value: BigNumber, tokenContract: Contract) {
		if ( !this.canWrapper('erc20collateral', tokenContract.options.address) ) {
			this.store.dispatch(waitingTokensRemove(token));
			this.store.dispatch(unsetLoading());
			this.store.dispatch(setError({
				text: `${this.t('Cannot add collateral')}: ${this.t('this contract does not support erc20 collateral')}`,
				buttons: undefined,
				links: undefined,
			}));
			return;
		}

		const erc20Contract = this.metamaskAdapter.getERC20Contract( erc20Token.address );
		if ( !erc20Contract ) { console.log('Something went wrong'); return; }

		const allowanceForContract = await erc20Contract.getAllowanceToAddress(tokenContract.options.address);
		// APPROVE
		if ( new BigNumber(value).gt(new BigNumber(allowanceForContract)) ) {
			// this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));
			this.store.dispatch(waitingTokensAdd({ token, msg: this.t('Waiting for approve') }));
			this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));
			try {
				console.log(tokenContract.options.address);
				await erc20Contract.makeAllowanceTransfer(new BigNumber(value), tokenContract.options.address);
			} catch(e: any) {
				console.log(e);
				this.store.dispatch(waitingTokensRemove(token));
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `${this.t('Cannot make allowance')}: ${e.message}`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			}

			// this.store.dispatch(setLoading({ msg: this.t('Waiting for refill') }));
			this.store.dispatch(waitingTokensAdd({ token, msg: this.t('Waiting for refill') }));
			this.store.dispatch(setLoading({ msg: this.t('Waiting for refill') }));
		}
		// END APPROVE

		const tx = tokenContract.methods.addERC20Collateral(token.tokenId, erc20Token.address, value.toString());

		let errMsg = '';
		try {
			await tx.estimateGas({ from: this.userAddress })
		} catch(e: any) {
			try {
				console.log('Cannot add collateral before send: ', e);
				const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
				errMsg = errorParsed.originalError.message
					.replace('execution reverted: ', '');
			} catch(ignored) {}
		}
		if ( errMsg !== '' ) {
			this.store.dispatch(waitingTokensRemove(token));
			this.store.dispatch(unsetLoading());
			this.store.dispatch(setError({
				text: `${this.t('Cannot add collateral')}: ${errMsg}`,
				buttons: undefined,
				links: undefined,
			}));
			return;
		}

		tx
			.send({ from: this.userAddress })
			.then((data: any) => {
				this.addCollateralSuccess(token, erc20Contract, data);
			})
			.catch((e: any) => {
				this.addCollateralError(token, e);
			});
	}
	async addCollateral(
		token: WrappedTokenType,
		value: BigNumber,
		erc20Token: ERC20ContractParamsType | undefined,
	) {

		if ( !this.canWrapper('addvalue', token.contractAddress) ) {
			console.log(
				'Contract does not support this operation:',
				token.contractAddress,
			);
			this.store.dispatch(unsetLoading());
			this.store.dispatch(setError({
				text: `Contract does not support this operation`,
				buttons: undefined,
				links: undefined,
			}));
			return;
		}

		this.store.dispatch(waitingTokensAdd({ token, msg: this.t('Waiting for refill') }));
		this.store.dispatch(setLoading({ msg: this.t('Waiting for refill') }));

		let tokenContract;

		if ( token.contractAddress.toLowerCase() === this.contractAddress.toLowerCase() ) {
			tokenContract = this.contract;
		} else {
			const foundContract = this.previousContracts.filter((item) => {
				return item.address.toLowerCase() === token.contractAddress.toLowerCase();
			})
			if ( foundContract.length ) {
				tokenContract = foundContract[0].contract;
			} else {
				console.log(
					'Cannot add value on previous contract:',
					token.contractAddress,
					this.previousContracts.map((item) => { return item.address })
				);
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `Cannot connect to contract`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			}
		}

		if ( erc20Token ) {
			await this.addCollaterallERC20(token, erc20Token, value, tokenContract);
		} else {
			const tx = tokenContract.methods.addNativeCollateral(token.tokenId);

			let errMsg = '';
			try {
				await tx.estimateGas({ from: this.userAddress, value: value })
			} catch(e: any) {
				try {
					console.log('Cannot add collateral before send: ', e);
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errMsg = errorParsed.originalError.message
						.replace('execution reverted: ', '');
				} catch(ignored) {}
			}
			if ( errMsg !== '' ) {
				this.store.dispatch(waitingTokensRemove(token));
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `${this.t('Cannot add collateral')}: ${errMsg}`,
					buttons: undefined,
					links: undefined,
				}));
			}

			tx
				.send({ from: this.userAddress, value: value })
				.then((data: any) => {
					this.addCollateralSuccess(token, undefined, data);
				})
				.catch((e: any) => {
					this.addCollateralError(token, e);
				});
		}
	}
	async setApprovalForAll(
		token: WrappedTokenType,
		addressTo: string,
	) {

		this.store.dispatch(waitingTokensAdd({ token, msg: this.t('Waiting for approve') }));
		this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));

		let tokenContract;

		if ( token.contractAddress.toLowerCase() === this.contractAddress.toLowerCase() ) {
			tokenContract = this.contract;
		} else {
			const foundContract = this.previousContracts.filter((item) => {
				return item.address.toLowerCase() === token.contractAddress.toLowerCase();
			})
			if ( foundContract.length ) {
				tokenContract = foundContract[0].contract;
			} else {
				console.log(
					'Cannot set approval on previous contract:',
					token.contractAddress,
					this.previousContracts.map((item) => { return item.address })
				);
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `Cannot connect to contract`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			}
		}

		const tx = tokenContract.methods.setApprovalForAll(
			addressTo,
			token.tokenId
		)

		// pre-send transaction check
		try {
			await tx.estimateGas({ from: this.userAddress })
		} catch(e: any) {
			try {
				this.store.dispatch(waitingTokensRemove(token));
				this.store.dispatch(unsetLoading());

				console.log('Cannot approve before send: ', e);
				const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
				const errMsg = errorParsed.originalError.message
					.replace('execution reverted: ', '');
				this.store.dispatch(setError({
					text: `${this.t('Cannot set approval for tokens')}: ${errMsg}`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			} catch(ignored) {}
		}

		tx
			.send({ from: this.userAddress })
			.then((data: any) => {
				this.store.dispatch(waitingTokensRemove(token));
				this.store.dispatch(unsetLoading());

				this.store.dispatch(wrappedTokensClear());
				this.getNFTTokens();
				this.updateNativeBalance();
				this.erc20Contract.getBalance();

				this.store.dispatch(setInfo({
					text: `${this.t('Our tokens has been approved')} (${addressTo})`,
					 buttons: [{
						text: 'Ok',
						clickFunc: () => { this.store.dispatch(clearInfo()) }
					 }],
					links: [{
						text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
						url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
					}]
				}));
				this.store.dispatch(setSuccess({
					text: this.t('Our tokens has been approved'),
					icon: icon_onb_5,
					token: undefined,
					transactionHash: data.transactionHash
				}));

			})
			.catch((e: any) => {
				console.log(e);
				this.store.dispatch(waitingTokensRemove(token));
				this.store.dispatch(unsetLoading());

				let errorMsg = '';
				if ('message' in e) {
					errorMsg = e.message
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						errorMsg = errorParsed.originalError.message;
					} catch(ignored) {}
				}

				let links = undefined;
				if ('transactionHash' in e) {
					links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						const txHash = errorParsed.transactionHash;
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
					} catch(ignored) {}
				}

				this.store.dispatch(setError({
					text: `${this.t('Cannot set approval for tokens')}: ${errorMsg}`,
					buttons: undefined,
					links: links,
				}));
			});
	}
	async transferToken(
		token: WrappedTokenType,
		addressTo: string,
		history: History | undefined = undefined,
	) {

		if ( !this.canWrapper('transfer', token.contractAddress) ) {
			console.log(
				'Contract does not support this operation:',
				token.contractAddress,
			);
			this.store.dispatch(unsetLoading());
			this.store.dispatch(setError({
				text: `Contract does not support this operation`,
				buttons: undefined,
				links: undefined,
			}));
			return;
		}

		this.store.dispatch(waitingTokensAdd({ token, msg: this.t('Waiting for transfer') }));
		this.store.dispatch(setLoading({ msg: this.t('Waiting for transfer') }));

		let tokenContract;

		if ( token.contractAddress.toLowerCase() === this.contractAddress.toLowerCase() ) {
			tokenContract = this.contract;
		} else {
			const foundContract = this.previousContracts.filter((item) => {
				return item.address.toLowerCase() === token.contractAddress.toLowerCase();
			})
			if ( foundContract.length ) {
				tokenContract = foundContract[0].contract;
			} else {
				console.log(
					'Cannot make transfer on previous contract:',
					token.contractAddress,
					this.previousContracts.map((item) => { return item.address })
				);
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `Cannot connect to contract`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			}
		}

		const tx = tokenContract.methods.safeTransferFrom(
			this.userAddress,
			addressTo,
			token.tokenId
		)

		// pre-send transaction check
		try {
			await tx.estimateGas({ from: this.userAddress })
		} catch(e: any) {
			try {
				this.store.dispatch(waitingTokensRemove(token));
				this.store.dispatch(unsetLoading());

				console.log('Cannot transfer before send: ', e);
				const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
				const errMsg = errorParsed.originalError.message
					.replace('execution reverted: ', '');
				this.store.dispatch(setError({
					text: `${this.t('Cannot transfer token')}: ${errMsg}`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			} catch(ignored) {}
		}

		tx
			.send({ from: this.userAddress })
			.then((data: any) => {
				this.store.dispatch(waitingTokensRemove(token));
				this.store.dispatch(unsetLoading());

				this.store.dispatch(wrappedTokensClear());
				this.getNFTTokens();
				this.updateNativeBalance();
				this.erc20Contract.getBalance();

				if ( history ) { history.push('/list'); }

				this.store.dispatch(setInfo({
					text: `${this.t('Our token has been transferred')} (${addressTo})`,
					 buttons: [{
						text: 'Ok',
						clickFunc: () => { this.store.dispatch(clearInfo()) }
					 }],
					links: [{
						text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
						url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
					}]
				}));
				this.store.dispatch(setSuccess({
					text: this.t('Our token has been transferred'),
					icon: icon_onb_5,
					token: undefined,
					transactionHash: data.transactionHash
				}));

			})
			.catch((e: any) => {
				console.log(e);
				this.store.dispatch(waitingTokensRemove(token));
				this.store.dispatch(unsetLoading());

				let errorMsg = '';
				if ('message' in e) {
					errorMsg = e.message
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						errorMsg = errorParsed.originalError.message;
					} catch(ignored) {}
				}

				let links = undefined;
				if ('transactionHash' in e) {
					links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						const txHash = errorParsed.transactionHash;
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
					} catch(ignored) {}
				}

				this.store.dispatch(setError({
					text: `${this.t('Cannot wrap token')}: ${errorMsg}`,
					buttons: undefined,
					links: links,
				}));
			});
	}
	async wrapSubmit(params: {
		token           : WrappedTokenType,
		collateral      : BigNumber,
		unwrapAfter     : BigNumber,
		transferFee     : BigNumber,
		transferFeeToken: string,
		royaltyPercent  : BigNumber,
		unwrapFee       : BigNumber,
		royaltyRec      : string,
	}) {
		const royaltyBeneficiary   = params.transferFee.toString() !== '0' ? params.royaltyRec : '0x0000000000000000000000000000000000000000';
		const royaltyPercentParsed = params.transferFee.toString() !== '0' ? params.royaltyPercent : '0';

		this.store.dispatch(waitingTokensAdd({
			token: params.token,
			msg: this.t('Waiting for wrap')
		}));
		this.store.dispatch(setLoading({ msg: this.t('Waiting for wrap') }));
		const tx = this.contract.methods.wrap721(
			params.token.contractAddress,
			params.token.tokenId,
			params.unwrapAfter.toString(),
			params.transferFee.toString(),
			royaltyBeneficiary,
			royaltyPercentParsed.toString(),
			params.unwrapFee.toString(),
			params.transferFeeToken
		)

		// pre-send transaction check
		let errMsg = '';
		try {
			await tx.estimateGas({ from: this.userAddress, value: params.collateral.toString() })
		} catch(e: any) {
			try {
				console.log('Cannot wrap before send: ', e);
				const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
				errMsg = errorParsed.originalError.message
					.replace('execution reverted: ', '');
			} catch(ignored) {}
		}
		if ( errMsg !== '' ) {
			this.store.dispatch(setError({
				text: `${this.t('Cannot wrap token')}: ${errMsg}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(waitingTokensRemove(params.token));
			this.store.dispatch(unsetLoading());
			return;
		}

		tx
			.send({ from: this.userAddress, value: params.collateral.toString() })
			.then((data: any) => {
				const chainId = this.store.getState().metamaskAdapter.chainId;
				removeERC721Token(params.token, chainId);
				this.store.dispatch(ignoredTokensAdd({ contractAddress: params.token.contractAddress, tokenId: params.token.tokenId }));
				this.store.dispatch(incompleteTokensRemove(params.token));
				this.store.dispatch(waitingTokensRemove(params.token));
				this.store.dispatch(unsetLoading());
				this.store.dispatch(wrappedTokensClear());
				this.getNFTTokens();
				this.updateNativeBalance();
				this.erc20Contract.getBalance();

				this.store.dispatch(setInfo({
					text: `${this.t('Non-Fungible Token is Successfully Wrapped')}. Contract address: ${this.contractAddress}. Token ID: ${data.events.Wrapped.returnValues.wrappedTokenId}`,
					 buttons: [{
						text: 'Ok',
						clickFunc: () => { this.store.dispatch(clearInfo()) }
					 }],
					links: [{
						text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
						url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
					}]
				}));
				this.store.dispatch(setSuccess({
					text: this.t('Non-Fungible Token is Successfully Wrapped'),
					icon: icon_onb_2,
					token: undefined,
					transactionHash: data.transactionHash
				}));
			})
			.catch((e: any) => {
				console.log('Cannot wrap after send: ', e);

				this.store.dispatch(waitingTokensRemove(params.token));
				this.store.dispatch(unsetLoading());

				let errorMsg = '';
				if ('message' in e) {
					errorMsg = e.message
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						errorMsg = errorParsed.originalError.message;
					} catch(ignored) {}
				}

				let links = undefined;
				if ('transactionHash' in e) {
					links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						const txHash = errorParsed.transactionHash;
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
					} catch(ignored) {}
				}

				if ( !errorMsg.includes('was not mined within 50 blocks') ) {
					this.store.dispatch(setError({
						text: `${this.t('Cannot wrap token')}: ${errorMsg}`,
						buttons: undefined,
						links: links,
					}));
				}
			})
	}
	async wrapToken(params: {
		token          : WrappedTokenType,
		collateral     : BigNumber,
		unwrapAfter    : BigNumber,
		transferFeeToken: string,
		transferFee    : BigNumber,
		royaltyPercent : BigNumber,
		royaltyRec     : string,
		unwrapFee      : BigNumber,
	}) {

		const contract721 = new this.web3.eth.Contract(erc721_abi as any, params.token.contractAddress);
		if ( !await this.checkApproveAll(contract721) && !await this.checkApprove(contract721, params.token.tokenId) ) {
			this.store.dispatch(waitingTokensAdd({
				token: params.token,
				msg: this.t('Waiting for approve')
			}));
			this.store.dispatch(setLoading({ msg: this.t('Waiting for approve') }));

			contract721.methods.approve(this.contractAddress, params.token.tokenId)
				.send({ from: this.userAddress })
				.then(() => {
					this.wrapSubmit({
						token: params.token,
						collateral: params.collateral,
						unwrapAfter: params.unwrapAfter,
						transferFee: params.transferFee,
						transferFeeToken: params.transferFeeToken,
						royaltyPercent: params.royaltyPercent,
						royaltyRec: params.royaltyRec,
						unwrapFee: params.unwrapFee,
					});
				})
				.catch((e: any) => {
					this.store.dispatch(waitingTokensRemove(params.token));
					this.store.dispatch(unsetLoading());
					this.store.dispatch(setError({
						text: `${this.t('Cannot wrap token')}: ${e.message}`,
						buttons: undefined,
						links: undefined,
					}));
				})
		} else {
			this.wrapSubmit({
				token: params.token,
				collateral: params.collateral,
				unwrapAfter: params.unwrapAfter,
				transferFee: params.transferFee,
				transferFeeToken: params.transferFeeToken,
				royaltyPercent: params.royaltyPercent,
				royaltyRec: params.royaltyRec,
				unwrapFee: params.unwrapFee,
			});
		}

	}
	async unwrapToken(token: WrappedTokenType) {
		this.store.dispatch(waitingTokensAdd({
			token: token,
			msg: this.t('Waiting for unwrap')
		}));
		this.store.dispatch(setLoading({ msg: this.t('Waiting for unwrap') }));

		let tokenContract;

		if ( token.contractAddress.toLowerCase() === this.contractAddress.toLowerCase() ) {
			tokenContract = this.contract;
		} else {
			const foundContract = this.previousContracts.filter((item) => {
				return item.address.toLowerCase() === token.contractAddress.toLowerCase();
			})
			if ( foundContract.length ) {
				tokenContract = foundContract[0].contract;
			} else {
				console.log(
					'Cannot unwrap on previous contract:',
					token.contractAddress,
					this.previousContracts.map((item) => { return item.address })
				);
				this.store.dispatch(unsetLoading());
				this.store.dispatch(setError({
					text: `Cannot connect to contract`,
					buttons: undefined,
					links: undefined,
				}));
				return;
			}
		}

		const tx = tokenContract.methods.unWrap721(
			token.tokenId,
		)

		// pre-send transaction check
		let errMsg = '';
		let estimatedGas;
		try {
			estimatedGas = await tx.estimateGas({ from: this.userAddress })
		} catch(e: any) {
			try {
				console.log('Cannot unwrap before send: ', e);
				const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
				errMsg = errorParsed.originalError.message
					.replace('execution reverted: ', '');
			} catch(ignored) {}
		}
		if ( errMsg !== '' ) { throw new Error(errMsg); }

		estimatedGas = new BigNumber(estimatedGas).plus(new BigNumber(100000)).toString();

		tx
			.send({ from: this.userAddress, gas: estimatedGas })
			.then((data: any) => {
				this.store.dispatch(waitingTokensRemove(token));
				this.store.dispatch(unsetLoading());
				this.store.dispatch(wrappedTokensClear());
				this.getNFTTokens();
				this.updateNativeBalance();
				this.erc20Contract.getBalance();

				this.store.dispatch(setInfo({
					text: `${this.t('Your Collateral is Successfully Unwrapped')}`,
					 buttons: [{
						text: 'Ok',
						clickFunc: () => { this.store.dispatch(clearInfo()) }
					 }],
					links: [{
						text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
						url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
					}]
				}));
				this.store.dispatch(setSuccess({
					text: this.t('Your Collateral is Successfully Unwrapped'),
					icon: icon_onb_3,
					token: token,
					transactionHash: data.transactionHash
				}));
				this.store.dispatch(unsetLoading());
			})
			.catch((e: any) => {
				console.log('Cannot unwrap after send: ', e);

				this.store.dispatch(waitingTokensRemove(token));
				this.store.dispatch(unsetLoading());

				let errorMsg = '';
				if ('message' in e) {
					errorMsg = e.message
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						errorMsg = errorParsed.originalError.message;
					} catch(ignored) {}
				}

				let links = undefined;
				if ('transactionHash' in e) {
					links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						const txHash = errorParsed.transactionHash;
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
					} catch(ignored) {}
				}

				if ( !errorMsg.includes('was not mined within 50 blocks') ) {
					this.store.dispatch(unsetLoading());
					this.store.dispatch(setError({
						text: `${this.t('Cannot unwrap token')}: ${errorMsg}`,
						buttons: undefined,
						links: links,
					}));
				}
			});
	}

	// ---------- BATCH WRAP ----------
	async batchWrapSubmit(params: {
		originalAddress: string,
		collaterals    : Array<BatchWrapCollateralItem>,
		recipients     : Array<BatchWrapRecipientItem>,
		unwrapAfter    : BigNumber,
	}) {
		this.store.dispatch(setLoading({ msg: this.t('Waiting for wrap') }));

		let payableAmount = new BigNumber(0);
		const foundNative = params.collaterals.filter((item) => {
			return item.tokenAddress === '' ||
			item.tokenAddress === '0' ||
			item.tokenAddress === '0x0000000000000000000000000000000000000000'
		});
		if ( foundNative.length ) { payableAmount = foundNative[0].amount }

		const recievers = params.recipients.map((item) => { return item.userAddress });
		const tokenIds = params.recipients.map((item) => { return item.tokenId });
		const forDistrib = params.collaterals
			.filter((item) => {
				return item.tokenAddress !== '' &&
				item.tokenAddress !== '0' &&
				item.tokenAddress !== '0x0000000000000000000000000000000000000000'
			})
			.map((item) => {
				return { erc20Token: item.tokenAddress, amount: item.amount.toString() }
			});

		const tx = this.contract.methods.WrapAndDistrib721WithMint(
			params.originalAddress,
			recievers,
			tokenIds,
			forDistrib,
			params.unwrapAfter.toString()
		)

		// pre-send transaction check
		const txParams: any = { from: this.userAddress }
		if ( !payableAmount.eq(0) ) { txParams.value = payableAmount.toString() }
		try {
			await tx.estimateGas(txParams)
		} catch(e: any) {
			console.log('Cannot batchwrap before send: ', e);
			let errorMsg = '';

			if ('message' in e) {
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errorMsg = errorParsed.message
						.replace('execution reverted: ', '');
				} catch(ignored) {}
			}

			this.store.dispatch(setError({
				text: `${this.t('Cannot batchwrap token')}: ${errorMsg || e}`,
				buttons: undefined,
				links: undefined,
			}));
			this.store.dispatch(unsetLoading());
			return;
		}

		tx
			.send(txParams)
			.then((data: any) => {
				this.store.dispatch(unsetLoading());
				this.updateNativeBalance();
				this.erc20Contract.getBalance();

				this.store.dispatch(setInfo({
					text: `${this.t('Non-Fungible Tokens is Successfully Wrapped')}`,
					 buttons: [{
						text: 'Ok',
						clickFunc: () => { this.store.dispatch(clearInfo()) }
					 }],
					links: [{
						text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
						url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
					}]
				}));
			})
			.catch((e: any) => {
				console.log('Cannot batchwrap after send: ', e);

				this.store.dispatch(unsetLoading());

				let errorMsg = '';
				if ('message' in e) {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						errorMsg = errorParsed.message
							.replace('execution reverted: ', '');
					} catch(ignored) {}
				}

				let links = undefined;
				if ('transactionHash' in e) {
					links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						const txHash = errorParsed.transactionHash;
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
					} catch(ignored) {}
				}

				this.store.dispatch(setError({
					text: `${this.t('Cannot wrap tokens')}: ${errorMsg}`,
					buttons: undefined,
					links: links,
				}));
			})

	}
	async batchWrapSubmitEmpty(params: {
		collaterals    : Array<BatchWrapCollateralItem>,
		recipients     : Array<BatchWrapRecipientItem>,
		unwrapAfter    : BigNumber,
	}) {
		this.store.dispatch(setLoading({ msg: this.t('Waiting for wrap') }));

		let payableAmount = new BigNumber(0);
		const foundNative = params.collaterals.filter((item) => {
			return item.tokenAddress === '' ||
			item.tokenAddress === '0' ||
			item.tokenAddress === '0x0000000000000000000000000000000000000000'
		});
		if ( foundNative.length ) { payableAmount = foundNative[0].amount }

		const recievers = params.recipients.map((item) => { return item.userAddress });
		const forDistrib = params.collaterals
			.filter((item) => {
				return item.tokenAddress !== '' &&
				item.tokenAddress !== '0' &&
				item.tokenAddress !== '0x0000000000000000000000000000000000000000'
			})
			.map((item) => {
				return { erc20Token: item.tokenAddress, amount: item.amount.toString() }
			});

		const tx = this.contract.methods.WrapAndDistribEmpty(
			recievers,
			forDistrib,
			params.unwrapAfter.toString()
		)

		// pre-send transaction check
		const txParams: any = { from: this.userAddress }
		if ( !payableAmount.eq(0) ) { txParams.value = payableAmount.toString() }
		try {
			await tx.estimateGas(txParams)
		} catch(e: any) {
			console.log('Cannot batchwrap before send: ', e);
			let errorMsg = '';

			if ('message' in e) {
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errorMsg = errorParsed.message
						.replace('execution reverted: ', '');
				} catch(ignored) {}
			}

			this.store.dispatch(setError({
				text: `${this.t('Cannot batchwrap token')}: ${errorMsg || e}`,
				buttons: undefined,
				links: undefined,
			}));

			this.store.dispatch(unsetLoading());
			return;
		}

		tx
			.send(txParams)
			.then((data: any) => {
				this.store.dispatch(unsetLoading());
				this.updateNativeBalance();
				this.erc20Contract.getBalance();

				this.store.dispatch(setInfo({
					text: `${this.t('Non-Fungible Tokens is Successfully Wrapped')}`,
						buttons: [{
						text: 'Ok',
						clickFunc: () => { this.store.dispatch(clearInfo()) }
						}],
					links: [{
						text: `View on ${this.metamaskAdapter.chainConfig.explorerName}`,
						url: `${this.metamaskAdapter.chainConfig.explorerBaseUrl}/tx/${data.transactionHash}`
					}]
				}));
			})
			.catch((e: any) => {
				console.log('Cannot wrap after send: ', e);

				this.store.dispatch(unsetLoading());

				let errorMsg = '';
				if ('message' in e) {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						errorMsg = errorParsed.message
							.replace('execution reverted: ', '');
					} catch(ignored) {}
				}

				let links = undefined;
				if ('transactionHash' in e) {
					links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
				} else {
					try {
						const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
						const txHash = errorParsed.transactionHash;
						links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
					} catch(ignored) {}
				}

				this.store.dispatch(setError({
					text: `${this.t('Cannot wrap tokens')}: ${errorMsg || e}`,
					buttons: undefined,
					links: links,
				}));
			})
	}
	async batchWrapCheckAllowances(
		collaterals: Array<BatchWrapCollateralItem>,
		qty        : number,
	) {
		this.store.dispatch(setLoading({ msg: this.t('Checking approve') }));

		const EIPStandart = `${this.store.getState().metamaskAdapter.EIPPrefix}-20` || 'ERC-20';

		for ( const collateral of collaterals  ) {
			// skip native
			if ( collateral.tokenAddress === '' || collateral.tokenAddress === '' || collateral.tokenAddress === '0x0000000000000000000000000000000000000000' ) { continue; }
			const allowanceToCheck = collateral.amount.multipliedBy(new BigNumber(qty));

			if ( collateral.token ) {
				// known token
				this.store.dispatch(setLoading({ msg: `${ this.t('Checking approve') } (${ EIPStandart }): ${ collateral.token.symbol }` }));

				const contract = this.metamaskAdapter.getERC20Contract(collateral.tokenAddress);
				if ( !contract ) { return; }
				const balance = await contract.getBalance();
				if ( !balance ) { throw new Error(`Cannot update balance of ${ contract.erc20Params.symbol }`) }
				if ( balance.balance.lt(allowanceToCheck) ) { throw new Error(`Not enough balance of ${ contract.erc20Params.symbol }`) }
				if ( balance.allowance.lt(allowanceToCheck) ) {
					this.store.dispatch(setLoading({ msg: `${ this.t('Waiting for approve') } (${ EIPStandart }): ${ collateral.token.symbol }` }));
					await contract.makeAllowance(allowanceToCheck)
				}

			} else {
				// known token
				this.store.dispatch(setLoading({ msg: `${ this.t('Checking approve') }` }));

				let contract;
				try {
					contract = new this.web3.eth.Contract(erc20_abi as any, collateral.tokenAddress);
				} catch (e) {
					throw new Error(`Cannot connect to ERC20 contract (${collateral.tokenAddress}): ${e}`)
				}

				const symbol = await contract.methods.symbol().call();
				this.store.dispatch(setLoading({ msg: `${ this.t('Checking approve') } (${ EIPStandart }): ${ symbol }` }));

				const balance   = new BigNumber(await contract.methods.balanceOf(this.userAddress).call());
				const allowance = new BigNumber(await contract.methods.allowance(this.userAddress, this.contractAddress).call());

				if ( balance.lt(allowanceToCheck) ) { throw new Error(`Not enough balance of ${ symbol }`) }
				if ( allowance.lt(allowanceToCheck) ) {
					this.store.dispatch(setLoading({ msg: `${ this.t('Waiting for approve') } (${ EIPStandart }): ${ symbol }` }));
					await contract.methods.approve(this.contractAddress, allowanceToCheck.toString()).send({ from: this.userAddress });
				}
			}
		}
	}
	async batchWrapToken(params: {
		originalAddress: string | undefined,
		recipients     : Array<BatchWrapRecipientItem>,
		collaterals    : Array<BatchWrapCollateralItem>,
		unwrapAfter    : BigNumber,
	}) {

		if ( !this.canWrapper('batchWrap', this.contractAddress) ) {
			console.log(
				'Contract does not support this operation:',
				this.contractAddress,
			);
			this.store.dispatch(unsetLoading());
			this.store.dispatch(setError({
				text: `Contract does not support this operation`,
				buttons: undefined,
				links: undefined,
			}));
			return;
		}

		try {
			await this.batchWrapCheckAllowances(params.collaterals, params.recipients.length);
		} catch (e: any) {
			console.log('Cannot approve while batchwraping while approving: ', e);

			this.store.dispatch(unsetLoading());

			let errorMsg = '';
			if ('message' in e) {
				errorMsg = e.message
			} else {
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					errorMsg = errorParsed.originalError.message;
				} catch(ignored) {}
			}

			let links = undefined;
			if ('transactionHash' in e) {
				links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${e.transactionHash}` }];
			} else {
				try {
					const errorParsed = JSON.parse(e.message.slice(e.message.indexOf('\n')));
					const txHash = errorParsed.transactionHash;
					links = [{ text: `${this.store.getState().metamaskAdapter.explorerName}`, url: `${this.store.getState().metamaskAdapter.explorerBaseUrl}/tx/${txHash}` }];
				} catch(ignored) {}
			}

			this.store.dispatch(setError({
				text: `${this.t('Cannot batchwrap token')}: ${errorMsg}`,
				buttons: undefined,
				links: links,
			}));
			return;
		}

		if ( params.originalAddress ) {
			this.batchWrapSubmit({
				originalAddress: params.originalAddress,
				collaterals    : params.collaterals,
				recipients     : params.recipients,
				unwrapAfter    : params.unwrapAfter,
			});
		} else {
			this.batchWrapSubmitEmpty({
				collaterals: params.collaterals,
				recipients : params.recipients,
				unwrapAfter: params.unwrapAfter,
			});
		}

		// this.updateNativeBalance();
		// this.erc20Contract.getBalance();

		// this.store.dispatch(unsetLoading());
		// this.store.dispatch(setInfo({
		// 	text: `Approved`,
		// 	buttons: [{
		// 		text: 'Ok',
		// 		clickFunc: () => { this.store.dispatch(clearInfo()) }
		// 	}],
		// 	links: undefined
		// }));

	}

}